import { useCustomerCategoryTypeDetail } from '../api/useCustomerCategoryTypeDetail';
import { useMutateCustomerCategoryType } from '../api/useMutateCustomerCategoryType';
import Loading from '../../../components/loading/loading.component';
import { ICustomerCategoryType } from '../interfaces/customerCategoryType';
import { CustomerCategoryTypeDetailComponent } from './customerCategoryTypeDetail.component';

type Props = {
    customerCategoryTypeId: string;
};

export const CustomerCategoryTypeDetailContainer = ({ customerCategoryTypeId }: Props) => {
    const {
        data: customerCategoryTypeData,
        isLoading: isCustomerCategoryTypeLoading,
        error: customerCategoryTypeError,
        isError: isCustomerCategoryTypeError
    } = useCustomerCategoryTypeDetail(customerCategoryTypeId);

    const { update: updateCustomerCategoryTypeMutation, create: createCustomerCategoryTypeMutation } =
        useMutateCustomerCategoryType();
    const { mutateAsync: updateCustomerCategoryType } = updateCustomerCategoryTypeMutation;
    const { mutateAsync: createCustomerCategoryType } = createCustomerCategoryTypeMutation;

    if (isCustomerCategoryTypeLoading) {
        return <Loading />;
    }

    if (isCustomerCategoryTypeError) {
        console.error(customerCategoryTypeError);
        return <></>;
    }

    const handleUpdate = (data: ICustomerCategoryType) => {
        updateCustomerCategoryType(data);
    };

    const handleCreate = (data: ICustomerCategoryType) => {
        createCustomerCategoryType(data);
    };

    const handleSave = (data: ICustomerCategoryType) => (data.id > 0 ? handleUpdate(data) : handleCreate(data));

    return (
        <CustomerCategoryTypeDetailComponent customerCategoryTypeData={customerCategoryTypeData} onSave={handleSave} />
    );
};
