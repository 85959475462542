import axios, { AxiosError, AxiosResponse } from 'axios';

const api_gateway = process.env.REACT_APP_API_GATEWAY;
const token = localStorage.getItem('token');

axios.defaults.baseURL = api_gateway;
if (token) {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
}

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
};

const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError> => {
    if (axios.isAxiosError(error)) {
        const { status } = (error?.response as AxiosResponse) ?? {};

        if (!error?.response) {
            window.location.href = '/';
        }

        switch (status) {
            case 401:
            case 403: {
                window.location.href = '/';
                break;
            }
            case 404: {
                // "Invalid request"
                break;
            }
            case 500: {
                // "Server error"
                break;
            }
            default: {
                // "Unknown error occurred"
                break;
            }
        }
    }

    return Promise.reject(error);
};

axios.interceptors.response.use(onResponse, onErrorResponse);

export default axios;
