import { useTotYearCustomer } from '../api/useSalesList';
import Loading from '../../../components/loading/loading.component';
import { AdesSalesListComponent } from './adesSalesList.component';

export const AdesSalesListContainer = () => {
    const { data, isLoading, error, isError } = useTotYearCustomer();

    if (isLoading) {
        return <Loading />;
    }
    if (isError) {
        console.error(error);
        return <></>;
    }

    const yearList = [];
    const rows = [];

    data.forEach((item) => {
        if (!yearList.find((y) => Number(y) === Number(item.year_ref))) {
            yearList.push(Number(item.year_ref));
        }

        const curRow = rows.find(
            (r) => r.customer_name === item.customer_name.trim() && r.mandator === item.mandator.trim()
        );

        if (curRow) {
            curRow[`qty_${item.year_ref}`] = item.tot_customer_year;
        } else {
            const newRow = {
                id: `${item.customer_name.trim()}_${item.mandator.trim()}`,
                customer_id: item.customer_id,
                customer_code: item.customer_code.trim(),
                customer_name: item.customer_name.trim(),
                mandator: item.mandator.trim(),
                [`qty_${item.year_ref}`]: item.tot_customer_year
            };

            rows.push(newRow);
        }
    });

    return <AdesSalesListComponent data={rows} yearList={yearList} />;
};
