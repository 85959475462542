import React from 'react';
import { Box } from '@material-ui/core';
import { AdesSalesListContainer } from './components/adesSalesList.container';

export const AdesSalesListPage = () => {
    return (
        <Box flexGrow="1" id="container">
            <AdesSalesListContainer />
        </Box>
    );
};
