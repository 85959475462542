import React from 'react';
import { useMandatorList } from '../../mandator/api/useMandatorList';
import { useCustomerMandatorListByCustomer } from '../api/useCustomerMandatorList';
import { useMutateCustomerMandator } from '../api/useMutateCustomerMandator';
import { CustomerMandatorListComponent } from './customerMandatorList.component';
import { ICustomerMandator } from '../interfaces/customerMandator';
import Loading from '../../../components/loading/loading.component';

type Props = {
    customerId: string;
};

export const CustomerMandatorListContainer = ({ customerId }: Props) => {
    const {
        update: updateCustomerMandatorMutation,
        create: createCustomerMandatorMutation,
        remove: removeCustomerMandatorMutation
    } = useMutateCustomerMandator(customerId);
    const { mutateAsync: updateCustomerMandator } = updateCustomerMandatorMutation;
    const { mutateAsync: createCustomerMandator } = createCustomerMandatorMutation;
    const { mutateAsync: removeCustomerMandator } = removeCustomerMandatorMutation;

    const {
        data: mandatorListData,
        isLoading: isMandatorListLoading,
        error: mandatorListError,
        isError: isMandatorListError
    } = useMandatorList();
    const {
        data: customerMandatorListData,
        isLoading: isCustomerMandatorListLoading,
        error: customerMandatorListError,
        isError: isCustomerMandatorListError,
        refetch: refecthCustomerMandatorList
    } = useCustomerMandatorListByCustomer(customerId);

    if (isMandatorListLoading || isCustomerMandatorListLoading) {
        return <Loading />;
    }

    if (isMandatorListError) {
        console.error(mandatorListError);
        return <></>;
    }
    if (isCustomerMandatorListError) {
        console.error(customerMandatorListError);
        return <></>;
    }

    const handleUpdateCustomerMandator = (data: ICustomerMandator) => {
        data.customer_id = parseInt(customerId);
        updateCustomerMandator(data).then(() => {
            refecthCustomerMandatorList();
        });
    };
    const handleCreateCustomerMandator = (data: ICustomerMandator) => {
        data.customer_id = parseInt(customerId);
        createCustomerMandator(data).then(() => {
            refecthCustomerMandatorList();
        });
    };
    const handleRemoveCustomerMandator = (data: ICustomerMandator) => {
        removeCustomerMandator(data).then(() => {
            refecthCustomerMandatorList();
        });
    };

    const handleSaveCustomerMandator = (data: ICustomerMandator) =>
        data.id > 0 ? handleUpdateCustomerMandator(data) : handleCreateCustomerMandator(data);

    return (
        <CustomerMandatorListComponent
            data={customerMandatorListData}
            mandatorList={mandatorListData}
            onSave={handleSaveCustomerMandator}
            onDelete={handleRemoveCustomerMandator}
        />
    );
};
