import React from 'react';
import { useParams } from 'react-router-dom';
import { useResetPwd } from './api/useResetPwd';
import { ResetPwdComponent } from './components/resetPwd.component';

type UrlParams = {
    code: string;
};

export const ResetPwdPage = () => {
    const { code } = useParams<UrlParams>();

    const { mutate: resetPwd } = useResetPwd();

    return <ResetPwdComponent code={code} resetPwd={resetPwd} />;
};
