import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ICustomerCategoryType } from '../interfaces/customerCategoryType';

export const useCustomerCategoryTypeDetail = (customerCategoryTypeId: string) => {
    return useQuery<ICustomerCategoryType, AxiosError>([QUERY_KEYS.CUSTOMER_CATEGORIES_TYPE_DETAIL, customerCategoryTypeId], () =>
        axios.get<ICustomerCategoryType>(`customer-category-type/${customerCategoryTypeId}`).then((response) => response.data)
    );
};
