import React from 'react';
import { useMandatorList } from '../api/useMandatorList';
import { useMutateMandator } from '../api/useMutateMandator';
import Loading from '../../../components/loading/loading.component';
import { MandatorListComponent } from './mandatorList.component';
import { IMandator } from '../interfaces/mandator';

export const MandatorListContainer = () => {
    const { remove: removeMandatorMutation } = useMutateMandator();
    const { mutateAsync: removeMandator } = removeMandatorMutation;

    const {
        data: mandatorListData,
        isLoading: isMandatorListLoading,
        error: mandatorListError,
        isError: isMandatorListError,
        refetch: refecthMandatorList
    } = useMandatorList();

    const handleRemoveMandator = (data: IMandator) => {
        removeMandator(data).then(() => {
            refecthMandatorList();
        });
    };

    // const handleRemoveMandator = React.useCallback(
    //     (data: IMandator) => () => {
    //         removeMandator(data).then(() => {
    //             refecthMandatorList();
    //         });
    //     },
    //     [removeMandator, refecthMandatorList]
    // );

    if (isMandatorListLoading) {
        return <Loading />;
    }

    if (isMandatorListError) {
        console.error(mandatorListError);
        return <></>;
    }

    return <MandatorListComponent data={mandatorListData} onDelete={handleRemoveMandator} />;
};
