import { useCustomerDetail } from '../api/useCustomerDetail';
import { useMutateCustomer } from '../api/useMutateCustomer';
import { useUserList } from '../../user/api/useUserList';
import { CustomerDetailsComponent } from './customerDetails.component';
import { ICustomer } from '../interfaces/customer';
import Loading from '../../../components/loading/loading.component';

type Props = {
    customerId: string;
};

export const CustomerDetailsContainer = ({ customerId }: Props) => {
    const { data: customerData, isLoading, error, isError } = useCustomerDetail(customerId);
    const { data: usersData, isLoading: isUsersLoading, error: usersError, isError: isUsersError } = useUserList();
    const { update: updateCustomerMutation, create: createCustomerMutation } = useMutateCustomer(customerId);

    const { mutate: update } = updateCustomerMutation;
    const { mutate: create } = createCustomerMutation;

    if (isLoading || isUsersLoading) {
        return <Loading />;
    }

    if (isError) {
        console.error(error);
        return <></>;
    }
    if (isUsersError) {
        console.error(usersError);
    }

    const handleSaveCustomer = (data: ICustomer) => (data.id > 0 ? update(data) : create(data));

    return <CustomerDetailsComponent data={customerData} users={usersData} onSave={handleSaveCustomer} />;
};
