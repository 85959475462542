import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ICustomerMandatorList } from '../interfaces/customerMandatorList';

export const useCustomerMandatorListByCustomer = (customerId: string) => {
    return useQuery<ICustomerMandatorList[], AxiosError>([QUERY_KEYS.CUSTOMER_MANDATOR_LIST, customerId], () =>
        parseInt(customerId) > 0
            ? axios
                  .get<ICustomerMandatorList[]>(`customer-mandator/customer/${customerId}`)
                  .then((response) => response.data)
            : []
    );
};

export const useCustomerMandatorList = () => {
    return useQuery<ICustomerMandatorList[], AxiosError>(QUERY_KEYS.CUSTOMER_MANDATOR_LIST, () =>
        axios.get<ICustomerMandatorList[]>(`customer-mandator`).then((response) => response.data)
    );
};
