import React from 'react';
import { useTranslation } from 'react-i18next';
import { useExpiration } from '../hooks/useExpiration';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CssBaseline, Drawer, Toolbar, AppBar, IconButton, Box, Hidden } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../recoil/currentUser.atom';

import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import { useLogout } from '../hooks/useLogout';

import { SideMenu } from '../components/side-menu/sideMenu.component';
import { SecondaryMenuComponent } from '../components/navbar/secondary-menu/secondary-menu.component';
import { Notification } from '../components/notification/notification.component';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up(3000)]: {
                width: drawerWidth,
                flexShrink: 0
            }
        },
        appBar: {
            [theme.breakpoints.up(3000)]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth
            }
        },
        menuButton: {
            // marginRight: theme.spacing(2),
            [theme.breakpoints.up(3000)]: {
                display: 'none'
            }
        },
        drawerPaper: {
            width: drawerWidth
        },
        content: {
            height: '100vh',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(3)
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar
    })
);

interface Props {
    children: React.ReactNode;
}

export const PrivateLayout = ({ children }: Props) => {
    const { i18n } = useTranslation();
    const isExpired = useExpiration();
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const currentUser = useRecoilValue(currentUserState);

    const logout = useLogout();

    const handleChangeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        logout(true);
        return <></>;
    };

    if (isExpired) {
        handleLogout();
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Box display="flex">
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar} elevation={0}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}>
                            <MenuIcon />
                        </IconButton>
                        {/* <SearchComponent /> */}
                        <Box flexGrow={1} />
                        <SecondaryMenuComponent onLogout={handleLogout} currentUser={currentUser} />
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    <Hidden xlUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            ModalProps={{
                                keepMounted: true
                            }}>
                            <SideMenu activeLanguage={i18n.language} onChangeLanguage={handleChangeLanguage} />
                        </Drawer>
                    </Hidden>
                    <Hidden xlDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            variant="permanent"
                            open>
                            <SideMenu activeLanguage={i18n.language} onChangeLanguage={handleChangeLanguage} />
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Box id="private-layout-body" display="flex" flex="1">
                        {children}
                    </Box>
                </main>
            </Box>
            <Notification />
        </DndProvider>
    );
};
