import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { IMandator } from '../interfaces/mandator';

export const useMandatorList = () => {
    return useQuery<IMandator[], AxiosError>(QUERY_KEYS.MANDATOR_LIST, () =>
        axios.get<IMandator[]>('mandator').then((response) => response.data)
    );
};
