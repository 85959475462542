import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { IUser } from '../interfaces/user';

export const useUserDetail = (userId: string) => {
    return useQuery<IUser, AxiosError>([QUERY_KEYS.USER_DETAIL, userId], () =>
        axios.get<IUser>(`user/${userId}`).then((response) => response.data)
    );
};
