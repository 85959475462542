import React from 'react';
import { Box } from '@material-ui/core';
import { MandatorListContainer } from './components/mandatorList.container';

export const MandatorListPage = () => {
    return (
        <Box flexGrow="1" id="container">
            <MandatorListContainer />
        </Box>
    );
};
