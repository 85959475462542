import React from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ADDRESS_TYPE } from '../../../config/constants';
import { ICustomerAddress } from '../interfaces/customerAddress';
import { Field, Form } from 'react-final-form';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';

const ADDRESS_TYPES = Object.keys(ADDRESS_TYPE);

type Props = {
    data: ICustomerAddress[];
    onSave: Function;
    onDelete: Function;
};

export const CustomerAddressListComponent = ({ data, onSave, onDelete }: Props) => {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [addressData, setAddressData] = React.useState<ICustomerAddress>();
    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'customerAddressList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const handleDelete = (row: any) => {
        if (window.confirm(t('CUSTOMER_ADDRESSES.CONF_DELETE'))) {
            onDelete(row);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'address_type',
            headerName: t(`CUSTOMER_ADDRESSES.FIELDS.address_type`),
            width: getColumnsWidth('address_type', 150, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('address_type', true, columnVisibility),
            renderCell: (params: GridCellParams) => {
                return t(`ADDRESS_TYPES.${params.row.address_type}`);
            }
        },
        {
            field: 'address',
            headerName: t(`CUSTOMER_ADDRESSES.FIELDS.address`),
            width: getColumnsWidth('address', 300, columnWidth),
            hide: !isColumnsVisibile('address', true, columnVisibility)
        },
        {
            field: 'zip',
            headerName: t('CUSTOMER_ADDRESSES.FIELDS.zip'),
            width: getColumnsWidth('zip', 150, columnWidth),
            hide: !isColumnsVisibile('zip', true, columnVisibility)
        },
        {
            field: 'city',
            headerName: t('CUSTOMER_ADDRESSES.FIELDS.city'),
            width: getColumnsWidth('city', 300, columnWidth),
            hide: !isColumnsVisibile('city', true, columnVisibility)
        },
        {
            field: 'province',
            headerName: t('CUSTOMER_ADDRESSES.FIELDS.province'),
            width: getColumnsWidth('province', 150, columnWidth),
            hide: !isColumnsVisibile('province', true, columnVisibility)
        },
        {
            field: 'country_iso2',
            headerName: t('CUSTOMER_ADDRESSES.FIELDS.country_iso2'),
            width: getColumnsWidth('country_iso2', 150, columnWidth),
            hide: !isColumnsVisibile('country_iso2', true, columnVisibility)
        },
        {
            field: 'delete',
            headerName: t(`COMMON.DELETE`),
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            width: getColumnsWidth('delete', 150, columnWidth),
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => (
                <IconButton color="secondary" onClick={() => handleDelete(params.row)}>
                    <DeleteForeverIcon />
                </IconButton>
            )
        },
        {
            field: 'id',
            headerName: t('CUSTOMER_ADDRESSES.FIELDS.id'),
            width: getColumnsWidth('id', 60, columnWidth),
            disableColumnMenu: true,
            sortable: false,
            hide: !isColumnsVisibile('id', false, columnVisibility)
        }
    ];

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        const { row } = params;

        setAddressData(row as ICustomerAddress);

        setOpen(true);
    };

    const handleAddNewClick = () => {
        setAddressData({} as ICustomerAddress);
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const onSubmit = (values: ICustomerAddress) => {
        onSave(values);
        setOpen(false);
    };

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <DataGridDefault
                        id={listId}
                        columns={columns}
                        data={data}
                        height="30vh"
                        showFooter={false}
                        handleAddNewClick={handleAddNewClick}
                        handleRowDoubleClick={handleRowDoubleClick}
                        setColumnVisibility={setColumnVisibility}
                        setColumnWidth={setColumnWidth}
                    />
                </CardContent>
            </Card>
            <Dialog fullWidth onClose={handleCancel} open={open}>
                <DialogContent dividers>
                    <Form
                        onSubmit={onSubmit}
                        validate={(values) => {
                            const errors: { [key: string]: string } = {};

                            if (!values.address) {
                                errors.address = t('COMMON.FIELD_REQUIRED');
                            }
                            if (!values.city) {
                                errors.city = t('COMMON.FIELD_REQUIRED');
                            }
                            if (!values.province) {
                                errors.province = t('COMMON.FIELD_REQUIRED');
                            }

                            if (values.country_iso2 && values.country_iso2.length > 2) {
                                errors.country_iso2 = t('COMMON.FIELD_MAX_LEN', { num: 2 });
                            }

                            return errors;
                        }}
                        initialValues={addressData}
                        render={({ handleSubmit, hasValidationErrors }) => (
                            <form onSubmit={handleSubmit}>
                                {addressData.id && <Box my={3}>ID: {addressData.id}</Box>}
                                <Box my={1}>
                                    <Field name="address_type">
                                        {({ input, meta }) => (
                                            <FormControl fullWidth>
                                                <InputLabel>{t('CUSTOMER_ADDRESSES.FIELDS.address_type')}</InputLabel>
                                                <Select {...input} error={meta.error && meta.touched}>
                                                    {ADDRESS_TYPES.map((item) => (
                                                        <MenuItem key={item} value={item}>
                                                            {t(`ADDRESS_TYPES.${item}`)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field name="address">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMER_ADDRESSES.FIELDS.address')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMER_ADDRESSES.FIELDS.address')}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field name="zip">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMER_ADDRESSES.FIELDS.zip')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMER_ADDRESSES.FIELDS.zip')}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field name="city">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMER_ADDRESSES.FIELDS.city')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMER_ADDRESSES.FIELDS.city')}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field name="province">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMER_ADDRESSES.FIELDS.province')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMER_ADDRESSES.FIELDS.province')}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field name="country_iso2">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMER_ADDRESSES.FIELDS.country_iso2')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMER_ADDRESSES.FIELDS.country_iso2')}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field name="timezone">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMER_ADDRESSES.FIELDS.timezone')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMER_ADDRESSES.FIELDS.timezone')}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </Box>

                                <Box mt={3} textAlign="right">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={hasValidationErrors}>
                                        {t('COMMON.SAVE')}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};
