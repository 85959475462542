import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { IActivityType } from '../interfaces/activityType';

export const useActivityTypeList = () => {
    return useQuery<IActivityType[], AxiosError>(QUERY_KEYS.ACTIVITY_TYPE_LIST, () =>
        axios.get<IActivityType[]>('activity-type').then((response) => response.data)
    );
};
