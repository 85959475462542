import { useTranslation } from 'react-i18next';
import { useCustomerEmployeeList } from '../api/useCustomerEmployeeList';
import { useMutateEmployee } from '../api/useMutateEmployee';
import Loading from '../../../components/loading/loading.component';
import { EmployeeListComponent } from './employeeList.component';

export const EmployeeListContainer = () => {
    const { i18n } = useTranslation();
    const lang = i18n.language;

    const {
        data: employeeListData,
        isLoading: isEmployeeListLoading,
        error: employeeListError,
        isError: isEmployeeListError,
        refetch: refetchEmployeeList
    } = useCustomerEmployeeList();

    const { remove: removeEmployeeMutation } = useMutateEmployee();

    const { mutateAsync: removeEmployee } = removeEmployeeMutation;

    if (isEmployeeListLoading) {
        return <Loading />;
    }

    if (isEmployeeListError) {
        console.error(employeeListError);
        return <></>;
    }

    const handleDelete = (row: any) => {
        removeEmployee(row).then(() => refetchEmployeeList());
    };

    employeeListData.forEach((item) => {
        item['employee_id'] = item.id;
        item.id = item.id + item.customer_id;
        item.is_active = Boolean(Number(item.is_active));
        item.personal_annotation = item?.personal_annotation?.replace(/[\r\n]/gm, '') ?? '';
        item.company_annotation = item?.company_annotation?.replace(/[\r\n]/gm, '') ?? '';
        item.from_date = item.from_date ? new Date(item.from_date).toLocaleDateString(lang) : '';
        item.to_date = item.to_date ? new Date(item.to_date).toLocaleDateString(lang) : '';
    });

    return <EmployeeListComponent data={employeeListData} onDelete={handleDelete} />;
};
