import { Box } from '@material-ui/core';
import { AdesAllSalesListContainer } from './components/adesAllSalesList.container';

export const AdesAllSalesListPage = () => {
    return (
        <Box flexGrow="1" id="container">
            <AdesAllSalesListContainer />
        </Box>
    );
};
