import React from 'react';
import { useCustomerCategoryTypeList } from '../api/useCustomerCategoryTypeList';
import { useMutateCustomerCategoryType } from '../api/useMutateCustomerCategoryType';
import { CustomerCategoryTypeListComponent } from './customerCategoryTypeList.component';
import { ICustomerCategoryType } from '../interfaces/customerCategoryType';
import Loading from '../../../components/loading/loading.component';

export const CustomerCategoryTypeListContainer = () => {
    const { remove: removeCustomerCategoryTypeMutation } = useMutateCustomerCategoryType();
    const { mutateAsync: removeCustomerCategoryType } = removeCustomerCategoryTypeMutation;

    const {
        data: customerCategoryTypeListData,
        isLoading: isCustomerCategoryTypeListLoading,
        error: customerCategoryTypeListError,
        isError: isCustomerCategoryTypeListError,
        refetch: refecthCustomerCategoryTypeList
    } = useCustomerCategoryTypeList();

    const handleRemoveCustomerCategoryType = (data: ICustomerCategoryType) => {
        removeCustomerCategoryType(data).then(() => {
            refecthCustomerCategoryTypeList();
        });
    };

    if (isCustomerCategoryTypeListLoading) {
        return <Loading />;
    }

    if (isCustomerCategoryTypeListError) {
        console.error(customerCategoryTypeListError);
        return <></>;
    }

    return <CustomerCategoryTypeListComponent data={customerCategoryTypeListData} onDelete={handleRemoveCustomerCategoryType} />;
};
