import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Card, CardContent, Typography, FormControlLabel, Switch } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { BackToList } from '../../../components/back-to-list/back-to-list';
import { IUser } from '../interfaces/user';
import { ROUTE_PATHS } from '../../../config/routes';

type Props = {
    userData: IUser;
    isAdmin: boolean;
    onSave: any;
};

export const UserDetailComponent = ({ userData, isAdmin, onSave }: Props) => {
    const { t } = useTranslation();

    const onSubmit = (values: IUser) => {
        onSave(values);
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <BackToList path={ROUTE_PATHS.USERS} />
                <Typography variant="h4">
                    {userData?.firstname} {userData?.lastname}
                </Typography>
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors: { [key: string]: string } = {};

                        if (!values.email) {
                            errors.email = t('COMMON.FIELD_REQUIRED');
                        }
                        if (values.new_password && values.new_password.length < 6) {
                            errors.new_password = t('USERS.ERRORS.PWD_TOO_SHORT', { min: 6 });
                        }
                        if (values.new_password && values.new_password.length > 16) {
                            errors.new_password = t('USERS.ERRORS.PWD_TOO_LONG', { max: 16 });
                        }
                        if (
                            values.new_password &&
                            values.new_password_bis &&
                            values.new_password !== values.new_password_bis
                        ) {
                            errors.new_password_bis = t('USERS.ERRORS.PWD_NOT_EQUAL');
                        }

                        return errors;
                    }}
                    initialValues={userData}
                    render={({ handleSubmit, hasValidationErrors }) => (
                        <form onSubmit={handleSubmit}>
                            {userData.id && <Box my={3}>ID: {userData.id}</Box>}

                            <Box my={1}>
                                <Field name="firstname">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.firstname')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.firstname')}
                                            fullWidth
                                            required
                                        />
                                    )}
                                </Field>
                                <Field name="lastname">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.lastname')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.lastname')}
                                            fullWidth
                                            required
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="shortname">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.shortname')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.shortname')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="address">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.address')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.address')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="city">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.city')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.city')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="province">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.province')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.province')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="email">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.email')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.email')}
                                            fullWidth
                                            required
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="email_private">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.email_private')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.email_private')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="phone">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.phone')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.phone')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="mobile">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.mobile')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.mobile')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="annotation">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            multiline
                                            rows={6}
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.annotation')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.annotation')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            {isAdmin && (
                                <>
                                    <Box my={1}>
                                        <Field name="is_active" type="checkbox" defaultValue={userData.is_active}>
                                            {({ input, meta }) => {
                                                return (
                                                    <FormControlLabel
                                                        control={<Switch {...input} color="primary" />}
                                                        label={t('USERS.FIELDS.is_active')}
                                                    />
                                                );
                                            }}
                                        </Field>
                                    </Box>
                                    <Box my={1}>
                                        <Field name="new_password">
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    type="password"
                                                    error={meta.error && meta.touched}
                                                    label={t('USERS.FIELDS.new_password')}
                                                    helperText={meta.error && meta.touched ? meta.error : ''}
                                                    placeholder={t('USERS.FIELDS.new_password')}
                                                    fullWidth
                                                />
                                            )}
                                        </Field>
                                    </Box>
                                    <Box my={1}>
                                        <Field name="new_password_bis">
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    type="password"
                                                    error={meta.error && meta.touched}
                                                    label={t('USERS.FIELDS.new_password_bis')}
                                                    helperText={meta.error && meta.touched ? meta.error : ''}
                                                    placeholder={t('USERS.FIELDS.new_password_bis')}
                                                    fullWidth
                                                />
                                            )}
                                        </Field>
                                    </Box>
                                </>
                            )}

                            <Box mt={30} textAlign="right">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={hasValidationErrors}>
                                    {t('COMMON.SAVE')}
                                </Button>
                            </Box>
                        </form>
                    )}
                />
            </CardContent>
        </Card>
    );
};
