import axios from '../../../helpers/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { currentUserState } from '../../../recoil/currentUser.atom';
import { ILogin } from '../../../interfaces/login';
import { IUser } from '../../user/interfaces/user';
import { ROUTE_PATHS } from '../../../config/routes';

interface LoginRes {
    token: string;
    exp: string;
    user: IUser;
}

export const useLogin = () => {
    const setUser = useSetRecoilState(currentUserState);

    return useMutation<AxiosResponse<LoginRes>, AxiosError, ILogin, unknown>(
        ({ email, password }: ILogin) =>
            axios.post('/login', {
                email,
                password
            }),
        {
            onError: (e) => {
                console.error('useLogin', e);
            },
            onSuccess: ({ data }) => {
                if (data && data.token && data.user) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('exp', data.exp);

                    setUser(data.user);
                }

                window.location.href = ROUTE_PATHS.CUSTOMERS;
            }
        }
    );
};

export const useRelogin = () => {
    const setUser = useSetRecoilState(currentUserState);

    return useMutation<AxiosResponse<LoginRes>, AxiosError, string, unknown>(
        (email) =>
            axios.post('/relogin', {
                email
            }),
        {
            onError: (e) => {
                console.error('useRelogin', e);
                window.location.href = ROUTE_PATHS.LOGIN;
            },
            onSuccess: ({ data }) => {
                if (data && data.token) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('exp', data.exp);
                }
                if (data && data.user) {
                    setUser(data.user);
                }
            }
        }
    );
};
