import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { IAdesSales } from '../interfaces/adesSales';
import { IAdesSaleTotYearByProduct } from '../interfaces/adesSaleTotYearByProduct';
import { IAdesSaleQtyPastYear } from '../interfaces/adesSaleQtyPastYear';
import { IAdesSaleTotYearByCustomer } from '../interfaces/adesSaleTotYearByCustomer';
import { IAdesSaleTotYearCustomer } from '../interfaces/adesSaleTotYearCustomer';

export const useSalesListByCustomerYear = (customerId: string, year: number) => {
    return useQuery<IAdesSales[], AxiosError>([QUERY_KEYS.ADES_SALES_LIST_BY_CUSTOMER_YEAR, customerId, year], () =>
        axios.get<IAdesSales[]>(`ades-sales/${customerId}/${year}`).then((response) => response.data)
    );
};

export const useSalesListByYear = (year: number) => {
    return useQuery<IAdesSales[], AxiosError>([QUERY_KEYS.ADES_SALES_LIST_BY_CUSTOMER_YEAR, year], () =>
        axios.get<IAdesSales[]>(`ades-sales/${year}`).then((response) => response.data)
    );
};

export const useSalesListByCustomerPastYears = (customerId: string, year: number) => {
    return useQuery<IAdesSaleQtyPastYear[], AxiosError>([QUERY_KEYS.ADES_SALES_QTY_YEAR, year, customerId], () =>
        axios
            .get<IAdesSaleQtyPastYear[]>(`ades-sales/qty-past-years/${year}/${customerId}`)
            .then((response) => response.data)
    );
};

export const useSalesListByPastYears = (year: number) => {
    const curYear = new Date().getFullYear();

    const yearParam = year < 2000 || year > curYear ? curYear : year;

    return useQuery<IAdesSaleQtyPastYear[], AxiosError>([QUERY_KEYS.ADES_SALES_QTY_YEAR, yearParam], () =>
        axios.get<IAdesSaleQtyPastYear[]>(`ades-sales/qty-past-years/${yearParam}`).then((response) => response.data)
    );
};

export const useYearList = (customerId?: string) => {
    return useQuery<{ year_ref: number }[], AxiosError>([QUERY_KEYS.ADES_SALES_YEAR_LIST, customerId], () =>
        customerId
            ? axios.get<{ year_ref: number }[]>(`ades-sales/year-list/${customerId}`).then((response) => response.data)
            : axios.get<{ year_ref: number }[]>(`ades-sales/year-list`).then((response) => response.data)
    );
};

export const useTotYearByProduct = (productId: string) => {
    return useQuery<IAdesSaleTotYearByProduct[], AxiosError>(
        [QUERY_KEYS.ADES_SALES_TOT_PRODUCT_YEAR_LIST, productId],
        () =>
            axios
                .get<IAdesSaleTotYearByProduct[]>(`ades-sales/tot-product-year/${productId}`)
                .then((response) => response.data)
    );
};

export const useTotYearByCustomer = (customerId: string) => {
    return useQuery<IAdesSaleTotYearByCustomer[], AxiosError>(
        [QUERY_KEYS.ADES_SALES_TOT_CUSTOMER_YEAR_LIST, customerId],
        () =>
            axios
                .get<IAdesSaleTotYearByCustomer[]>(`ades-sales/tot-customer-year/${customerId}`)
                .then((response) => response.data)
    );
};

export const useTotYearCustomer = () => {
    return useQuery<IAdesSaleTotYearCustomer[], AxiosError>(QUERY_KEYS.ADES_SALES_TOT_YEAR_CUSTOMER, () =>
        axios.get<IAdesSaleTotYearCustomer[]>(`ades-sales/tot-year-customer`).then((response) => response.data)
    );
};
