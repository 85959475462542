import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Card, CardContent, Typography } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { BackToList } from '../../../components/back-to-list/back-to-list';
import { ICustomerCategoryType } from '../interfaces/customerCategoryType';
import { ROUTE_PATHS } from '../../../config/routes';

type Props = {
    customerCategoryTypeData: ICustomerCategoryType;
    onSave: any;
};

export const CustomerCategoryTypeDetailComponent = ({ customerCategoryTypeData, onSave }: Props) => {
    const { t } = useTranslation();

    const onSubmit = (values: ICustomerCategoryType) => {
        onSave(values);
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <BackToList path={ROUTE_PATHS.CUSTOMER_CATEGORY_TYPES} />
                <Typography variant="h4">{customerCategoryTypeData?.category}</Typography>
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors: { [key: string]: string } = {};

                        if (!values.category) {
                            errors.customerCategoryType_customerCategoryType_code = t('COMMON.FIELD_REQUIRED');
                        }

                        return errors;
                    }}
                    initialValues={customerCategoryTypeData}
                    render={({ handleSubmit, hasValidationErrors }) => (
                        <form onSubmit={handleSubmit}>
                            {customerCategoryTypeData.id && <Box my={3}>ID: {customerCategoryTypeData.id}</Box>}

                            <Box my={1}>
                                <Field name="category">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMER_CATEGORIES.FIELDS.category')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMER_CATEGORIES.FIELDS.category')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="annotation">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMER_CATEGORIES.FIELDS.annotation')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMER_CATEGORIES.FIELDS.annotation')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>

                            <Box mt={3} textAlign="right">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={hasValidationErrors}>
                                    {t('COMMON.SAVE')}
                                </Button>
                            </Box>
                        </form>
                    )}
                />
            </CardContent>
        </Card>
    );
};
