import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { IProduct } from '../interfaces/product';

export const useProductList = () => {
    return useQuery<IProduct[], AxiosError>(QUERY_KEYS.PRODUCT_LIST, () =>
        axios.get<IProduct[]>(`product`).then((response) => response.data)
    );
};
