import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import { Card, CardContent, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { ICustomerCategoryType } from '../interfaces/customerCategoryType';
import { ROUTE_PATHS } from '../../../config/routes';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';

type Props = {
    data: ICustomerCategoryType[];
    onDelete: Function;
};

export const CustomerCategoryTypeListComponent = ({ data, onDelete }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'customerCategoryTypeList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        history.push(`${ROUTE_PATHS.CUSTOMER_CATEGORY_TYPES}/${params.row.id}`);
    };

    const handleAddNewClick = () => {
        history.push(`${ROUTE_PATHS.CUSTOMER_CATEGORY_TYPES}/new`);
    };

    const handleDelete = (row: any) => {
        if (window.confirm(t('CUSTOMER_CATEGORIES.CONF_DELETE'))) {
            onDelete(row);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'category',
            headerName: t(`CUSTOMER_CATEGORIES.FIELDS.category`),
            width: getColumnsWidth('category', 200, columnWidth),
            hide: !isColumnsVisibile('category', true, columnVisibility)
        },
        {
            field: 'annotation',
            headerName: t(`CUSTOMER_CATEGORIES.FIELDS.annotation`),
            width: getColumnsWidth('annotation', 300, columnWidth),
            hide: !isColumnsVisibile('annotation', true, columnVisibility)
        },
        {
            field: 'delete',
            headerName: t(`COMMON.DELETE`),
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            width: getColumnsWidth('delete', 130, columnWidth),
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => (
                <IconButton
                    color="secondary"
                    onClick={() => {
                        handleDelete(params.row);
                    }}>
                    <DeleteForeverIcon />
                </IconButton>
            )
        },
        {
            field: 'id',
            headerName: t('CUSTOMER_CATEGORIES.FIELDS.id'),
            width: getColumnsWidth('id', 60, columnWidth),
            disableColumnMenu: true,
            sortable: false,
            hide: !isColumnsVisibile('id', false, columnVisibility)
        }
    ];

    return (
        <Card variant="outlined">
            <CardContent>
                <DataGridDefault
                    id={listId}
                    columns={columns}
                    data={data}
                    handleAddNewClick={handleAddNewClick}
                    handleRowDoubleClick={handleRowDoubleClick}
                    setColumnVisibility={setColumnVisibility}
                        setColumnWidth={setColumnWidth}
                />
            </CardContent>
        </Card>
    );
};
