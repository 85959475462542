import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        // maxWidth: '200px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'stretch'
    },
    logo: {
        maxWidth: '100%',
        maxHeight: theme.mixins.toolbar.minHeight,
        alignSelf: 'center'
    }
}));

export const LogoComponent = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img src="/images/logo-customer.png" alt="" className={classes.logo} />
        </div>
    );
};
