import React from 'react';
import { Box } from '@material-ui/core';
import { ProductListContainer } from './components/productList.container';

export const ProductListPage = () => {
    return (
        <Box flexGrow="1" id="container">
            <ProductListContainer />
        </Box>
    );
};
