import React from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { UserDetailContainer } from './components/userDetail.container';

type UrlParams = {
    id: string;
};

export const UserDetailPage = () => {
    const { id } = useParams<UrlParams>();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <UserDetailContainer userId={id} />
            </Grid>
        </Grid>
    );
};
