export const DEFAULT_LIST_LENGTH = 40;

export const ADDRESS_TYPE = {
    OPERATIONAL_HEADQUARTER: 'OPERATIONAL_HEADQUARTER',
    REGISTERED_OFFICE: 'REGISTERED_OFFICE',
    OTHER: 'OTHER'
};

export const CONTACT_TYPE = {
    TEL: 'TEL',
    MOBILE: 'MOBILE',
    EMAIL: 'EMAIL',
    WWW: 'WWW',
    OTHER: 'OTHER'
};

export const USER_ROLES = {
    ADMIN: 'admin',
    USER: 'user'
};

export const LOG_LEVELS = {
    INFO: 'INFO',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
    SYSTEM: 'SYSTEM'
};

export const ADES_SALE_PRICE_VAR = 1.3;

export const IMPORT_COL_WIDTH = 210;
