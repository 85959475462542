import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarColumnsButton,
    GridToolbarContainer
} from '@mui/x-data-grid-pro';

type Props = {
    expFileName?: string;
    onAddNewClick?: React.MouseEventHandler;
};

export const ListToolbar = ({ expFileName, onAddNewClick }: Props) => {
    const { t } = useTranslation();

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport csvOptions={{ fileName: expFileName ?? 'crm-export', delimiter: ';' }} />
            {onAddNewClick && (
                <Button
                    variant="text"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={onAddNewClick}>
                    {t('COMMON.NEW')}
                </Button>
            )}
        </GridToolbarContainer>
    );
};
