import { PrivateLayout } from '../layouts/private.layout';
import { RestrictedLayout } from '../layouts/restricted.layout';
import { PublicLayout } from '../layouts/public.layout';
import { Home } from '../pages/home/home.page';
import { SignInPage } from '../pages/signIn/signIn.page';
import { LostPwdPage } from '../pages/lostPwd/lostPwd.page';
import { ResetPwdPage } from '../pages/resetPwd/resetPwd.page';
import { SignUpPage } from '../pages/sign-up/sign-up.page';
import { Dashboard } from '../pages/dashboard/dashboard.page';
import { ImportFile } from '../pages/importFile/importFile.page';
import { ImportFileCustomer } from '../pages/importFile/importFileCustomer.page';
import { ImportFileAdes } from '../pages/importFile/importFileAdes.page';
import { CustomerListPage } from '../pages/customer/customerList.page';
import { CustomerDetailPage } from '../pages/customer/customerDetail.page';
import { ActivityListPage } from '../pages/activity/activityList.page';
import { ProductListPage } from '../pages/product/productList.page';
import { ProductDetailPage } from '../pages/product/productDetail.page';
import { AdesSalesListPage } from '../pages/adesSales/adesSalesList.page';
import { AdesAllSalesListPage } from '../pages/adesSales/adesAllSalesList.page';
import { EmployeeListPage } from '../pages/employee/employeeList.page';
import { EmployeeDetailPage } from '../pages/employee/employeeDetail.page';
import { MandatorListPage } from '../pages/mandator/mandatorList.page';
import { MandatorDetailPage } from '../pages/mandator/mandatorDetail.page';
import { CustomerCategoryTypeListPage } from '../pages/customerCategoryType/customerCategoryTypeList.page';
import { CustomerCategoryTypeDetailPage } from '../pages/customerCategoryType/customerCategoryTypeDetail.page';
import { UserDetailPage } from '../pages/user/userDetail.page';
import { UserListPage } from '../pages/user/userList.page';
import { ImportFileSales } from '../pages/importFile/importFileSales.page';
import { ImportFileProduct } from '../pages/importFile/importFileProduct.page';

export const ROUTE_PATHS = {
    ROOT: '/',
    LOGIN: '/login',
    FORGOT_PWD: '/forgot-pwd',
    RESET_PWD: '/reset-pwd',
    REGISTER: '/register',
    DASHBOARD: '/dashboard',
    CUSTOMERS: '/customers',
    CUSTOMER_EMPLOYEES: '/customer_employees',
    EMPLOYEES: '/employees',
    MANDATORS: '/mandators',
    VENDORS: '/vendors',
    ACTIVITIES: '/activities',
    PRODUCTS: '/products',
    SALES: '/sales',
    ALL_SALES: '/all-sales',
    USERS: '/users',
    CUSTOMER_CATEGORY_TYPES: '/customer_category_types',
    IMPORT_FILES: '/importFiles',
    IMPORT_ADES: '/ades'
};

export const publicRoutes = [
    {
        id: 'home',
        path: ROUTE_PATHS.ROOT,
        exact: true,
        component: Home,
        layout: PublicLayout
    }
];

export const restrictedRoutes = [
    {
        id: 'login',
        path: ROUTE_PATHS.LOGIN,
        exact: true,
        component: SignInPage,
        layout: RestrictedLayout
    },
    {
        id: 'forgot_pwd',
        path: ROUTE_PATHS.FORGOT_PWD,
        exact: true,
        component: LostPwdPage,
        layout: RestrictedLayout
    },
    {
        id: 'reset_pwd',
        path: ROUTE_PATHS.RESET_PWD + '/:code',
        exact: true,
        component: ResetPwdPage,
        layout: RestrictedLayout
    },
    {
        id: 'register',
        path: ROUTE_PATHS.REGISTER,
        exact: true,
        component: SignUpPage,
        layout: RestrictedLayout
    }
];

export const privateRoutes = [
    {
        id: 'dashboard',
        path: ROUTE_PATHS.DASHBOARD,
        exact: true,
        component: Dashboard,
        layout: PrivateLayout
    },
    {
        id: 'customers',
        path: ROUTE_PATHS.CUSTOMERS,
        component: CustomerListPage,
        layout: PrivateLayout,
        exact: true
    },
    {
        id: 'customer_employees',
        path: ROUTE_PATHS.CUSTOMER_EMPLOYEES,
        component: Dashboard,
        layout: PrivateLayout,
        exact: true
    },
    {
        id: 'customer',
        path: ROUTE_PATHS.CUSTOMERS + '/:id/:tab?',
        component: CustomerDetailPage,
        layout: PrivateLayout
    },
    {
        id: 'employees',
        path: ROUTE_PATHS.EMPLOYEES,
        exact: true,
        component: EmployeeListPage,
        layout: PrivateLayout
    },
    {
        id: 'employee',
        path: ROUTE_PATHS.EMPLOYEES + '/:id',
        component: EmployeeDetailPage,
        layout: PrivateLayout
    },
    {
        id: 'mandators',
        path: ROUTE_PATHS.MANDATORS,
        exact: true,
        component: MandatorListPage,
        layout: PrivateLayout
    },
    {
        id: 'mandator',
        path: ROUTE_PATHS.MANDATORS + '/:id',
        exact: true,
        component: MandatorDetailPage,
        layout: PrivateLayout
    },
    {
        id: 'vendors',
        path: ROUTE_PATHS.VENDORS,
        exact: true,
        component: Dashboard,
        layout: PrivateLayout
    },
    {
        id: 'activities',
        path: ROUTE_PATHS.ACTIVITIES,
        exact: true,
        component: ActivityListPage,
        layout: PrivateLayout
    },
    {
        id: 'products',
        path: ROUTE_PATHS.PRODUCTS,
        exact: true,
        component: ProductListPage,
        layout: PrivateLayout
    },
    {
        id: 'products',
        path: ROUTE_PATHS.PRODUCTS + '/sel/:id_selected?',
        exact: true,
        component: ProductListPage,
        layout: PrivateLayout
    },
    {
        id: 'product',
        path: ROUTE_PATHS.PRODUCTS + '/:id',
        exact: true,
        component: ProductDetailPage,
        layout: PrivateLayout
    },
    {
        id: 'sales',
        path: ROUTE_PATHS.SALES,
        exact: true,
        component: AdesSalesListPage,
        layout: PrivateLayout
    },
    {
        id: 'all_sales',
        path: ROUTE_PATHS.ALL_SALES,
        exact: true,
        component: AdesAllSalesListPage,
        layout: PrivateLayout
    },
    {
        id: 'customer_category_types',
        path: ROUTE_PATHS.CUSTOMER_CATEGORY_TYPES,
        exact: true,
        component: CustomerCategoryTypeListPage,
        layout: PrivateLayout
    },
    {
        id: 'customer_category_type',
        path: ROUTE_PATHS.CUSTOMER_CATEGORY_TYPES + '/:id',
        exact: true,
        component: CustomerCategoryTypeDetailPage,
        layout: PrivateLayout
    },
    {
        id: 'users',
        path: ROUTE_PATHS.USERS,
        exact: true,
        component: UserListPage,
        layout: PrivateLayout
    },
    {
        id: 'user',
        path: ROUTE_PATHS.USERS + '/:id',
        exact: true,
        component: UserDetailPage,
        layout: PrivateLayout
    },
    {
        id: 'import_files',
        path: ROUTE_PATHS.IMPORT_FILES,
        exact: true,
        component: ImportFile,
        layout: PrivateLayout
    },
    {
        id: 'import_files_customers',
        path: ROUTE_PATHS.IMPORT_FILES + ROUTE_PATHS.CUSTOMERS,
        exact: true,
        component: ImportFileCustomer,
        layout: PrivateLayout
    },
    {
        id: 'import_files_products',
        path: ROUTE_PATHS.IMPORT_FILES + ROUTE_PATHS.PRODUCTS,
        exact: true,
        component: ImportFileProduct,
        layout: PrivateLayout
    },
    {
        id: 'import_files_sales',
        path: ROUTE_PATHS.IMPORT_FILES + ROUTE_PATHS.SALES,
        exact: true,
        component: ImportFileSales,
        layout: PrivateLayout
    },
    {
        id: 'import_files_ades',
        path: ROUTE_PATHS.IMPORT_FILES + ROUTE_PATHS.IMPORT_ADES,
        exact: true,
        component: ImportFileAdes,
        layout: PrivateLayout
    }
];
