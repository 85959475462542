import axios from '../../../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationState } from '../../../recoil/notification.atom';
import { IUser } from '../interfaces/user';
import { ROUTE_PATHS } from '../../../config/routes';

export const useMutateUser = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const setNotification = useSetRecoilState(notificationState);

    const create = useMutation<AxiosResponse, AxiosError, IUser>(
        (data: IUser) => axios.post(`user`, data),

        {
            onSuccess: async (res) => {
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('USERS.CREATE_SUCCESS')
                });
                history.push(`${ROUTE_PATHS.USERS}/${res?.data}`);
                return res;
            },
            onError: (err) => {
                // console.error('Create User error:', err);
                setNotification({ open: true, severity: 'error', message: t('USERS.CREATE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.USER_LIST);
            }
        }
    );

    const update = useMutation<AxiosResponse, AxiosError, IUser>((data: IUser) => axios.put(`user/${data.id}`, data), {
        onSuccess: async (res) => {
            return setNotification({
                open: true,
                severity: 'success',
                message: t('USERS.UPDATE_SUCCESS')
            });
        },
        onError: (err) => {
            console.error(err.request.response);
            setNotification({ open: true, severity: 'error', message: t('USERS.UPDATE_ERROR') });
        },
        onSettled: async () => {
            await queryClient.invalidateQueries(QUERY_KEYS.USER_LIST);
        }
    });

    const remove = useMutation<AxiosResponse, AxiosError, IUser>((data: IUser) => axios.delete(`user/${data.id}`), {
        onSuccess: async (res) =>
            setNotification({
                open: true,
                severity: 'success',
                message: t('USERS.DELETE_SUCCESS')
            }),
        onError: (err) => {
            console.error(err.request.response);
            setNotification({ open: true, severity: 'error', message: t('USERS.DELETE_ERROR') });
        },
        onSettled: async () => {
            await queryClient.invalidateQueries(QUERY_KEYS.USER_LIST);
        }
    });

    return { update, create, remove };
};
