import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationState } from '../../recoil/notification.atom';
import { useImportFile } from '../../pages/importFile/api/useImportFile';
import { useGridColumnBuilder } from '../../hooks/useGridColumnBuilder';
import { useGridRowBuilder } from '../../hooks/useGridRowBuilder';
import { Container, Box, Divider, Button } from '@material-ui/core';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LoadingComponent as Loader } from '../loading/fullscreen_loading.component';
import { ImportFile as FileUploader, ResponseType } from './fileUploader.component';
import { Dustbin } from './dustbin.component';
import { DragColumn } from './dragColumn.component';

type Props = {
    fieldsData: string[];
    fieldsDataTrans: string[];
    importPath: string;
    showDelButton?: boolean;
};

export const ImportFileCSV = ({ fieldsData, fieldsDataTrans, importPath, showDelButton }: Props) => {
    const { t } = useTranslation();
    const setNotification = useSetRecoilState(notificationState);

    const { mutate, isLoading: isImportLoading } = useImportFile();

    const [previewData, setPreviewData] = React.useState<string[][]>();
    const [filename, setFilename] = React.useState<string>('');
    const [hasFirstRowHeader, setHasFirstRowHeader] = React.useState(true);
    const [separator, setSeparator] = React.useState(';');
    const [mandatorId, setMandatorId] = React.useState(0);
    const [yearSel, setYearSel] = React.useState<number>();
    const [fieldsAssociation, setFieldsAssociation] = React.useState<string[]>([]);

    const columns = useGridColumnBuilder({ arrayData: previewData, hasFirstRowHeader });
    const rows = useGridRowBuilder({ arrayData: previewData, hasFirstRowHeader });

    const getDbFieldName = (fileFieldIndex: number): string => {
        if (fieldsAssociation) {
            return fieldsAssociation[fileFieldIndex];
        }

        return '';
    };

    if (isImportLoading) {
        return <Loader show={true} />;
    }

    const handleUpload = (data: ResponseType) => {
        setPreviewData(data.previewRows);
        setFilename(data.filename);
    };

    const handleHasRowHeaderChange = (hasHeader: boolean) => {
        setHasFirstRowHeader(hasHeader);
    };

    const handleColumnDropped = ({ dragResult, dropResult }) => {
        fieldsAssociation[dropResult.id] = dragResult.name;
        setFieldsAssociation([...fieldsAssociation]);
    };

    const handleImport = () => {
        mutate(
            {
                filename,
                skipFirstLine: hasFirstRowHeader,
                separator,
                mandatorId,
                yearSel,
                url: importPath,
                fields: fieldsAssociation
            },
            {
                onSuccess: async (res) => {
                    setPreviewData(null);
                    setFilename(null);
                    setFieldsAssociation([]);

                    setNotification({
                        open: true,
                        severity: res.data.updated?.length || res.data.inserted?.length ? 'success' : 'warning',
                        message: t('IMPORT_FILES.IMPORTED_ROWS', {
                            numUpdated: res.data.updated?.length || 0,
                            numInserted: res.data.inserted?.length || 0
                        })
                    });
                },
                onError: (err) => {
                    setNotification({ open: true, severity: 'error', message: t('IMPORT_FILES.IMPORT_ERROR') });
                }
            }
        );
    };

    return (
        <Container maxWidth={false}>
            <Box id="file-bar" width="100%" display="flex">
                <Box width={['100%', '75%', '50%']}>
                    <FileUploader
                        hasFirstRowHeader={hasFirstRowHeader}
                        separator={separator}
                        mandatorId={mandatorId}
                        yearSel={yearSel}
                        showDelButton={showDelButton}
                        onUpload={handleUpload}
                        onHasRowHeaderChange={handleHasRowHeaderChange}
                        setHasFirstRowHeader={setHasFirstRowHeader}
                        setSeparator={setSeparator}
                        setMandatorId={setMandatorId}
                        setYearSel={setYearSel}
                    />
                </Box>
            </Box>

            <Divider />

            <h3>{t('IMPORT_FILES.FIELDS_AVAILABLE')}</h3>
            <Box my={2} id="fields" display="flex">
                {fieldsData?.map((column, index) => {
                    return (
                        <DragColumn
                            key={index}
                            id={index}
                            name={column}
                            label={fieldsDataTrans[index]}
                            type="DragColumn"
                            isMandatory={false}
                            onDrop={handleColumnDropped}
                        />
                    );
                })}
            </Box>
            {previewData && (
                <>
                    <Divider />
                    <Box mt={2} mb="1px" display="flex">
                        {previewData[0].map((cell, index) => {
                            let dbFieldName = getDbFieldName(index);

                            if (dbFieldName) {
                                const dbFieldNameIndex = fieldsData.indexOf(dbFieldName);
                                dbFieldName = fieldsDataTrans[dbFieldNameIndex];
                            }

                            const colName = hasFirstRowHeader ? cell : t('IMPORT_FILES.COLUMN') + (index + 1);

                            return (
                                <Dustbin
                                    key={index}
                                    id={index}
                                    name={colName}
                                    dbFieldName={dbFieldName}
                                    accept="DragColumn"
                                />
                            );
                        })}
                    </Box>

                    <Box width="100%" height="400px">
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            disableColumnMenu={true}
                            disableColumnReorder={true}
                            hideFooter={true}
                        />
                    </Box>

                    <Divider />

                    <Box mt={2} mb={4} textAlign="center">
                        <Button color="primary" variant="contained" size="large" onClick={handleImport}>
                            Import
                        </Button>
                    </Box>
                </>
            )}
        </Container>
    );
};
