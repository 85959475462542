import { ChangeEvent, useState } from 'react';
import { useSalesListByPastYears, useSalesListByYear } from '../api/useSalesList';
import { Box, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/loading/loading.component';
import { AdesSalesFullListComponent } from '../../customer/components/customerAdesSalesList.component';

export const AdesAllSalesListContainer = () => {
    const curYear = new Date().getFullYear();

    const [year, setYear] = useState(curYear);

    const { t } = useTranslation();

    const { data, isLoading: isDataLoading, error, isError } = useSalesListByYear(year);

    /** Used for calculating past qty and selling and pass them into data (useSalesListByCustomerYear)  */
    const {
        data: adesQtyPastYearListData,
        isLoading: isAdesQtyPastYearListLoading,
        error: adesQtyPastYearListError,
        isError: isAdesQtyPastYearListError
    } = useSalesListByPastYears(year);

    if (isDataLoading || isAdesQtyPastYearListLoading) {
        return <Loading />;
    }

    if (isError) {
        console.error(error);
        return <></>;
    }
    if (isAdesQtyPastYearListError) {
        console.error(adesQtyPastYearListError);
        return <></>;
    }

    const yearList = [year - 3, year - 2, year - 1, year];

    const handleYearChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newYear = parseInt(e.target.value);

        if (newYear > curYear) {
            setYear(curYear);
        } else {
            setYear(newYear);
        }
    };

    data.forEach((item) => {
        /** tot row */
        item['tot_sell'] = (Number(item.quantity) * Number(item.sell_price)).toFixed(5);

        /** qty past years */
        const qtyPastYearList = adesQtyPastYearListData.filter(
            (qtyPastYear) => qtyPastYear.product_id === item.product_id && qtyPastYear.customer_id === item.customer_id
        );

        if (qtyPastYearList?.length) {
            qtyPastYearList.forEach((qtyPastYear) => {
                item[`qty_${qtyPastYear.year_ref}`] = qtyPastYear.tot_quantity;
                item[`tot_${qtyPastYear.year_ref}`] = (qtyPastYear.tot_quantity * qtyPastYear.sell_price).toFixed(5);
            });
        }
    });

    return (
        <>
            <Box display="flex" alignItems="center" bgcolor={'#fff'} p={1} borderRadius="4px">
                <TextField
                    type="number"
                    id="yearRef"
                    variant="outlined"
                    label={t('ADES_SALES.FIELDS.year_ref')}
                    value={year}
                    inputProps={{ maxLength: 4, style: { textAlign: 'center' } }}
                    onChange={handleYearChange}
                />
            </Box>
            <AdesSalesFullListComponent data={data} yearList={yearList} yearSelected={year} showCustomer />
        </>
    );
};
