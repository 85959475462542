export const fixDecimalNumber = (numberTxt: string): number => {
  if (!numberTxt) {
    return 0;
  }
  return Number(numberTxt.replace(',', '.'));
};

export const currency = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
  // minimumSignificantDigits: 2,
  // maximumSignificantDigits: 5
});

export const quantity = new Intl.NumberFormat('it-IT', {
  style: 'decimal',
  maximumFractionDigits: 5,
  maximumSignificantDigits: 5
});
