import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Card, CardContent, Typography } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { BackToList } from '../../../components/back-to-list/back-to-list';
import { IMandator } from '../interfaces/mandator';
import { ROUTE_PATHS } from '../../../config/routes';

type Props = {
    mandatorData: IMandator;
    onSave: any;
};

export const MandatorDetailComponent = ({ mandatorData, onSave }: Props) => {
    const { t } = useTranslation();

    const onSubmit = (values: IMandator) => {
        onSave(values);
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <BackToList path={ROUTE_PATHS.MANDATORS} />
                <Typography variant="h4">{mandatorData?.company}</Typography>
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors: { [key: string]: string } = {};

                        if (!values.company) {
                            errors.mandator_mandator_code = t('COMMON.FIELD_REQUIRED');
                        }

                        return errors;
                    }}
                    initialValues={mandatorData}
                    render={({ handleSubmit, hasValidationErrors }) => (
                        <form onSubmit={handleSubmit}>
                            {mandatorData.id && <Box my={3}>ID: {mandatorData.id}</Box>}

                            <Box my={1}>
                                <Field name="company">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('MANDATORS.FIELDS.company')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('MANDATORS.FIELDS.company')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="email_default">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('MANDATORS.FIELDS.email_default')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('MANDATORS.FIELDS.email_default')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="phone_default">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('MANDATORS.FIELDS.phone_default')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('MANDATORS.FIELDS.phone_default')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="www">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('MANDATORS.FIELDS.www')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('MANDATORS.FIELDS.www')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="vat_code">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('MANDATORS.FIELDS.vat_code')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('MANDATORS.FIELDS.vat_code')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="city">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('MANDATORS.FIELDS.city')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('MANDATORS.FIELDS.city')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="province">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('MANDATORS.FIELDS.province')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('MANDATORS.FIELDS.province')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="nationality">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('MANDATORS.FIELDS.nationality')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('MANDATORS.FIELDS.nationality')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>

                            <Box mt={3} textAlign="right">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={hasValidationErrors}>
                                    {t('COMMON.SAVE')}
                                </Button>
                            </Box>
                        </form>
                    )}
                />
            </CardContent>
        </Card>
    );
};
