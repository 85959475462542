import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';

export const useSalesColumn = () => {
    return useQuery<string[], AxiosError>(QUERY_KEYS.SALES_IMPORT_COLUMNS, () =>
        axios.get<string[]>('ades-sales/import/column/list').then((response) => response.data)
    );
};
