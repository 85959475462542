import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import EmojiPeopleOutlinedIcon from '@material-ui/icons/EmojiPeopleOutlined';
import DirectionsRunOutlinedIcon from '@material-ui/icons/DirectionsRunOutlined';
import MuseumOutlinedIcon from '@material-ui/icons/MuseumOutlined';
// import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ListIcon from '@material-ui/icons/List';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { LogoComponent } from '../logo/logo.component';
import { SideMenuItem } from './sideMenuItem.component';

import { ROUTE_PATHS } from '../../config/routes';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Box, Button, SvgIconTypeMap } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        flagDisabled: {
            filter: 'grayscale(50%)'
        }
    })
);

export interface RouteConfig {
    title: string;
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    url: string;
    subItems?: RouteConfig[];
}

type Props = {
    activeLanguage: string;
    onChangeLanguage: Function;
};

export function SideMenu({ activeLanguage, onChangeLanguage }: Props) {
    const classes = useStyles();

    const menuItemsFirst: RouteConfig[] = [
        {
            title: 'SIDE_MENU.DASHBOARD',
            Icon: DashboardOutlinedIcon,
            url: ROUTE_PATHS.DASHBOARD
        },
        {
            title: 'SIDE_MENU.CUSTOMERS',
            Icon: EmojiPeopleOutlinedIcon,
            url: ROUTE_PATHS.CUSTOMERS
        },
        {
            title: 'SIDE_MENU.CUSTOMER_EMPLOYEES',
            Icon: DirectionsRunOutlinedIcon,
            url: ROUTE_PATHS.EMPLOYEES
        },
        // {
        //     title: 'SIDE_MENU.VENDORS',
        //     Icon: RecordVoiceOverOutlinedIcon,
        //     url: ROUTE_PATHS.VENDORS
        // },
        {
            title: 'SIDE_MENU.ACTIVITIES',
            Icon: PhoneInTalkOutlinedIcon,
            url: ROUTE_PATHS.ACTIVITIES
        },
        {
            title: 'SIDE_MENU.PRODUCTS',
            Icon: StorefrontOutlinedIcon,
            url: ROUTE_PATHS.PRODUCTS
        },
        {
            title: 'SIDE_MENU.SALES',
            Icon: TrendingUpOutlinedIcon,
            url: ROUTE_PATHS.SALES
        },
        {
            title: 'SIDE_MENU.ALL_SALES',
            Icon: ShoppingBasket,
            url: ROUTE_PATHS.ALL_SALES
        }
    ];

    const menuItemsSecond: RouteConfig[] = [
        {
            title: 'SIDE_MENU.SETTINGS',
            Icon: SettingsOutlinedIcon,
            url: '',
            subItems: [
                {
                    title: 'SIDE_MENU.MANDATORS',
                    Icon: MuseumOutlinedIcon,
                    url: ROUTE_PATHS.MANDATORS
                },
                {
                    title: 'SIDE_MENU.USERS',
                    Icon: AccountCircleOutlinedIcon,
                    url: ROUTE_PATHS.USERS
                },
                {
                    title: 'SIDE_MENU.CUSTOMER_CATEGORIES',
                    Icon: AssignmentOutlinedIcon,
                    url: ROUTE_PATHS.CUSTOMER_CATEGORY_TYPES
                }
            ]
        },
        {
            title: 'SIDE_MENU.IMPORTS',
            Icon: ImportExportOutlinedIcon,
            url: ROUTE_PATHS.IMPORT_FILES,
            subItems: [
                {
                    title: 'SIDE_MENU.IMPORT_CUSTOMERS',
                    Icon: EmojiPeopleOutlinedIcon,
                    url: ROUTE_PATHS.CUSTOMERS
                },
                {
                    title: 'SIDE_MENU.IMPORT_PRODUCTS',
                    Icon: StorefrontOutlinedIcon,
                    url: ROUTE_PATHS.PRODUCTS
                },
                {
                    title: 'SIDE_MENU.IMPORT_SALES',
                    Icon: TrendingUpOutlinedIcon,
                    url: ROUTE_PATHS.SALES
                },
                {
                    title: 'SIDE_MENU.IMPORT_ADES',
                    Icon: ListIcon,
                    url: ROUTE_PATHS.IMPORT_ADES
                }
            ]
        }
    ];

    return (
        <div>
            <Box display="flex" className={classes.toolbar}>
                <LogoComponent />
            </Box>
            <Box display="flex" justifyContent="center">
                <Button disabled={activeLanguage === 'it'} size="small" onClick={() => onChangeLanguage('it')}>
                    <img
                        src="/images/it.png"
                        alt=""
                        width="24"
                        className={activeLanguage === 'it' ? classes.flagDisabled : null}
                    />
                </Button>
                <Button disabled={activeLanguage === 'en'} size="small" onClick={() => onChangeLanguage('en')}>
                    <img
                        src="/images/en.png"
                        alt=""
                        width="24"
                        className={activeLanguage === 'en' ? classes.flagDisabled : null}
                    />
                </Button>
            </Box>
            <Divider />
            <List>
                {menuItemsFirst.map((item, index) => {
                    return <SideMenuItem key={item.title} item={item} />;
                })}
            </List>
            <Divider />
            <List>
                {menuItemsSecond.map((item, index) => {
                    return <SideMenuItem key={item.title} item={item} />;
                })}
            </List>
        </div>
    );
}
