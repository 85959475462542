import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { IEmployee } from '../interfaces/employee';

export const useEmployeeDetail = (employeeId: string) => {
    return useQuery<IEmployee, AxiosError>([QUERY_KEYS.EMPLOYEE_DETAIL, employeeId], () =>
        parseInt(employeeId) > 0
            ? axios.get<IEmployee>(`employee/${employeeId}`).then((response) => response.data)
            : null
    );
};
