import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import { Card, CardContent, IconButton } from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { makeStyles } from '@material-ui/core/styles';
import { IUser } from '../interfaces/user';
import { ROUTE_PATHS } from '../../../config/routes';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';

const useStyles = makeStyles((theme) => ({
    iconActive: {
        color: theme.palette.success.dark
    },
    iconClosed: {
        color: theme.palette.error.main
    }
}));

type Props = {
    data: IUser[];
    onDelete: Function;
};

export const UserListComponent = ({ data, onDelete }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'userList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        history.push(`${ROUTE_PATHS.USERS}/${params.row.id}`);
    };

    const handleAddNewClick = () => {
        history.push(`${ROUTE_PATHS.USERS}/new`);
    };

    const handleDelete = (row: any) => {
        if (window.confirm(t('USERS.CONF_DELETE'))) {
            onDelete(row);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'firstname',
            headerName: t(`USERS.FIELDS.firstname`),
            width: getColumnsWidth('firstname', 200, columnWidth)
        },
        {
            field: 'lastname',
            headerName: t(`USERS.FIELDS.lastname`),
            width: getColumnsWidth('lastname', 200, columnWidth)
        },
        {
            field: 'address',
            headerName: t(`USERS.FIELDS.address`),
            width: getColumnsWidth('address', 200, columnWidth)
        },
        { field: 'city', headerName: t(`USERS.FIELDS.city`), width: getColumnsWidth('city', 200, columnWidth) },
        {
            field: 'province',
            headerName: t(`USERS.FIELDS.province`),
            width: getColumnsWidth('province', 200, columnWidth)
        },
        {
            field: 'phone',
            headerName: t(`USERS.FIELDS.phone`),
            width: getColumnsWidth('phone', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('phone', true, columnVisibility)
        },
        {
            field: 'mobile',
            headerName: t(`USERS.FIELDS.mobile`),
            width: getColumnsWidth('mobile', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('mobile', true, columnVisibility)
        },
        {
            field: 'email',
            headerName: t(`USERS.FIELDS.email`),
            width: getColumnsWidth('email', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('email', true, columnVisibility)
        },
        {
            field: 'email_private',
            headerName: t(`USERS.FIELDS.email_private`),
            width: getColumnsWidth('email_private', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('email_private', true, columnVisibility)
        },
        {
            field: 'cf',
            headerName: 'CF',
            width: getColumnsWidth('cf', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('cf', true, columnVisibility)
        },
        {
            field: 'iban',
            headerName: 'IBAN',
            width: getColumnsWidth('iban', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('iban', true, columnVisibility)
        },
        {
            field: 'annotation',
            headerName: t(`USERS.FIELDS.annotation`),
            width: getColumnsWidth('annotation', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('annotation', true, columnVisibility)
        },
        {
            field: 'is_active',
            headerName: t(`USERS.FIELDS.is_active`),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('is_active', true, columnVisibility),
            width: getColumnsWidth('is_active', 150, columnWidth),
            type: 'singleSelect',
            valueOptions: [
                { value: '0', label: 'No' },
                { value: '1', label: 'Sì' }
            ],
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => {
                if (parseInt(params.row.is_active) === 1) {
                    return <CheckCircleOutlinedIcon className={classes.iconActive} />;
                }
                return <CancelOutlinedIcon className={classes.iconClosed} />;
            }
        },
        {
            field: 'delete',
            headerName: t(`COMMON.DELETE`),
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            width: getColumnsWidth('delete', 130, columnWidth),
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => (
                <IconButton
                    color="secondary"
                    onClick={() => {
                        handleDelete(params.row);
                    }}>
                    <DeleteForeverIcon />
                </IconButton>
            )
        },
        {
            field: 'id',
            headerName: t('USERS.FIELDS.id'),
            width: getColumnsWidth('id', 60, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('id', false, columnVisibility)
        }
    ];

    return (
        <Card variant="outlined">
            <CardContent>
                <DataGridDefault
                    id={listId}
                    columns={columns}
                    data={data}
                    handleAddNewClick={handleAddNewClick}
                    handleRowDoubleClick={handleRowDoubleClick}
                    setColumnVisibility={setColumnVisibility}
                    setColumnWidth={setColumnWidth}
                />
            </CardContent>
        </Card>
    );
};
