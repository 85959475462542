import React from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    TextField,
    Select,
    MenuItem,
    IconButton,
    FormControl,
    InputLabel
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ICustomerMandatorList } from '../interfaces/customerMandatorList';
import { IMandator } from '../../mandator/interfaces/mandator';
import { ICustomerMandator } from '../interfaces/customerMandator';
import { Field, Form } from 'react-final-form';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';

type Props = {
    data: ICustomerMandatorList[];
    mandatorList: IMandator[];
    onSave: Function;
    onDelete: Function;
};

export const CustomerMandatorListComponent = ({ data, mandatorList, onSave, onDelete }: Props) => {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [customerMandatorData, setCustomerMandatorData] = React.useState<ICustomerMandator>();
    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'customerMandatorList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const handleDelete = (row: any) => {
        if (window.confirm(t('CUSTOMER_MANDATORS.CONF_DELETE', { code: row.mandator_code }))) {
            onDelete(row);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'mandator',
            headerName: t('MANDATORS.FIELDS.mandator'),
            flex: 1,
            hide: !isColumnsVisibile('mandator', true, columnVisibility)
        },
        {
            field: 'mandator_code',
            headerName: t(`MANDATORS.FIELDS.mandator_code`),
            width: getColumnsWidth('mandator_code', 300, columnWidth),
            hide: !isColumnsVisibile('mandator_code', true, columnVisibility)
        },
        {
            field: 'delete',
            headerName: t(`COMMON.DELETE`),
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            width: getColumnsWidth('delete', 150, columnWidth),
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => (
                <IconButton color="secondary" onClick={() => handleDelete(params.row)}>
                    <DeleteForeverIcon />
                </IconButton>
            )
        }
    ];

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        const { row } = params;

        setCustomerMandatorData({
            id: row.id,
            customer_id: row.customer_id,
            mandator_id: row.mandator_id,
            code: row.mandator_code
        });

        setOpen(true);
    };

    const handleAddNewClick = () => {
        setCustomerMandatorData({
            id: null,
            customer_id: null,
            mandator_id: null,
            code: ''
        });
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const onSubmit = (values: ICustomerMandator) => {
        onSave(values);
        setOpen(false);
    };

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <DataGridDefault
                        id={listId}
                        columns={columns}
                        data={data}
                        height="40vh"
                        showFooter={false}
                        handleAddNewClick={handleAddNewClick}
                        handleRowDoubleClick={handleRowDoubleClick}
                        setColumnVisibility={setColumnVisibility}
                        setColumnWidth={setColumnWidth}
                    />
                </CardContent>
            </Card>
            <Dialog onClose={handleCancel} open={open}>
                <DialogContent dividers>
                    <Form
                        onSubmit={onSubmit}
                        validate={(values) => {
                            const errors: { [key: string]: string } = {};

                            if (!values.mandator_id) {
                                errors.mandator_id = t('COMMON.FIELD_REQUIRED');
                            }

                            return errors;
                        }}
                        initialValues={customerMandatorData}
                        render={({ handleSubmit, hasValidationErrors }) => (
                            <form onSubmit={handleSubmit}>
                                <Box my={1}>
                                    <Field name="code">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMERS.FIELDS.code')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMERS.FIELDS.code')}
                                            />
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field name="mandator_id">
                                        {({ input, meta }) => (
                                            <FormControl fullWidth>
                                                <InputLabel>{t('MANDATORS.FIELDS.mandator')}</InputLabel>
                                                <Select {...input} error={meta.error && meta.touched}>
                                                    {mandatorList.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.company}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>

                                <Box mt={3} textAlign="right">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={hasValidationErrors}>
                                        {t('COMMON.SAVE')}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};
