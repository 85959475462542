import { useAddressListByCustomer } from '../api/useCustomerAddressList';
import { useMutateCustomerAddress } from '../api/useMutateCustomerAddress';
import { CustomerAddressListComponent } from './customerAddressList.component';
import { ICustomerAddress } from '../interfaces/customerAddress';
import Loading from '../../../components/loading/loading.component';

type Props = {
    customerId: string;
};

export const CustomerAddressListContainer = ({ customerId }: Props) => {
    const {
        update: updateCustomerAddressMutation,
        create: createCustomerAddressMutation,
        remove: removeCustomerAddressMutation
    } = useMutateCustomerAddress(customerId);
    const { mutateAsync: updateCustomerAddress } = updateCustomerAddressMutation;
    const { mutateAsync: createCustomerAddress } = createCustomerAddressMutation;
    const { mutateAsync: removeCustomerAddress } = removeCustomerAddressMutation;

    const {
        data: customerAddressListData,
        isLoading: isCustomerAddressListLoading,
        error: customerAddressListError,
        isError: isCustomerAddressListError,
        refetch: refecthCustomerAddressList
    } = useAddressListByCustomer(customerId);

    if (isCustomerAddressListLoading) {
        return <Loading />;
    }

    if (isCustomerAddressListError) {
        console.error(customerAddressListError);
        return <></>;
    }

    const handleUpdateCustomerAddress = (data: ICustomerAddress) => {
        data.customer_id = parseInt(customerId);
        updateCustomerAddress(data).then(() => {
            refecthCustomerAddressList();
        });
    };
    const handleCreateCustomerAddress = (data: ICustomerAddress) => {
        data.customer_id = parseInt(customerId);
        createCustomerAddress(data).then(() => {
            refecthCustomerAddressList();
        });
    };
    const handleRemoveCustomerAddress = (data: ICustomerAddress) => {
        removeCustomerAddress(data).then(() => {
            refecthCustomerAddressList();
        });
    };

    const handleSaveCustomerAddress = (data: ICustomerAddress) =>
        data.id > 0 ? handleUpdateCustomerAddress(data) : handleCreateCustomerAddress(data);

    return (
        <CustomerAddressListComponent
            data={customerAddressListData}
            onSave={handleSaveCustomerAddress}
            onDelete={handleRemoveCustomerAddress}
        />
    );
};
