import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    TextField,
    Card,
    CardContent,
    Typography,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { BackToList } from '../../../components/back-to-list/back-to-list';
import { IProduct } from '../interfaces/product';
import { IMandator } from '../../mandator/interfaces/mandator';
import { ROUTE_PATHS } from '../../../config/routes';

type Props = {
    productData: IProduct;
    mandatorList: IMandator[];
    onSave: any;
};

export const ProductDetailComponent = ({ productData, mandatorList, onSave }: Props) => {
    const { t } = useTranslation();

    const onSubmit = (values: IProduct) => {
        onSave(values);
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <BackToList path={ROUTE_PATHS.PRODUCTS} />
                <Typography variant="h4">{productData?.product_name}</Typography>
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors: { [key: string]: string } = {};

                        if (!values.mandator_id) {
                            errors.mandator_id = t('COMMON.FIELD_REQUIRED');
                        }
                        if (!values.mandator_product_code) {
                            errors.mandator_product_code = t('COMMON.FIELD_REQUIRED');
                        }
                        if (!values.product_name) {
                            errors.product_name = t('COMMON.FIELD_REQUIRED');
                        }
                        if (!values.price_default) {
                            errors.price_default = t('COMMON.FIELD_REQUIRED');
                        }

                        return errors;
                    }}
                    initialValues={productData}
                    render={({ handleSubmit, hasValidationErrors }) => (
                        <form onSubmit={handleSubmit}>
                            {productData.id && <Box my={3}>ID: {productData.id}</Box>}
                            <Box my={1}>
                                <Field name="mandator_id" parse={(value, name) => parseInt(value)}>
                                    {({ input, meta }) => (
                                        <>
                                            <InputLabel>{t('PRODUCTS.FIELDS.mandator')}</InputLabel>
                                            <Select {...input} label={t('PRODUCTS.FIELDS.mandator')} fullWidth required>
                                                {mandatorList.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.company}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>
                                                {meta.error && meta.touched ? meta.error : ''}
                                            </FormHelperText>
                                        </>
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="code">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.code')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.code')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="mandator_product_code">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.mandator_product_code')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.mandator_product_code')}
                                            fullWidth
                                            required
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="manufacturer_product_code">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.manufacturer_product_code')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.manufacturer_product_code')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="product_name">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.product_name')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.product_name')}
                                            fullWidth
                                            required
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="product_description">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            multiline
                                            rows={6}
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.product_description')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.product_description')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="stock">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="number"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.stock')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.stock')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="available">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="number"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.available')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.available')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="ordered">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="number"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.ordered')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.ordered')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="committed">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.committed')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.committed')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="price_default">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.price_default')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.price_default')}
                                            fullWidth
                                            required
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="purchase_price">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.purchase_price')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.purchase_price')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="special_price">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.special_price')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.special_price')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="last_purchase_price">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.last_purchase_price')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.last_purchase_price')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="unit_of_measure">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('PRODUCTS.FIELDS.unit_of_measure')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('PRODUCTS.FIELDS.unit_of_measure')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>

                            <Box mt={3} textAlign="right">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={hasValidationErrors}>
                                    {t('COMMON.SAVE')}
                                </Button>
                            </Box>
                        </form>
                    )}
                />
            </CardContent>
        </Card>
    );
};
