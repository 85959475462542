import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ICustomerAddress } from '../interfaces/customerAddress';

export const useAddressListByCustomer = (customerId: string) => {
    return useQuery<ICustomerAddress[], AxiosError>([QUERY_KEYS.CUSTOMER_ADDRESS_LIST, customerId], () =>
        parseInt(customerId) > 0
            ? axios.get<ICustomerAddress[]>(`customer-address/customer/${customerId}`).then((response) => response.data)
            : []
    );
};

export const useCustomerAddressList = () => {
    return useQuery<ICustomerAddress[], AxiosError>(QUERY_KEYS.CUSTOMER_ADDRESS_LIST, () =>
        axios.get<ICustomerAddress[]>(`customer-address`).then((response) => response.data)
    );
};
