import React from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    TextField,
    IconButton,
    Select,
    FormControl,
    InputLabel,
    MenuItem
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { CONTACT_TYPE } from '../../../config/constants';
import { ICustomerContact } from '../interfaces/customerContact';
import { Field, Form } from 'react-final-form';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';

const CONTACT_TYPES = Object.keys(CONTACT_TYPE);

type Props = {
    data: ICustomerContact[];
    onSave: Function;
    onDelete: Function;
};

export const CustomerContactListComponent = ({ data, onSave, onDelete }: Props) => {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [contactData, setContactData] = React.useState<ICustomerContact>();
    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'customerContactList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const handleDelete = (row: any) => {
        if (window.confirm(t('CUSTOMER_CONTACTS.CONF_DELETE'))) {
            onDelete(row);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'contact_type',
            headerName: t(`CUSTOMER_CONTACTS.FIELDS.contact_type`),
            width: getColumnsWidth('contact_type', 150, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('contact_type', true, columnVisibility),
            renderCell: (params: GridCellParams) => {
                return t(`CONTACT_TYPES.${params.row.contact_type}`);
            }
        },
        {
            field: 'contact_type_desc',
            headerName: t(`CUSTOMER_CONTACTS.FIELDS.contact_type_desc`),
            width: getColumnsWidth('contact_type_desc', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('contact_type_desc', true, columnVisibility)
        },
        {
            field: 'contact_value',
            headerName: t(`CUSTOMER_CONTACTS.FIELDS.contact_value`),
            width: getColumnsWidth('contact_value', 300, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('contact_value', true, columnVisibility)
        },
        {
            field: 'id',
            headerName: t('CUSTOMER_CONTACTS.FIELDS.id'),
            width: getColumnsWidth('id', 60, columnWidth),
            disableColumnMenu: true,
            sortable: false,
            hide: !isColumnsVisibile('id', false, columnVisibility)
        },
        {
            field: 'delete',
            headerName: t(`COMMON.DELETE`),
            width: getColumnsWidth('delete', 150, columnWidth),
            align: 'center',
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridCellParams) => (
                <IconButton color="secondary" onClick={() => handleDelete(params.row)}>
                    <DeleteForeverIcon />
                </IconButton>
            )
        }
    ];

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        const { row } = params;

        setContactData(row as ICustomerContact);

        setOpen(true);
    };

    const handleAddNewClick = () => {
        setContactData({} as ICustomerContact);
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const onSubmit = (values: ICustomerContact) => {
        onSave(values);
        setOpen(false);
    };

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <DataGridDefault
                        id={listId}
                        columns={columns}
                        data={data}
                        height="40vh"
                        showFooter={false}
                        handleAddNewClick={handleAddNewClick}
                        handleRowDoubleClick={handleRowDoubleClick}
                        setColumnVisibility={setColumnVisibility}
                        setColumnWidth={setColumnWidth}
                    />
                </CardContent>
            </Card>
            <Dialog fullWidth onClose={handleCancel} open={open}>
                <DialogContent dividers>
                    <Form
                        onSubmit={onSubmit}
                        validate={(values) => {
                            const errors: { [key: string]: string } = {};

                            if (!values.contact_type) {
                                errors.contact_type = t('COMMON.FIELD_REQUIRED');
                            }
                            if (!values.contact_value) {
                                errors.contact_value = t('COMMON.FIELD_REQUIRED');
                            }

                            return errors;
                        }}
                        initialValues={contactData}
                        render={({ handleSubmit, hasValidationErrors }) => (
                            <form onSubmit={handleSubmit}>
                                {contactData.id && <Box my={3}>ID: {contactData.id}</Box>}
                                <Box my={1}>
                                    <Field name="contact_type">
                                        {({ input, meta }) => (
                                            <FormControl fullWidth>
                                                <InputLabel>{t('CUSTOMER_CONTACTS.FIELDS.contact_type')}</InputLabel>
                                                <Select {...input} error={meta.error && meta.touched}>
                                                    {CONTACT_TYPES.map((item) => (
                                                        <MenuItem key={item} value={item}>
                                                            {t(`CONTACT_TYPES.${item}`)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field name="contact_type_desc">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMER_CONTACTS.FIELDS.contact_type_desc')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMER_CONTACTS.FIELDS.contact_type_desc')}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field
                                        name="contact_value"
                                        placeholder={t('CUSTOMER_CONTACTS.FIELDS.contact_value')}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMER_CONTACTS.FIELDS.contact_value')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMER_CONTACTS.FIELDS.contact_value')}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </Box>

                                <Box mt={3} textAlign="right">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={hasValidationErrors}>
                                        {t('COMMON.SAVE')}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};
