import React from 'react';
import { useUserList } from '../api/useUserList';
import { useMutateUser } from '../api/useMutateUser';
import Loading from '../../../components/loading/loading.component';
import { UserListComponent } from './userList.component';
import { IUser } from '../interfaces/user';

export const UserListContainer = () => {
    const { remove: removeUserMutation } = useMutateUser();
    const { mutateAsync: removeUser } = removeUserMutation;

    const {
        data: userListData,
        isLoading: isUserListLoading,
        error: userListError,
        isError: isUserListError,
        refetch: refecthUserList
    } = useUserList();

    const handleRemoveUser = (data: IUser) => {
        removeUser(data).then(() => {
            refecthUserList();
        });
    };

    // const handleRemoveUser = React.useCallback(
    //     (data: IUser) => () => {
    //         removeUser(data).then(() => {
    //             refecthUserList();
    //         });
    //     },
    //     [removeUser, refecthUserList]
    // );

    if (isUserListLoading) {
        return <Loading />;
    }

    if (isUserListError) {
        console.error(userListError);
        return <></>;
    }

    return <UserListComponent data={userListData} onDelete={handleRemoveUser} />;
};
