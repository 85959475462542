import React from 'react';
import { Box } from '@material-ui/core';
import { CustomerCategoryTypeListContainer } from './components/customerCategoryTypeList.container';

export const CustomerCategoryTypeListPage = () => {
    return (
        <Box flexGrow="1" id="container">
            <CustomerCategoryTypeListContainer />
        </Box>
    );
};
