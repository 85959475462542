import { Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AdesSaleListContainer } from './components/adesSaleList.container';
import { ProductDetailContainer } from './components/productDetail.container';

type UrlParams = {
    id: string;
};

export const ProductDetailPage = () => {
    const { id } = useParams<UrlParams>();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <ProductDetailContainer productId={id} />
            </Grid>
            <Grid item xs={12} md={6}>
                {id && id !== 'new' && <AdesSaleListContainer productId={id} />}
            </Grid>
        </Grid>
    );
};
