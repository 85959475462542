import { ColumnWidth } from '../recoil/listParams.atom';

export const useColumnWidth = () => {
    const getColumnsWidth = (field: string, widthDefault: number, columnWidth: ColumnWidth[]): number => {
        const col = columnWidth && Array.isArray(columnWidth) ? columnWidth?.find((column) => column?.field === field) : null;

        if (col) {
            return col.width;
        }

        return widthDefault;
    };

    return getColumnsWidth;
};
