import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { IUser } from '../interfaces/user';

export const useUserList = () => {
    return useQuery<IUser[], AxiosError>(QUERY_KEYS.USER_LIST, () =>
        axios.get<IUser[]>('user').then((response) => response.data)
    );
};
