import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { currency, quantity } from '../../helpers';

type Props = {
    data: any[];
    headerKey: string;
    rowKey: string;
    sumKey: string;
    isCurrency?: boolean;
};

export const Pivot = ({ data, headerKey, rowKey, sumKey, isCurrency }: Props) => {
    interface Cell {
        headerKey: string;
        value: number;
    }

    interface Row {
        key: string;
        values: Cell[];
    }

    let headerRows: string[] = [];
    let rows: Row[] = [];

    data.forEach((item) => {
        if (!headerRows.includes(item[headerKey])) {
            headerRows.push(item[headerKey]);
        }

        const curRow = rows.find((row) => row.key === item[rowKey]);

        if (curRow) {
            const curValue = curRow.values.find((valuesItem) => valuesItem.headerKey === item[headerKey]);

            if (curValue) {
                curValue.value += Number(item[sumKey]);
            } else {
                curRow.values.push({ headerKey: item[headerKey], value: Number(item[sumKey]) });
            }
        } else {
            rows.push({ key: item[rowKey], values: [{ headerKey: item[headerKey], value: item[sumKey] }] });
        }
    });

    

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="Tot. by year/mandator">
                <TableHead>
                    <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        {headerRows.slice(0, 4).map((header) => (
                            <TableCell key={header} component="th" align="right">
                                <b>{header}</b>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.key}>
                            <TableCell component="th" scope="row" align="right">
                                <b>{row.key}</b>
                            </TableCell>
                            {row.values.slice(0, 4).map((value, i) => (
                                <TableCell key={`${row.key}}-${i}`} align="right">
                                    {isCurrency ? currency.format(value.value) : quantity.format(value.value)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
