import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { Box, Paper, Button, TextField } from '@material-ui/core';
import { RouterLinkComponent as Link } from '../../../components/link/router-link.component';
import { ROUTE_PATHS } from '../../../config/routes';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: grey[50]
    },
    btFacebook: {
        color: '#ffffff',
        backgroundColor: '#3b5998',
        '&:hover': {
            backgroundColor: '#0069d9'
        }
    },
    btGoogle: {
        color: '#ffffff',
        backgroundColor: '#e05449'
    }
}));

interface Props {
    requestNewPwd: Function;
}

export const LostPwdComponent = ({ requestNewPwd }: Props) => {
    const classes = useStyles();

    const [userEmail, setUserEmail] = useState('');

    const { t } = useTranslation();

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (userEmail) {
            requestNewPwd(userEmail);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;

        setUserEmail(value);
    };

    return (
        <Paper variant="outlined" className={classes.paper}>
            <Box p={3}>
                <form onSubmit={handleSubmit}>
                    <Box my={2}>
                        <TextField
                            name="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={handleChange}
                        />
                    </Box>
                    <Box my={2}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            {t('FORGOT_PWD.LOGIN_EMAIL')}
                        </Button>
                    </Box>
                </form>
                <hr />
                <Box mt={4} display="flex" justifyContent="space-between">
                    <Link to={ROUTE_PATHS.LOGIN}>{t('FORGOT_PWD.BACK_LOGIN')}</Link>
                </Box>
            </Box>
        </Paper>
    );
};
