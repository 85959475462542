import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProductColumn } from '../product/api/useProductColumn';
import { LoadingComponent as Loader } from '../../components/loading/fullscreen_loading.component';
import { ImportFileCSV } from '../../components/file-importer/importFileCSV.component';

export const ImportFileProduct = () => {
    const { t } = useTranslation();
    const { data: fieldsData, isLoading: fieldsAreLoading, error: fieldsError } = useProductColumn();

    if (fieldsAreLoading) {
        return <Loader show={true} />;
    }

    if (fieldsError) {
        console.error({ fieldsError });
    }

    const fieldsDataTrans = fieldsData.map((item) => t(`PRODUCTS.FIELD_ALIAS.${item}`));

    return <ImportFileCSV fieldsData={fieldsData} fieldsDataTrans={fieldsDataTrans} importPath="product/import" />;
};
