import axios from '../../../helpers/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation } from 'react-query';

type ImportParams = {
    url: string;
    filename: string;
    separator: string;
    mandatorId: number;
    yearSel: number;
    skipFirstLine: boolean;
    fields: object;
};

type ImportRes = {
    updated: number[];
    inserted: number[];
};

export const useImportFile = () => {
    return useMutation<AxiosResponse<ImportRes>, AxiosError, ImportParams, unknown>(
        ({ url, filename, separator, mandatorId, yearSel, skipFirstLine, fields }: ImportParams) =>
            axios.post('/' + url, {
                filename,
                separator,
                mandatorId,
                yearSel,
                skipFirstLine,
                fields
            }),
        {
            onError: (e) => {
                console.error('useImportFile', e);
            }
        }
    );
};
