import { Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { MandatorDetailContainer } from './components/mandatorDetail.container';

type UrlParams = {
    id: string;
};

export const MandatorDetailPage = () => {
    const { id } = useParams<UrlParams>();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <MandatorDetailContainer mandatorId={id} />
            </Grid>
        </Grid>
    );
};
