import { Box } from '@material-ui/core';
import { EmployeeListContainer } from './components/employeeList.container';

export const EmployeeListPage = () => {
    return (
        <Box flexGrow="1" id="container">
            <EmployeeListContainer />
        </Box>
    );
};
