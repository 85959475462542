import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ICustomerEmployee } from '../interfaces/customerEmployee';

export const useCustomerEmployeeListByCustomer = (customerId: string) => {
    return useQuery<ICustomerEmployee[], AxiosError>([QUERY_KEYS.CUSTOMER_EMPLOYEE_LIST_BY_CUSTOMER, customerId], () =>
        parseInt(customerId) > 0
            ? axios
                  .get<ICustomerEmployee[]>(`customer-employee/customer/${customerId}`)
                  .then((response) => response.data)
            : []
    );
};
