import React, { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';

import AccountCircle from '@material-ui/icons/AccountCircle';
import { NotificationIconComponent } from './notification-icon.component';

import { IUser } from '../../../pages/user/interfaces/user';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../config/routes';

const useStyles = makeStyles((theme) => ({
    viewInDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    viewInMobile: {
        isplay: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    link: {
        cursor: 'pointer'
    }
}));

interface Props {
    onLogout?: MouseEventHandler;
    currentUser: IUser;
}

export const SecondaryMenuComponent = ({ onLogout, currentUser }: Props) => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const isMenuOpen = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const goToAccount = () => {
        history.push(`${ROUTE_PATHS.USERS}/${currentUser.id}`)
        handleClose();
    };

    const menuId = 'primary-search-account-menu';
    const AccountMenu = () => {
        const { t } = useTranslation();
        return (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleClose}>
                <MenuItem className={classes.viewInMobile}>
                    <NotificationIconComponent BadgeContent={13} />
                </MenuItem>
                <MenuItem onClick={goToAccount}>{t('ACCOUNT_MENU.MY_ACCOUNT')}</MenuItem>
                <MenuItem onClick={onLogout}>{t('ACCOUNT_MENU.LOGOUT')}</MenuItem>
            </Menu>
        );
    };

    return (
        <Box display="flex" alignItems="center">
            {/* <span className={classes.viewInDesktop}>
                <NotificationIconComponent BadgeContent={13} />
            </span> */}

            <IconButton edge="end" onClick={handleOpen} color="inherit">
                <AccountCircle />
            </IconButton>
            <Box component="small" paddingLeft={2} onClick={handleOpen} className={classes.link}>
                {currentUser.fullname}
            </Box>
            <AccountMenu />
        </Box>
    );
};
