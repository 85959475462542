import { ColumnVisibility } from '../recoil/listParams.atom';

export const useColumnVisibility = () => {
    const isColumnsVisibile = (
        field: string,
        visibilityDefault: boolean,
        columnVisibility: ColumnVisibility[]
    ): boolean => {
        const col = columnVisibility && Array.isArray(columnVisibility) ? columnVisibility?.find((column) => column?.field === field) : null;

        if (col) {
            return col.isVisible;
        }

        return visibilityDefault;
    };

    return isColumnsVisibile;
};
