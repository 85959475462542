import { createMuiTheme } from '@material-ui/core/styles';
import { itIT, enUS } from '@material-ui/core/locale';
import { itIT as grid_itIT, enUS as grid_enUS } from '@mui/x-data-grid-pro';

import i18n from 'i18next';

import blue from '@material-ui/core/colors/blue';

const lang = i18n && i18n?.language ? i18n.language.substr(0, 2).toLowerCase() : 'it';

const langDictionary = {
    it: itIT,
    en: enUS
};

const langGridDictionary = {
    it: grid_itIT,
    en: grid_enUS
};

const loc = Object.entries(langDictionary).filter((item) => item[0] === lang)[0];
const gridLoc = Object.entries(langGridDictionary).filter((item) => item[0] === lang)[0];


export const defaultTheme = createMuiTheme(
    {
        typography: {
            fontFamily: 'Open Sans',
            // htmlFontSize: 14,
            // fontSize: 16
        },
        palette: {
            primary: {
                main: blue[500]
            }
        }
    },
    gridLoc[1],
    loc[1]
);
