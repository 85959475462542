import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomerEmployeeListByEmployee } from '../api/useCustomerEmployeeList';
import { useMutateCustomerEmployee } from '../api/useMutateCustomerEmployee';
import { useCustomerList } from '../../customer/api/useCustomerList';
import Loading from '../../../components/loading/loading.component';
import { CustomerEmployeeListComponent } from './customerEmployeeList.component';
import { ICustomerEmployee } from '../interfaces/customerEmployee';

type Props = {
    employeeId: string;
};

export const CustomerEmployeeListContainer = ({ employeeId }: Props) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const {
        data: customerEmployeeListData,
        isLoading: isCustomerEmployeeListLoading,
        error: customerEmployeeListError,
        isError: isCustomerEmployeeListError,
        refetch: refetchEmployeeList
    } = useCustomerEmployeeListByEmployee(employeeId);

    const {
        data: customerListData,
        isLoading: isCustomerListLoading,
        error: customerListError,
        isError: isCustomerListError
    } = useCustomerList();

    const {
        update: updateCustomerEmployeeMutation,
        create: createCustomerEmployeeMutation,
        remove: removeCustomerEmployeeMutation,
        setActiveStatus: changeActiveStatusMutation
    } = useMutateCustomerEmployee(employeeId);

    const { mutateAsync: updateCustomerEmployee } = updateCustomerEmployeeMutation;
    const { mutateAsync: createCustomerEmployee } = createCustomerEmployeeMutation;
    const { mutateAsync: removeCustomerEmployee } = removeCustomerEmployeeMutation;
    const { mutateAsync: changeActiveStatus } = changeActiveStatusMutation;

    if (isCustomerEmployeeListLoading || isCustomerListLoading) {
        return <Loading />;
    }

    if (isCustomerEmployeeListError) {
        console.error(customerEmployeeListError);
        return <></>;
    }

    if (isCustomerListError) {
        console.error(customerListError);
        return <></>;
    }

    customerEmployeeListData.forEach((item) => (item.is_active = Boolean(Number(item.is_active))));

    customerEmployeeListData.forEach((item) => {
        item.from_date = item.from_date ? new Date(item.from_date).toLocaleDateString(lang) : '';
        item.to_date = item.to_date ? new Date(item.to_date).toLocaleDateString(lang) : '';
    });

    const handleSave = (data: ICustomerEmployee) => {
        data.employee_id = parseInt(employeeId);
        const action = data.id > 0 ? updateCustomerEmployee(data) : createCustomerEmployee(data);

        action.then((res) => {
            refetchEmployeeList();
            setDialogOpen(false);
        });
    };

    const handleDelete = (data: ICustomerEmployee) => {
        removeCustomerEmployee(data.id).then(() => refetchEmployeeList());
    };

    const handleActiveStatus = (data: { id: number; is_active: number }) => {
        data.is_active = +data.is_active === 1 ? 0 : 1;

        changeActiveStatus(data);
    };

    return (
        <CustomerEmployeeListComponent
            data={customerEmployeeListData}
            customerList={customerListData}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            onSave={handleSave}
            onDelete={handleDelete}
            onActiveStatus={handleActiveStatus}
        />
    );
};
