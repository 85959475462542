import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'
import { IUser } from '../pages/user/interfaces/user';

const { persistAtom } = recoilPersist()

const initValue: IUser = {
    id: 0,
    email: '',
    fullname: ''
};

export const currentUserState = atom<IUser>({
    key: 'currentUser',
    default: initValue,
    effects_UNSTABLE: [persistAtom]
});
