import React from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    TextField,
    Select,
    IconButton,
    MenuItem,
    FormHelperText,
    Checkbox,
    FormControlLabel,
    FormControl,
    InputLabel
} from '@material-ui/core';
import { Autocomplete } from 'mui-rff';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { makeStyles } from '@material-ui/core/styles';
import { ICustomerActivity } from '../interfaces/customerActivity';
import { Field, Form } from 'react-final-form';
import { IMandator } from '../../mandator/interfaces/mandator';
import { ICustomer } from '../../customer/interfaces/customer';
import { IActivityType } from '../../activityType/interfaces/activityType';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';

const useStyles = makeStyles((theme) => ({
    iconOpen: {
        color: theme.palette.success.dark
    },
    iconClosed: {
        color: theme.palette.error.main
    }
}));

type Props = {
    data: ICustomerActivity[];
    customerId?: string;
    mandatorList: IMandator[];
    customerList: ICustomer[];
    activityTypeList: IActivityType[];
    listHeight?: string;
    onSave: Function;
    onClosedStatus: Function;
    onDelete: Function;
};

export const ActivityListComponent = ({
    data,
    customerId,
    mandatorList,
    customerList,
    activityTypeList,
    listHeight,
    onSave,
    onClosedStatus,
    onDelete
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [activityData, setActivityData] = React.useState<ICustomerActivity>();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'prodactivityListuctAdesSalesList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const activityTypeFilterList = React.useMemo(
        () => activityTypeList.map((item) => item.activity_type),
        [activityTypeList]
    );

    const mandatorFilterList = React.useMemo(() => mandatorList.map((item) => item.company), [mandatorList]);

    const handleDelete = (row: any) => {
        if (window.confirm(t('CUSTOMER_ACTIVITIES.CONF_DELETE'))) {
            onDelete(row);
        }
    };

    const handleClosedStatus = (row: any) => {
        if (window.confirm(t('CUSTOMER_ACTIVITIES.CONF_CHANGE_CLOSED_STATUS'))) {
            onClosedStatus(row);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'created_at_date',
            headerName: t(`CUSTOMER_ACTIVITIES.FIELDS.created_at_text`),
            width: getColumnsWidth('created_at_date', 200, columnWidth),
            type: 'dateTime',
            hide: !isColumnsVisibile('created_at_date', true, columnVisibility)
        },

        {
            field: 'activity_type',
            headerName: t(`CUSTOMER_ACTIVITIES.FIELDS.activity_type`),
            width: getColumnsWidth('activity_type', 200, columnWidth),
            hide: !isColumnsVisibile('activity_type', true, columnVisibility),
            type: 'singleSelect',
            valueOptions: activityTypeFilterList
        },
        {
            field: 'customer',
            headerName: t(`CUSTOMER_ACTIVITIES.FIELDS.customer`),
            width: getColumnsWidth('customer', 200, columnWidth),
            hide: !isColumnsVisibile('customer', true, columnVisibility)
        },
        {
            field: 'contact',
            headerName: t(`CUSTOMER_ACTIVITIES.FIELDS.contact`),
            width: getColumnsWidth('contact', 200, columnWidth),
            hide: !isColumnsVisibile('contact', true, columnVisibility)
        },
        {
            field: 'activity_description',
            headerName: t(`CUSTOMER_ACTIVITIES.FIELDS.activity_description`),
            width: getColumnsWidth('activity_description', 200, columnWidth),
            hide: !isColumnsVisibile('activity_description', true, columnVisibility)
        },
        {
            field: 'mandator',
            headerName: t(`CUSTOMER_ACTIVITIES.FIELDS.mandator`),
            width: getColumnsWidth('mandator', 200, columnWidth),
            hide: !isColumnsVisibile('mandator', true, columnVisibility),
            type: 'singleSelect',
            valueOptions: mandatorFilterList
        },
        {
            field: 'user',
            headerName: t(`CUSTOMER_ACTIVITIES.FIELDS.user`),
            width: getColumnsWidth('user', 200, columnWidth),
            hide: !isColumnsVisibile('user', true, columnVisibility)
        },
        {
            field: 'is_closed',
            headerName: t(`CUSTOMER_ACTIVITIES.FIELDS.is_closed`),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('is_closed', true, columnVisibility),
            width: getColumnsWidth('is_closed', 150, columnWidth),
            type: 'singleSelect',
            valueOptions: [
                { value: '0', label: 'No' },
                { value: '1', label: 'Sì' }
            ],
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => {
                if (parseInt(params.row.is_closed) === 1) {
                    return (
                        <IconButton className={classes.iconClosed} onClick={() => handleClosedStatus(params.row)}>
                            <LockOutlinedIcon />
                        </IconButton>
                    );
                }
                return (
                    <IconButton className={classes.iconOpen} onClick={() => handleClosedStatus(params.row)}>
                        <LockOpenOutlined />
                    </IconButton>
                );
            }
        },
        {
            field: 'delete',
            headerName: t(`COMMON.DELETE`),
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            width: getColumnsWidth('delete', 150, columnWidth),
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => (
                <IconButton color="secondary" onClick={() => handleDelete(params.row)}>
                    <DeleteForeverIcon />
                </IconButton>
            )
        },
        {
            field: 'id',
            headerName: t('CUSTOMER_ACTIVITIES.FIELDS.id'),
            width: getColumnsWidth('id', 100, columnWidth),
            filterable: false,
            sortable: false,
            hide: !isColumnsVisibile('id', false, columnVisibility)
        }
    ];

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        const { row } = params;
        setActivityData(row as ICustomerActivity);
        setOpen(true);
    };

    const handleAddNewClick = () => {
        setActivityData({} as ICustomerActivity);
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const onSubmit = (values: ICustomerActivity) => {
        values.is_closed = values.is_closed ? 1 : 0;
        onSave(values);
        setOpen(false);
    };

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <DataGridDefault
                        id={listId}
                        columns={columns}
                        data={data}
                        height={listHeight}
                        handleAddNewClick={handleAddNewClick}
                        handleRowDoubleClick={handleRowDoubleClick}
                        setColumnVisibility={setColumnVisibility}
                        setColumnWidth={setColumnWidth}
                    />
                </CardContent>
            </Card>
            <Dialog fullWidth onClose={handleCancel} open={open}>
                <DialogContent dividers>
                    <Form
                        onSubmit={onSubmit}
                        validate={(values) => {
                            const errors: { [key: string]: string } = {};

                            if (!values.activity_type_id) {
                                errors.activity = t('COMMON.FIELD_REQUIRED');
                            }

                            return errors;
                        }}
                        initialValues={activityData}
                        render={({ handleSubmit, hasValidationErrors }) => (
                            <form onSubmit={handleSubmit}>
                                {activityData.id && <Box my={3}>ID: {activityData.id}</Box>}
                                <Box my={1}>
                                    <Field name="activity_type_id" parse={(value, name) => parseInt(value)}>
                                        {({ input, meta }) => (
                                            <FormControl fullWidth>
                                                <InputLabel>{t('CUSTOMER_ACTIVITIES.FIELDS.activity_type')}</InputLabel>
                                                <Select
                                                    {...input}
                                                    label={t('CUSTOMER_ACTIVITIES.FIELDS.activity_type')}
                                                    fullWidth>
                                                    {activityTypeList.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.activity_type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>
                                                    {meta.error && meta.touched ? meta.error : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>
                                {!customerId && (
                                    <Box my={1}>
                                        <Autocomplete
                                            label={t('CUSTOMER_ACTIVITIES.FIELDS.customer')}
                                            name="customer_id"
                                            required={true}
                                            options={customerList}
                                            getOptionValue={(option) => option.id}
                                            getOptionLabel={(option) => option.company}
                                        />
                                    </Box>
                                )}
                                <Box my={1}>
                                    <Field name="contact">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMER_ACTIVITIES.FIELDS.contact')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMER_ACTIVITIES.FIELDS.contact')}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field name="mandator_id" parse={(value, name) => parseInt(value)}>
                                        {({ input, meta }) => (
                                            <FormControl fullWidth>
                                                <InputLabel>{t('CUSTOMER_ACTIVITIES.FIELDS.mandator')}</InputLabel>
                                                <Select {...input} fullWidth>
                                                    {mandatorList.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.company}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>
                                                    {meta.error && meta.touched ? meta.error : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field name="activity_description">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                type="text"
                                                multiline
                                                rows={6}
                                                error={meta.error && meta.touched}
                                                label={t('CUSTOMER_ACTIVITIES.FIELDS.activity_description')}
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                placeholder={t('CUSTOMER_ACTIVITIES.FIELDS.activity_description')}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </Box>
                                <Box my={1}>
                                    <Field
                                        name="is_closed"
                                        type="checkbox"
                                        parse={(value, name) => {
                                            return value ? 1 : 0;
                                        }}>
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                control={<Checkbox {...input} color="primary" />}
                                                label={t('CUSTOMER_ACTIVITIES.FIELDS.is_closed')}
                                            />
                                        )}
                                    </Field>
                                </Box>

                                <Box mt={3} textAlign="right">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={hasValidationErrors}>
                                        {t('COMMON.SAVE')}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};
