import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import KeyboardBackspaceOutlined from '@material-ui/icons/KeyboardBackspaceOutlined';
import purple from '@material-ui/core/colors/purple';
import { RouterLinkComponent } from '../link/router-link.component';

type Props = {
    path: string;
};

export const BackToList = ({ path }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Box display="flex" alignItems="center" mb={2} pb={1} borderBottom="1px solid #ccc">
             <Box display="flex" mr={3} color={purple[500]} style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
                <KeyboardBackspaceOutlined /> <Typography variant="body1">{t('COMMON.GO_BACK')}</Typography>
            </Box>
            <RouterLinkComponent to={path}>
                <Box display="flex">
                    <ArrowBackOutlinedIcon /> <Typography variant="body1">{t('COMMON.GO_BACK_TO_THE_LIST')}</Typography>
                </Box>
            </RouterLinkComponent>
           
        </Box>
    );
};
