import { useEmployeeDetail } from '../api/useEmployeeDetail';
import Loading from '../../../components/loading/loading.component';
import { IEmployee } from '../interfaces/employee';
import { EmployeeDetailComponent } from './employeeDetail.component';
import { useMutateEmployee } from '../api/useMutateEmployee';

type Props = {
    employeeId: string;
};

export const EmployeeDetailContainer = ({ employeeId }: Props) => {
    const {
        data: employeeData,
        isLoading: isEmployeeLoading,
        error: employeeError,
        isError: isEmployeeError
    } = useEmployeeDetail(employeeId);

    const {
        update: updateEmployeeMutation,
        create: createEmployeeMutation,
        // remove: removeEmployeeMutation
    } = useMutateEmployee();

    const { mutateAsync: updateEmployee } = updateEmployeeMutation;
    const { mutateAsync: createEmployee } = createEmployeeMutation;
    // const { mutateAsync: removeEmployee } = removeEmployeeMutation;

    if (isEmployeeLoading) {
        return <Loading />;
    }

    if (isEmployeeError) {
        console.error(employeeError);
        return <></>;
    }

    const handleUpdateEmployee = (data: IEmployee) => {
        updateEmployee(data);
    };

    const handleCreateEmployee = (data: IEmployee) => {
        createEmployee(data);
    };

    const handleSave = (data: IEmployee) => {
        data.id > 0 ? handleUpdateEmployee(data) : handleCreateEmployee(data);
    };

    return <EmployeeDetailComponent employeeData={employeeData} onSave={handleSave} />;
};
