import axios from '../../../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationState } from '../../../recoil/notification.atom';
import { IMandator } from '../interfaces/mandator';

export const useMutateMandator = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const setNotification = useSetRecoilState(notificationState);

    const create = useMutation<AxiosResponse, AxiosError, IMandator>((data: IMandator) => axios.post(`mandator`, data), {
        onSuccess: async (res) => {
            setNotification({
                open: true,
                severity: 'success',
                message: t('MANDATORS.CREATE_SUCCESS')
            });
            return res;
        },
        onError: (err) => {
            console.error(err.request.response);
            setNotification({ open: true, severity: 'error', message: t('MANDATORS.CREATE_ERROR') });
        },
        onSettled: async () => {
            await queryClient.invalidateQueries(QUERY_KEYS.MANDATOR_LIST);
        }
    });

    const update = useMutation<AxiosResponse, AxiosError, IMandator>(
        (data: IMandator) => axios.put(`mandator/${data.id}`, data),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('MANDATORS.UPDATE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('MANDATORS.UPDATE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.MANDATOR_LIST);
            }
        }
    );

    const remove = useMutation<AxiosResponse, AxiosError, IMandator>(
        (data: IMandator) => axios.delete(`mandator/${data.id}`),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('MANDATORS.DELETE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('MANDATORS.DELETE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.MANDATOR_LIST);
            }
        }
    );

    return { update, create, remove };
};
