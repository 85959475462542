import axios from '../../../helpers/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation } from 'react-query';

interface Res {
    inserted: number;
    updated: number;
    err_rows: unknown[];
}

type ImportRes = {
    artascii?: Res;
    cfasci?: Res;
    dispon_g?: Res;
    lisdet?: Res;
    lispec?: Res;
    stoascii?: Res;
    p_quote?: Res;
};

export const useImportAdes = () => {
    return useMutation<AxiosResponse<ImportRes>, AxiosError, FormData, unknown>(
        (formData: FormData) => axios.post('/import/ades', formData),
        {
            onError: (e) => {
                console.error('useImportAdes', e);
            }
        }
    );
};
