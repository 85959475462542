import axios from '../../../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ROUTE_PATHS } from '../../../config/routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationState } from '../../../recoil/notification.atom';
import { IProduct } from '../interfaces/product';

export const useMutateProduct = (customerId?: string) => {
    const { t } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const setNotification = useSetRecoilState(notificationState);

    const create = useMutation<AxiosResponse, AxiosError, IProduct>((data: IProduct) => axios.post(`product`, data), {
        onSuccess: async (res) => {
            setNotification({
                open: true,
                severity: 'success',
                message: t('PRODUCTS.CREATE_SUCCESS')
            });
            history.push(`${ROUTE_PATHS.PRODUCTS}/${res.data}`);
            return res;
        },
        onError: (err) => {
            console.error(err.request.response);
            setNotification({ open: true, severity: 'error', message: t('PRODUCTS.CREATE_ERROR') });
        },
        onSettled: async () => {
            await queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_LIST);
            if (customerId) {
                await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_ACTIVITY_LIST, customerId]);
            }
        }
    });

    const update = useMutation<AxiosResponse, AxiosError, IProduct>(
        (data: IProduct) => axios.put(`product/${data.id}`, data),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('PRODUCTS.UPDATE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('PRODUCTS.UPDATE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_LIST);
                if (customerId) {
                    await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_ACTIVITY_LIST, customerId]);
                }
            }
        }
    );

    const remove = useMutation<AxiosResponse, AxiosError, IProduct>(
        (data: IProduct) => axios.delete(`product/${data.id}`),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('PRODUCTS.DELETE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('PRODUCTS.DELETE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_LIST);
                if (customerId) {
                    await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_ACTIVITY_LIST, customerId]);
                }
            }
        }
    );

    return { update, create, remove };
};
