import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { Box, Paper, Button, TextField } from '@material-ui/core';
import { Field, Form } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: grey[50]
    },
    btFacebook: {
        color: '#ffffff',
        backgroundColor: '#3b5998',
        '&:hover': {
            backgroundColor: '#0069d9'
        }
    },
    btGoogle: {
        color: '#ffffff',
        backgroundColor: '#e05449'
    }
}));

interface Credentials {
    code: string;
    new_password: string;
    new_password_bis: string;
}

interface Props {
    code: string;
    resetPwd: Function;
}

export const ResetPwdComponent = ({ code, resetPwd }: Props) => {
    const classes = useStyles();

    const [userCredentials] = useState<Credentials>({
        code,
        new_password: '',
        new_password_bis: ''
    });

    const { t } = useTranslation();

    const onSubmit = async (values: Credentials) => {
        resetPwd(values);
    };

    return (
        <Paper variant="outlined" className={classes.paper}>
            <Box p={3}>
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors: { [key: string]: string } = {};
                        if (values.new_password && values.new_password.length < 6) {
                            errors.new_password = t('USERS.ERRORS.PWD_TOO_SHORT', { min: 6 });
                        }
                        if (values.new_password && values.new_password.length > 16) {
                            errors.new_password = t('USERS.ERRORS.PWD_TOO_LONG', { max: 16 });
                        }
                        if (
                            values.new_password &&
                            values.new_password_bis &&
                            values.new_password !== values.new_password_bis
                        ) {
                            errors.new_password_bis = t('USERS.ERRORS.PWD_NOT_EQUAL');
                        }

                        return errors;
                    }}
                    initialValues={userCredentials}
                    render={({ handleSubmit, hasValidationErrors }) => (
                        <form onSubmit={handleSubmit}>
                            <Box my={1}>
                                <Field name="new_password">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="password"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.new_password')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.new_password')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="new_password_bis">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="password"
                                            error={meta.error && meta.touched}
                                            label={t('USERS.FIELDS.new_password_bis')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('USERS.FIELDS.new_password_bis')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>

                            <Box mt={3} textAlign="right">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={hasValidationErrors}>
                                    {t('COMMON.SAVE')}
                                </Button>
                            </Box>
                        </form>
                    )}
                />
            </Box>
        </Paper>
    );
};
