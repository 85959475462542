import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Card, CardContent, Typography } from '@material-ui/core';
import { BackToList } from '../../../components/back-to-list/back-to-list';
import { Field, Form } from 'react-final-form';
import { IEmployee } from '../interfaces/employee';
import { ROUTE_PATHS } from '../../../config/routes';

type Props = {
    employeeData: IEmployee;
    onSave: any;
};

export const EmployeeDetailComponent = ({ employeeData, onSave }: Props) => {
    const { t } = useTranslation();

    const onSubmit = (values: IEmployee) => {
        onSave(values);
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <BackToList path={ROUTE_PATHS.EMPLOYEES} />
                <Typography variant="h4">{t('EMPLOYEES.PERSONAL_DATA')}</Typography>
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors: { [key: string]: string } = {};

                        if (!values.lastname) {
                            errors.lastname = t('COMMON.FIELD_REQUIRED');
                        }

                        return errors;
                    }}
                    initialValues={employeeData}
                    render={({ handleSubmit, hasValidationErrors }) => (
                        <form onSubmit={handleSubmit}>
                            {employeeData?.id && <Box my={3}>ID: {employeeData.id}</Box>}
                            <Box my={1}>
                                <Field name="person_prefix">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.person_prefix')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.person_prefix')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="firstname">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.firstname')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.firstname')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="lastname">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            required
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.lastname')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.lastname')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="email_default">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.email_default')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.email_default')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="phone_default">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.phone_default')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.phone_default')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="mobile_default">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.personal_mobile')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.personal_mobile')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="tax_code">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.tax_code')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.tax_code')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="annotation">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            multiline
                                            rows={6}
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.personal_annotation')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.annotation')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box mt={3} textAlign="right">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={hasValidationErrors}>
                                    {t('COMMON.SAVE')}
                                </Button>
                            </Box>
                        </form>
                    )}
                />
            </CardContent>
        </Card>
    );
};
