import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ICustomer } from '../interfaces/customer';

export const useCustomerList = () => {
    return useQuery<ICustomer[], AxiosError>(QUERY_KEYS.CUSTOMER_LIST, () =>
        axios.get<ICustomer[]>('customer').then((response) => response.data)
    );
};
