export const QUERY_KEYS = {
    CUSTOMER_LIST: 'CUSTOMER_LIST',
    CUSTOMER_DETAIL: 'CUSTOMER_LIST',
    CUSTOMER_IMPORT_COLUMNS: 'CUSTOMER_IMPORT_COLUMNS',
    MANDATOR_LIST: 'MANDATOR_LIST',
    MANDATOR_DETAIL: 'MANDATOR_DETAIL',
    CUSTOMER_MANDATOR_LIST: 'CUSTOMER_MANDATOR_LIST',
    CUSTOMER_MANDATOR_DETAIL: 'CUSTOMER_MANDATOR_DETAIL',
    CUSTOMER_ADDRESS_LIST: 'CUSTOMER_ADDRESS_LIST',
    CUSTOMER_ADDRESS_DETAIL: 'CUSTOMER_ADDRESS_DETAIL',
    CUSTOMER_CONTACT_LIST: 'CUSTOMER_CONTACT_LIST',
    CUSTOMER_CONTACT_DETAIL: 'CUSTOMER_CONTACT_DETAIL',
    CUSTOMER_EMPLOYEE_LIST: 'CUSTOMER_EMPLOYEE_LIST',
    CUSTOMER_EMPLOYEE_LIST_BY_CUSTOMER: 'CUSTOMER_EMPLOYEE_LIST_BY_CUSTOMER',
    CUSTOMER_EMPLOYEE_LIST_BY_EMPLOYEE: 'CUSTOMER_EMPLOYEE_LIST_BY_EMPLOYEE',
    CUSTOMER_ACTIVITY_LIST: 'CUSTOMER_ACTIVITY_LIST',
    CUSTOMER_CATEGORIES_LIST: 'CUSTOMER_CATEGORIES_LIST',
    ADES_SALES_LIST: 'ADES_SALES_LIST',
    ADES_SALES_LIST_BY_CUSTOMER: 'ADES_SALES_LIST_BY_CUSTOMER',
    ADES_SALES_LIST_BY_YEAR: 'ADES_SALES_LIST_BY_YEAR',
    ADES_SALES_LIST_BY_CUSTOMER_YEAR: 'ADES_SALES_LIST_BY_CUSTOMER_YEAR',
    ADES_SALES_YEAR_LIST: 'ADES_SALES_YEAR_LIST',
    ADES_SALES_TOT_PRODUCT_YEAR_LIST: 'ADES_SALES_TOT_PRODUCT_YEAR_LIST',
    ADES_SALES_TOT_CUSTOMER_YEAR_LIST: 'ADES_SALES_TOT_CUSTOMER_YEAR_LIST',
    ADES_SALES_TOT_YEAR_CUSTOMER: 'ADES_SALES_TOT_YEAR_CUSTOMER',
    ADES_SALES_QTY_YEAR: 'ADES_SALES_QTY_YEAR',
    SALES_IMPORT_COLUMNS: 'SALES_IMPORT_COLUMNS',
    ACTIVITY_TYPE_LIST: 'ACTIVITY_TYPE_LIST',
    CUSTOMER_CATEGORIES_TYPE_LIST: 'CUSTOMER_CATEGORIES_TYPE_LIST',
    CUSTOMER_CATEGORIES_TYPE_DETAIL: 'CUSTOMER_CATEGORIES_TYPE_DETAIL',
    ACTIVITY_LIST: 'ACTIVITY_LIST',
    PRODUCT_LIST: 'PRODUCT_LIST',
    PRODUCT_DETAIL: 'PRODUCT_DETAIL',
    PRODUCT_IMPORT_COLUMNS: 'PRODUCT_IMPORT_COLUMNS',
    USER_LIST: 'USER_LIST',
    USER_DETAIL: 'USER_DETAIL',
    EMPLOYEE_DETAIL: 'EMPLOYEE_DETAIL'
};
