import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Card, CardContent, Typography } from '@material-ui/core';
import { Autocomplete } from 'mui-rff';
import { Field, Form } from 'react-final-form';
import { ICustomerEmployee } from '../interfaces/customerEmployee';
import { ICustomer } from '../../customer/interfaces/customer';

type Props = {
    customerEmployeeData: ICustomerEmployee;
    customerList: ICustomer[];
    onSave: any;
};

export const CustomerEmployeeDetailComponent = ({ customerEmployeeData, customerList, onSave }: Props) => {
    const { t } = useTranslation();

    const onSubmit = (values: ICustomerEmployee) => {
        onSave(values);
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h4">{t('EMPLOYEES.COMPANY_DATA')}</Typography>
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors: { [key: string]: string } = {};

                        if (!values.customer_id) {
                            errors.customer_id = t('COMMON.FIELD_REQUIRED');
                        }

                        return errors;
                    }}
                    initialValues={customerEmployeeData}
                    render={({ handleSubmit, hasValidationErrors }) => (
                        <form onSubmit={handleSubmit}>
                            {customerEmployeeData.id && <Box my={3}>ID: {customerEmployeeData.id}</Box>}
                            <Box my={1}>
                                <Autocomplete
                                    label={t('CUSTOMER_ACTIVITIES.FIELDS.customer')}
                                    name="customer_id"
                                    required
                                    options={customerList}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.company}
                                />
                            </Box>
                            <Box my={1}>
                                <Field name="job_title">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.job_title')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.job_title')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="unit">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.unit')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.unit')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="email">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.company_email')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.company_email')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="phone">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.company_phone')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.company_phone')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="mobile">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.company_mobile')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.company_mobile')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="annotation">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            multiline
                                            rows={6}
                                            error={meta.error && meta.touched}
                                            label={t('EMPLOYEES.FIELDS.company_annotation')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('EMPLOYEES.FIELDS.annotation')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box mt={3} textAlign="right">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={hasValidationErrors}>
                                    {t('COMMON.SAVE')}
                                </Button>
                            </Box>
                        </form>
                    )}
                />
            </CardContent>
        </Card>
    );
};
