import React from 'react';
import { useRecoilState } from 'recoil';
import { notificationState } from '../../recoil/notification.atom';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Notification = () => {
    const [state, setState] = useRecoilState(notificationState);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setState({ ...state, open: false });
    };

    return (
        <Snackbar
            open={state.open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity={state.severity}>
                {state.message}
            </Alert>
        </Snackbar>
    );
};
