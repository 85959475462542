import { Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useRecoilValue } from 'recoil';
import { currentUserState } from './recoil/currentUser.atom';
import { ErrorBoundaryComponent as ErrorBoundary } from './components/error-boundary/error-boundary.component';
import { LoadingComponent as Loading } from './components/loading/fullscreen_loading.component';
import { PublicRouteComponent as PublicRoute } from './components/routes/public-route.component';
import { PrivateRouteComponent as PrivateRoute } from './components/routes/private-route.component';

import { defaultTheme } from './config/material-theme';
import { publicRoutes, restrictedRoutes, privateRoutes } from './config/routes';

import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
    'fbde9d856c39c3aa252aaac4d6cfe9b6T1JERVI6Mjg4NDUsRVhQSVJZPTE2NjIwMTgzODgwMDAsS0VZVkVSU0lPTj0x'
);

export const App = () => {
    const queryClient = new QueryClient();

    const currentUser = useRecoilValue(currentUserState);
    const token = localStorage.getItem('token');

    const isLoggedIn = Boolean(token) && currentUser && currentUser.id > 0;

    return (
        <ThemeProvider theme={defaultTheme}>
            <ErrorBoundary>
                <Suspense fallback={<Loading show={true} />}>
                    <QueryClientProvider client={queryClient}>
                        <Switch>
                            {publicRoutes.map((item) => (
                                <PublicRoute
                                    key={item.path}
                                    isLoggedIn={isLoggedIn}
                                    restricted={false}
                                    component={item.component}
                                    layout={item.layout}
                                    path={item.path}
                                    exact={item.exact}
                                />
                            ))}
                            {restrictedRoutes.map((item) => (
                                <PublicRoute
                                    key={item.path}
                                    isLoggedIn={isLoggedIn}
                                    restricted={true}
                                    component={item.component}
                                    layout={item.layout}
                                    path={item.path}
                                    exact={item.exact}
                                />
                            ))}
                            {privateRoutes.map((item) => (
                                <PrivateRoute
                                    key={item.path}
                                    isLoggedIn={isLoggedIn}
                                    restricted={true}
                                    component={item.component}
                                    layout={item.layout}
                                    path={item.path}
                                    exact={item.exact}
                                />
                            ))}
                        </Switch>
                    </QueryClientProvider>
                </Suspense>
            </ErrorBoundary>
        </ThemeProvider>
    );
};
