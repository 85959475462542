import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import { Card, CardContent, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { IMandator } from '../interfaces/mandator';
import { ROUTE_PATHS } from '../../../config/routes';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';

type Props = {
    data: IMandator[];
    onDelete: Function;
};

export const MandatorListComponent = ({ data, onDelete }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'mandatorList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        history.push(`${ROUTE_PATHS.MANDATORS}/${params.row.id}`);
    };

    const handleAddNewClick = () => {
        history.push(`${ROUTE_PATHS.MANDATORS}/new`);
    };

    const handleDelete = (row: any) => {
        if (window.confirm(t('MANDATORS.CONF_DELETE'))) {
            onDelete(row);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'company',
            headerName: t(`MANDATORS.FIELDS.company`),
            width: getColumnsWidth('company', 200, columnWidth),
            hide: !isColumnsVisibile('company', true, columnVisibility)
        },
        {
            field: 'city',
            headerName: t(`MANDATORS.FIELDS.city`),
            width: getColumnsWidth('city', 200, columnWidth),
            hide: !isColumnsVisibile('city', true, columnVisibility)
        },
        {
            field: 'province',
            headerName: t(`MANDATORS.FIELDS.province`),
            width: getColumnsWidth('province', 110, columnWidth),
            hide: !isColumnsVisibile('province', true, columnVisibility)
        },
        {
            field: 'nationality',
            headerName: t(`MANDATORS.FIELDS.nationality`),
            width: getColumnsWidth('nationality', 110, columnWidth),
            hide: !isColumnsVisibile('nationality', true, columnVisibility)
        },
        {
            field: 'email_default',
            headerName: t(`MANDATORS.FIELDS.email_default`),
            width: getColumnsWidth('email_default', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('email_default', false, columnVisibility)
        },
        {
            field: 'phone_default',
            headerName: t(`MANDATORS.FIELDS.phone_default`),
            width: getColumnsWidth('phone_default', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('phone_default', true, columnVisibility)
        },
        {
            field: 'www',
            headerName: t(`MANDATORS.FIELDS.www`),
            width: getColumnsWidth('www', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('www', true, columnVisibility)
        },
        {
            field: 'vat_code',
            headerName: t(`MANDATORS.FIELDS.vat_code`),
            width: getColumnsWidth('vat_code', 200, columnWidth),
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            hide: !isColumnsVisibile('vat_code', false, columnVisibility)
        },

        {
            field: 'delete',
            headerName: t(`COMMON.DELETE`),
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            width: getColumnsWidth('delete', 130, columnWidth),
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => (
                <IconButton
                    color="secondary"
                    onClick={() => {
                        handleDelete(params.row);
                    }}>
                    <DeleteForeverIcon />
                </IconButton>
            )
        },
        {
            field: 'id',
            headerName: t('MANDATORS.FIELDS.id'),
            width: getColumnsWidth('id', 60, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('id', false, columnVisibility)
        }
    ];

    return (
        <Card variant="outlined">
            <CardContent>
                <DataGridDefault
                    id={listId}
                    columns={columns}
                    data={data}
                    handleAddNewClick={handleAddNewClick}
                    handleRowDoubleClick={handleRowDoubleClick}
                    setColumnVisibility={setColumnVisibility}
                        setColumnWidth={setColumnWidth}
                />
            </CardContent>
        </Card>
    );
};
