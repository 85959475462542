import { CircularProgress, styled, Typography } from '@material-ui/core';

const LoaderContainer = styled('div')({
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
});

export interface LoadingProps {
    text?: string;
    size?: number;
    thickness?: number;
}

const Loading = ({ text, size, thickness }: LoadingProps) => (
    <LoaderContainer>
        <CircularProgress thickness={thickness} size={size} />
        {text && <Typography variant="h4">{text}</Typography>}
    </LoaderContainer>
);

export default Loading;
