import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Card, CardContent } from '@material-ui/core';

import { IAdesSaleTotYearByProduct } from '../../adesSales/interfaces/adesSaleTotYearByProduct';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { RouterLinkComponent } from '../../../components/link/router-link.component';
import { ROUTE_PATHS } from '../../../config/routes';
import { currency, quantity } from '../../../helpers';

type Props = {
    data: IAdesSaleTotYearByProduct[];
};

export const AdesSaleListComponent = ({ data }: Props) => {
    const { t } = useTranslation();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'productAdesSalesList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                field: 'customer_code',
                headerName: t('ADES_SALES.FIELDS.customer_code'),
                width: getColumnsWidth('customer_code', 150, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('customer_code', true, columnVisibility)
            },
            {
                field: 'customer_name',
                headerName: t(`ADES_SALES.FIELDS.customer_name`),
                width: getColumnsWidth('customer_name', 250, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('customer_name', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    if (params.row.customer_id) {
                        return (
                            <RouterLinkComponent to={`${ROUTE_PATHS.CUSTOMERS}/${params.row.customer_id}/sales`}>
                                {params.row.customer_name}
                            </RouterLinkComponent>
                        );
                    } else {
                        return <span>{params.row.customer_name}</span>;
                    }
                }
            },
            {
                field: 'year_ref',
                headerName: t(`ADES_SALES.FIELDS.year_ref`),
                width: getColumnsWidth('year_ref', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('year_ref', true, columnVisibility)
            },
            {
                field: 'tot_quantity',
                headerName: t(`ADES_SALES.FIELDS.tot_quantity`),
                width: getColumnsWidth('tot_quantity', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('tot_quantity', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{quantity.format(params.row.tot_quantity)}</span>
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'sell_price',
                headerName: t(`ADES_SALES.FIELDS.sell_price`),
                width: getColumnsWidth('sell_price', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('sell_price', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.sell_price)}</span>
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'list_price',
                headerName: t(`ADES_SALES.FIELDS.list_price`),
                width: getColumnsWidth('list_price', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('list_price', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.list_price)}</span>
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'tot_customer_year',
                headerName: t(`ADES_SALES.FIELDS.tot_customer_year`),
                width: getColumnsWidth('tot_customer_year', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('tot_customer_year', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.tot_customer_year)}</span>
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'id',
                headerName: t('PRODUCTS.FIELDS.id'),
                width: getColumnsWidth('id', 100, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('id', false, columnVisibility)
            }
        ],
        [columnVisibility, columnWidth, getColumnsWidth, isColumnsVisibile, t]
    );

    return (
        <Card variant="outlined">
            <CardContent>
                <DataGridDefault
                    id={listId}
                    columns={columns}
                    data={data}
                    setColumnVisibility={setColumnVisibility}
                    setColumnWidth={setColumnWidth}
                />
            </CardContent>
        </Card>
    );
};
