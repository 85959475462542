import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'
import { INotification } from '../interfaces/notification';

const { persistAtom } = recoilPersist()

const initValue: INotification = {
    open: false,
    severity: 'info',
    message: ''
};

export const notificationState = atom<INotification>({
    key: 'notification',
    default: initValue,
    effects_UNSTABLE: [persistAtom]
});
