import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { grey, green } from '@material-ui/core/colors';
import {
    Box,
    Button,
    Typography,
    IconButton,
    FormControlLabel,
    Switch,
    TextField,
    Select,
    MenuItem
} from '@material-ui/core';
import { LoadingComponent as Loader } from '../loading/fullscreen_loading.component';
import { Alert } from '@material-ui/lab';
import { Delete } from '@material-ui/icons';
import axios from '../../helpers/axios';
import { useMandatorList } from '../../pages/mandator/api/useMandatorList';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useBulkDelete } from '../../pages/importFile/api/useBulkDelete';

const useStyles = (props: { isDragActive: boolean }) =>
    makeStyles((theme: Theme) =>
        createStyles({
            dropZone: {
                backgroundColor: 'white',
                textAlign: 'center',
                boxSizing: 'border-box',
                borderWidth: '2px',
                borderStyle: props.isDragActive ? 'dashed' : 'dotted',
                borderColor: props.isDragActive ? green[500] : grey[500]
            },
            fileInput: {
                height: 0,
                width: 0,
                display: 'none'
            },
            deleteButton: {
                color: theme.palette.error.main
            }
        })
    );

export type ResponseType = {
    filename: string;
    previewRows: string[][];
};

interface Props {
    hasFirstRowHeader: boolean;
    separator: string;
    mandatorId: number;
    yearSel: number;
    showDelButton?: boolean;
    onUpload: (d: ResponseType) => void;
    onHasRowHeaderChange: (b: boolean) => void;
    setHasFirstRowHeader: (b: boolean) => void;
    setSeparator: (t: string) => void;
    setMandatorId: (n: number) => void;
    setYearSel: (n: number) => void;
}

export const ImportFile = ({
    hasFirstRowHeader,
    separator,
    mandatorId,
    yearSel,
    showDelButton,
    onUpload,
    onHasRowHeaderChange,
    setHasFirstRowHeader,
    setSeparator,
    setMandatorId,
    setYearSel
}: Props) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
    const [validFiles, setValidFiles] = React.useState<File[]>([]);
    const [unsupportedFiles, setUnsupportedFiles] = React.useState<File[]>([]);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isDragActive, setDragActive] = React.useState(false);
    const [uploadPercentage, setUploadPercentage] = React.useState(0);

    const fileInputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const uploadRef = React.useRef() as React.MutableRefObject<HTMLSpanElement>;

    const classes = useStyles({ isDragActive })();

    const {
        data: mandatorListData,
        isLoading: isMandatorListLoading,
        error: mandatorListError,
        isError: isMandatorListError
    } = useMandatorList();

    const { mutate: bulkDelete } = useBulkDelete(yearSel, mandatorId);

    React.useEffect(() => {
        let filteredArray = selectedFiles.reduce((files: File[], current: File) => {
            const res = files.some((item) => item.name === current.name);
            if (!res) {
                return files.concat([current]);
            } else {
                return files;
            }
        }, []);
        setValidFiles([...filteredArray]);
    }, [selectedFiles]);

    const hasAllParams = mandatorId > 0 && yearSel > 2000;

    if (isMandatorListLoading) {
        return <Loader show={true} />;
    }

    if (isMandatorListError) {
        console.error(mandatorListError);
        return <></>;
    }

    const dragOver = (e: any) => {
        e.preventDefault();
        setDragActive(true);
    };

    const dragEnter = (e: any) => {
        e.preventDefault();
    };

    const dragLeave = (e: any) => {
        e.preventDefault();
    };

    const fileSize = (size: number) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const fileType = (fileName: string) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    };

    const handleFiles = (files: any) => {
        const validateFile = (file: File) => {
            const validTypes = ['text/csv', 'application/vnd.ms-excel'];
            return validTypes.some((fType) => fType === file.type);
        };

        for (let i = 0; i < files.length; i++) {
            if (!validateFile(files[i])) {
                files[i]['invalid'] = true;

                setErrorMessage('IMPORT_FILES.FILE_UPLOADER.FILE_TYPE_NOT_PERMITTED');
                setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
            }

            setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        }
    };

    const removeFile = (name: string) => {
        const validFileIndex = validFiles.findIndex((e) => e.name === name);
        validFiles.splice(validFileIndex, 1);
        setValidFiles([...validFiles]);

        const selectedFileIndex = selectedFiles.findIndex((e) => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        setSelectedFiles([...selectedFiles]);

        const unsupportedFileIndex = unsupportedFiles.findIndex((e) => e.name === name);
        if (unsupportedFileIndex !== -1) {
            unsupportedFiles.splice(unsupportedFileIndex, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }

        if (unsupportedFiles.length === 0) {
            setErrorMessage('');
        }
    };

    const filesSelected = () => {
        if (fileInputRef?.current?.files?.length) {
            handleFiles(fileInputRef.current.files);
        }
    };

    const fileDrop = (e: any) => {
        e.preventDefault();
        setDragActive(false);
        const files = e.dataTransfer.files;
        console.log(files, typeof files);
        if (files.length) {
            handleFiles(files);
        }
    };

    const fileInputClicked = () => {
        fileInputRef?.current?.click();
    };

    const uploadFiles = async () => {
        const uploadedData: ResponseType[] = [];

        for (let i = 0; i < validFiles.length; i++) {
            const formData = new FormData();
            formData.append('datafile', validFiles[i]);
            formData.append('separator', separator);

            await axios
                .post<ResponseType>('data-file-upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const up = Math.floor((progressEvent.loaded / progressEvent.total) * 100);

                        uploadRef.current.innerHTML = up + '%';

                        setUploadPercentage(up);

                        if (up === 100) {
                            uploadRef.current.innerHTML += ' ' + t('IMPORT_FILES.FILE_UPLOADER.FILE_UPLOADED');
                            validFiles.length = 0;
                            setValidFiles([...validFiles]);
                            setSelectedFiles([...validFiles]);
                            setUnsupportedFiles([...validFiles]);
                        }
                    }
                })
                .then((res) => {
                    uploadedData.push(res.data);
                    return onUpload(res.data);
                })
                .catch((e) => {
                    console.error('Error during file upload:', e);
                    uploadRef.current.innerHTML = t('IMPORT_FILES.FILE_UPLOADER.FILE_UPLOADED_ERROR');
                    setErrorMessage(t('IMPORT_FILES.FILE_UPLOADER.FILE_UPLOADED_ERROR'));
                });
        }

        return uploadedData;
    };

    const handleRowHeader = () => {
        onHasRowHeaderChange(!hasFirstRowHeader);
        setHasFirstRowHeader(!hasFirstRowHeader);
    };

    const handleMandator = (e) => {
        setMandatorId(e.target.value);
    };

    const handleDeleteYearMadantor = () => {
        const mandatorName = mandatorListData.find((item) => item.id === mandatorId)?.company;

        if (window.confirm(t('IMPORT_FILES.FILE_UPLOADER.CONF_BULK_DELETE', { mandatorName, yearSel }))) {
            bulkDelete();
        }
    };

    return (
        <section id="file-uploader">
            <Box px={1} my={2} display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                <Box minWidth={['100%', '30%']} py={[1, 0]}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={hasFirstRowHeader || false}
                                onChange={handleRowHeader}
                                name="hasFirstRowHeader"
                                color="primary"
                            />
                        }
                        label={t('IMPORT_FILES.FILE_UPLOADER.HAS_HEADER_ROW')}
                    />
                </Box>
                <Box py={[1, 0]}>
                    <TextField
                        label="Separator"
                        inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                        variant="outlined"
                        defaultValue={separator}
                        onChange={(e) => setSeparator(e.target.value)}
                    />
                </Box>
            </Box>
            <Box px={1} my={2} display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                <Box minWidth={['100%', '30%']} my={[1, 0]}>
                    <TextField
                        label="Anno importazione"
                        inputProps={{ maxLength: 4, style: { textAlign: 'center' } }}
                        variant="outlined"
                        defaultValue={yearSel}
                        onChange={(e) => setYearSel(parseInt(e.target.value))}
                    />
                </Box>
                <Box my={[1, 0]}>
                    <Select
                        label={t('MANDATORS.FIELDS.mandator')}
                        fullWidth
                        value={mandatorId}
                        variant="outlined"
                        onChange={handleMandator}>
                        {mandatorListData.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.company}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box my={[1, 0]}>
                    {showDelButton && (
                        <IconButton
                            className={classes.deleteButton}
                            disabled={!hasAllParams}
                            onClick={handleDeleteYearMadantor}>
                            <Delete /> {t('IMPORT_FILES.FILE_UPLOADER.BULK_DELETE')}
                        </IconButton>
                    )}
                </Box>
            </Box>

            <Box
                p={2}
                className={`drop-container ${classes.dropZone}`}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={fileInputClicked}>
                <p>{t('IMPORT_FILES.FILE_UPLOADER.DROP_HERE')}</p>
                <input ref={fileInputRef} className={classes.fileInput} type="file" multiple onChange={filesSelected} />
            </Box>
            <Box my={1} display="flex" alignItems="center">
                <Box flexGrow="1" marginRight={4}>
                    <Alert variant="outlined" severity={errorMessage ? 'error' : 'info'}>
                        <span ref={uploadRef}>{uploadPercentage} %</span>
                    </Alert>
                </Box>
                <Box textAlign="right">
                    {unsupportedFiles.length ? (
                        <p>{t('IMPORT_FILES.FILE_UPLOADER.REMOVE_UNVALID')}</p>
                    ) : (
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={unsupportedFiles.length > 0 || !validFiles.length || !hasAllParams}
                            onClick={() => uploadFiles()}>
                            {t('IMPORT_FILES.FILE_UPLOADER.UPLOAD')}
                        </Button>
                    )}
                </Box>
            </Box>
            <Box my={1}>
                <div className="file-display-container">
                    {validFiles.map((data, i) => (
                        <Box display="flex" alignItems="center" key={i}>
                            <Box width="60%">
                                <Typography component="span" color={data['invalid'] ? 'error' : 'initial'}>
                                    {data.name}
                                </Typography>
                                {data['invalid'] && (
                                    <Typography component="span" color="error">
                                        {' '}
                                        ( {t(errorMessage)} )
                                    </Typography>
                                )}
                            </Box>
                            <Box width="10%">{fileType(data.name)}</Box>
                            <Box width="10%">{fileSize(data.size)}</Box>
                            <Box width="20%">
                                <IconButton className={classes.deleteButton} onClick={() => removeFile(data.name)}>
                                    <Delete />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </div>
            </Box>
        </section>
    );
};
