import React from 'react';
import { Route } from 'react-router-dom';

interface Props {
    component: any;
    layout: any;
    isLoggedIn: boolean;
    restricted: boolean;
    exact?: boolean;
    path: string;
}

export const PublicRouteComponent = ({
    component: Component,
    layout: Layout,
    isLoggedIn,
    restricted,
    ...rest
}: Props) => {
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    if (isLoggedIn && restricted) {
        // return <Route {...rest} render={() => <Redirect to="/dashboard" />} />;
    }

    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout {...props}>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};
