import React from 'react';
import { Box } from '@material-ui/core';
import { UserListContainer } from './components/userList.container';

export const UserListPage = () => {
    return (
        <Box flexGrow="1" id="container">
            <UserListContainer />
        </Box>
    );
};
