import { useProductDetail } from '../api/useProductDetail';
import { useMandatorList } from '../../mandator/api/useMandatorList';
import { useMutateProduct } from '../api/useMutateProduct';
import Loading from '../../../components/loading/loading.component';
import { IProduct } from '../interfaces/product';
import { ProductDetailComponent } from './productDetail.component';
import { fixDecimalNumber } from '../../../helpers';

type Props = {
    productId: string;
};

export const ProductDetailContainer = ({ productId }: Props) => {
    const {
        data: productData,
        isLoading: isProductLoading,
        error: productError,
        isError: isProductError
    } = useProductDetail(productId);

    const {
        data: mandatorListData,
        isLoading: isMandatorListLoading,
        error: mandatorListError,
        isError: isMandatorListError
    } = useMandatorList();

    const { update: updateProductMutation, create: createProductMutation } = useMutateProduct();
    const { mutateAsync: updateProduct } = updateProductMutation;
    const { mutateAsync: createProduct } = createProductMutation;

    if (isProductLoading || isMandatorListLoading) {
        return <Loading />;
    }

    if (isProductError) {
        console.error(productError);
        return <></>;
    }
    if (isMandatorListError) {
        console.error(mandatorListError);
        return <></>;
    }

    const handleUpdate = (data: IProduct) => {
        data.price_default = fixDecimalNumber(data.price_default.toString());
        data.purchase_price = fixDecimalNumber(data.purchase_price.toString());
        data.last_purchase_price = fixDecimalNumber(data.last_purchase_price.toString());
        data.special_price = fixDecimalNumber(data.special_price.toString());

        updateProduct(data);
    };

    const handleCreate = (data: IProduct) => {
        createProduct(data).then((res) => console.log('I created product id', res));
    };

    const handleSave = (data: IProduct) => (data.id > 0 ? handleUpdate(data) : handleCreate(data));

    return <ProductDetailComponent productData={productData} mandatorList={mandatorListData} onSave={handleSave} />;
};
