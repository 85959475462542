import React from 'react';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { LogoComponent } from '../components/logo/logo.component';

interface Props {
    children: React.ReactNode;
}

export const RestrictedLayout = ({ children }: Props) => (
    <Container maxWidth="xs">
        <Box height="100vh" display="flex" flexDirection="column" mt="20%">
            <Box p={2}>
                <LogoComponent />
            </Box>
            {children}
        </Box>
    </Container>
);
