import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { Box, Paper, Button, TextField } from '@material-ui/core';
import { RouterLinkComponent as Link } from '../../../components/link/router-link.component';
import { ROUTE_PATHS } from '../../../config/routes';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: grey[50]
    },
    btFacebook: {
        color: '#ffffff',
        backgroundColor: '#3b5998',
        '&:hover': {
            backgroundColor: '#0069d9'
        }
    },
    btGoogle: {
        color: '#ffffff',
        backgroundColor: '#e05449'
    }
}));

interface Props {
    emailSignIn: Function;
}

export const SignInComponent = ({ emailSignIn }: Props) => {
    const classes = useStyles();

    const [userCredentials, setCredentials] = useState({
        email: '',
        password: ''
    });

    const { email, password } = userCredentials;

    const { t } = useTranslation();

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        emailSignIn({ email, password });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.currentTarget;

        setCredentials({ ...userCredentials, [name]: value });
    };

    return (
        <Paper variant="outlined" className={classes.paper}>
            <Box p={3}>
                <form onSubmit={handleSubmit}>
                    <Box my={2}>
                        <TextField name="email" label="Email" variant="outlined" fullWidth onChange={handleChange} />
                    </Box>
                    <Box my={2}>
                        <TextField
                            type="password"
                            name="password"
                            label="Password"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Box>
                    <Box my={2}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            {t('LOGIN.LOGIN_WITH_EMAIL')}
                        </Button>
                    </Box>
                </form>
                <hr />
                <Box mt={4} display="flex" justifyContent="space-between">
                    <Link to={ROUTE_PATHS.FORGOT_PWD}> {t('LOGIN.FORGOT_PWD')}</Link>
                    {/* <span>|</span>
                    <Link to="/register">New user? Register</Link> */}
                </Box>
            </Box>
        </Paper>
    );
};
