import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { IProduct } from '../interfaces/product';

export const useProductDetail = (productId: string) => {
    return useQuery<IProduct, AxiosError>([QUERY_KEYS.PRODUCT_DETAIL, productId], () =>
        axios.get<IProduct>(`product/${productId}`).then((response) => response.data)
    );
};
