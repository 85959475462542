import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ICustomerCategoryType } from '../interfaces/customerCategoryType';

export const useCustomerCategoryTypeList = () => {
    return useQuery<ICustomerCategoryType[], AxiosError>(QUERY_KEYS.CUSTOMER_CATEGORIES_TYPE_LIST, () =>
        axios.get<ICustomerCategoryType[]>('customer-category-type').then((response) => response.data)
    );
};
