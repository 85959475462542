import { useCustomerContactListByCustomer } from '../api/useCustomerContactList';
import { useMutateCustomerContact } from '../api/useMutateCustomerContact';
import { CustomerContactListComponent } from './customerContactList.component';
import { ICustomerContact } from '../interfaces/customerContact';
import Loading from '../../../components/loading/loading.component';

type Props = {
    customerId: string;
};

export const CustomerContactListContainer = ({ customerId }: Props) => {
    const {
        update: updateCustomerContactMutation,
        create: createCustomerContactMutation,
        remove: removeCustomerContactMutation
    } = useMutateCustomerContact(customerId);
    const { mutateAsync: updateCustomerContact } = updateCustomerContactMutation;
    const { mutateAsync: createCustomerContact } = createCustomerContactMutation;
    const { mutateAsync: removeCustomerContact } = removeCustomerContactMutation;

    const {
        data: customerContactListData,
        isLoading: isCustomerContactListLoading,
        error: customerContactListError,
        isError: isCustomerContactListError,
        refetch: refecthCustomerContactList
    } = useCustomerContactListByCustomer(customerId);

    if (isCustomerContactListError) {
        console.error(customerContactListError);
        return <></>;
    }

    const handleUpdateCustomerContact = (data: ICustomerContact) => {
        data.customer_id = parseInt(customerId);
        updateCustomerContact(data).then(() => {
            refecthCustomerContactList();
        });
    };
    const handleCreateCustomerContact = (data: ICustomerContact) => {
        data.customer_id = parseInt(customerId);
        createCustomerContact(data).then(() => {
            refecthCustomerContactList();
        });
    };
    const handleRemoveCustomerContact = (data: ICustomerContact) => {
        removeCustomerContact(data).then(() => {
            refecthCustomerContactList();
        });
    };

    const handleSaveCustomerContact = (data: ICustomerContact) =>
        data.id > 0 ? handleUpdateCustomerContact(data) : handleCreateCustomerContact(data);

    return isCustomerContactListLoading ? (
        <Loading />
    ) : (
        <CustomerContactListComponent
            data={customerContactListData}
            onSave={handleSaveCustomerContact}
            onDelete={handleRemoveCustomerContact}
        />
    );
};
