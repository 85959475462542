import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import { Card, CardContent, IconButton } from '@material-ui/core';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import { ICustomerEmployee } from '../interfaces/customerEmployee';
import { ROUTE_PATHS } from '../../../config/routes';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';
import { RouterLinkComponent } from '../../../components/link/router-link.component';

const useStyles = makeStyles((theme) => ({
    iconOpen: {
        color: theme.palette.success.dark
    },
    iconClosed: {
        color: theme.palette.error.main
    }
}));

type Props = {
    data: ICustomerEmployee[];
    onDelete: Function;
};

export const EmployeeListComponent = ({ data, onDelete }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'employeeList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        history.push(`${ROUTE_PATHS.EMPLOYEES}/${params.row['employee_id']}`);
    };

    const handleAddNewClick = () => {
        history.push(`${ROUTE_PATHS.EMPLOYEES}/new`);
    };

    const handleDelete = React.useCallback(
        (row: any) => {
            if (window.confirm(t('EMPLOYEES.CONF_DELETE'))) {
                onDelete(row);
            }
        },
        [onDelete, t]
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                field: 'person_prefix',
                headerName: t(`EMPLOYEES.FIELDS.person_prefix`),
                width: getColumnsWidth('person_prefix', 200, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('person_prefix', true, columnVisibility)
            },
            {
                field: 'firstname',
                headerName: t(`EMPLOYEES.FIELDS.firstname`),
                width: getColumnsWidth('firstname', 200, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('firstname', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return (
                        <RouterLinkComponent to={`${ROUTE_PATHS.EMPLOYEES}/${params.row['employee_id']}`}>
                            {params.row.firstname}
                        </RouterLinkComponent>
                    );
                }
            },
            {
                field: 'lastname',
                headerName: t(`EMPLOYEES.FIELDS.lastname`),
                width: getColumnsWidth('lastname', 200, columnWidth),
                hide: !isColumnsVisibile('lastname', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return (
                        <RouterLinkComponent to={`${ROUTE_PATHS.EMPLOYEES}/${params.row['employee_id']}`}>
                            {params.row.lastname}
                        </RouterLinkComponent>
                    );
                }
            },
            {
                field: 'customer',
                headerName: t(`EMPLOYEES.FIELDS.customer`),
                width: getColumnsWidth('customer', 200, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('customer', true, columnVisibility)
            },
            {
                field: 'unit',
                headerName: t(`EMPLOYEES.FIELDS.unit`),
                width: getColumnsWidth('unit', 200, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('unit', true, columnVisibility)
            },
            {
                field: 'job_title',
                headerName: t(`EMPLOYEES.FIELDS.job_title`),
                width: getColumnsWidth('job_title', 200, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('job_title', true, columnVisibility)
            },
            {
                field: 'phone_default',
                headerName: t(`EMPLOYEES.FIELDS.phone_default`),
                width: getColumnsWidth('phone_default', 200, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('phone_default', true, columnVisibility)
            },
            {
                field: 'mobile_default',
                headerName: t(`EMPLOYEES.FIELDS.personal_mobile`),
                width: getColumnsWidth('mobile_default', 200, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('mobile_default', true, columnVisibility)
            },
            {
                field: 'email_default',
                headerName: t(`EMPLOYEES.FIELDS.email_default`),
                width: getColumnsWidth('email_default', 200, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('email_default', true, columnVisibility)
            },
            {
                field: 'personal_annotation',
                headerName: t(`EMPLOYEES.FIELDS.personal_annotation`),
                width: getColumnsWidth('personal_annotation', 400, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('personal_annotation', true, columnVisibility)
            },
            {
                field: 'company_annotation',
                headerName: t(`EMPLOYEES.FIELDS.company_annotation`),
                width: getColumnsWidth('company_annotation', 400, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('company_annotation', true, columnVisibility)
            },
            {
                field: 'is_active',
                headerName: t(`EMPLOYEES.FIELDS.is_active`),
                width: getColumnsWidth('is_active', 150, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('is_active', false, columnVisibility),
                sortable: false,
                filterable: false,
                type: 'singleSelect',
                valueOptions: [
                    { value: '0', label: 'No' },
                    { value: '1', label: 'Sì' }
                ],
                align: 'center',
                headerAlign: 'center',
                renderCell: (params: GridCellParams) => {
                    if (params.row.is_active || parseInt(params.row.is_active) === 1) {
                        return <LockOutlinedIcon className={classes.iconClosed} titleAccess="In forza" />;
                    }
                    return <LockOpenOutlined className={classes.iconOpen} titleAccess="Non lavora piu' qui" />;
                }
            },
            {
                field: 'delete',
                headerName: t(`COMMON.DELETE`),
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                width: getColumnsWidth('delete', 130, columnWidth),
                align: 'center',
                headerAlign: 'center',
                hide: !isColumnsVisibile('delete', false, columnVisibility),
                renderCell: (params: GridCellParams) => (
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            handleDelete(params.row);
                        }}>
                        <DeleteForeverIcon />
                    </IconButton>
                )
            },
            {
                field: 'employee_id',
                headerName: t('EMPLOYEES.FIELDS.id'),
                type: 'number',
                width: getColumnsWidth('employee_id', 70, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('employee_id', false, columnVisibility)
            }
        ],
        [
            classes.iconClosed,
            classes.iconOpen,
            columnVisibility,
            columnWidth,
            getColumnsWidth,
            handleDelete,
            isColumnsVisibile,
            t
        ]
    );

    return (
        <Card variant="outlined">
            <CardContent>
                <DataGridDefault
                    id={listId}
                    columns={columns}
                    data={data}
                    handleAddNewClick={handleAddNewClick}
                    handleRowDoubleClick={handleRowDoubleClick}
                    setColumnVisibility={setColumnVisibility}
                    setColumnWidth={setColumnWidth}
                />
            </CardContent>
        </Card>
    );
};
