import { useTranslation } from 'react-i18next';
import { Container, Box } from '@material-ui/core';
import { LogoComponent } from '../../components/logo/logo.component';
import { RouterLinkComponent as Link } from '../../components/link/router-link.component';
import { ROUTE_PATHS } from '../../config/routes';

export const Home = () => {
    const { t } = useTranslation();

    return (
        <Container maxWidth="xs">
            <Box textAlign="center" height="100vh" display="flex" flexDirection="column" justifyContent="center">
                <Box p={2} title="Visita il sito">
                    <a href="https://www.apiceservice.it/" target="_new">
                        <LogoComponent />
                    </a>
                </Box>
                <Box m={3}>
                    <Link to={ROUTE_PATHS.LOGIN}>{t('HOME.LOGIN_WITH_EMAIL')}</Link>
                </Box>
            </Box>
        </Container>
    );
};
