import axios from '../../../helpers/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { ROUTE_PATHS } from '../../../config/routes';

export const useLostPwd = () => {
    return useMutation<AxiosResponse<string | boolean>, AxiosError, string, unknown>(
        (email: string) =>
            axios.post('/forgot-password', {
                email: email
            }),
        {
            onError: (e) => {
                console.error('useLostPwd', e);
            },
            onSuccess: ({ data }) => {
                window.location.href = ROUTE_PATHS.LOGIN;
            }
        }
    );
};
