import React from 'react';
import { useLogout } from '../../hooks/useLogout';
import { useLogin } from './api/useLogin';
import { SignInComponent as SignIn } from './components/signIn.component';
import { ILogin } from '../../interfaces/login';

export const SignInPage = () => {
    const { mutate: login } = useLogin();

    const logout = useLogout();

    React.useEffect(() => {
        logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const emailSignIn = ({ email, password }: ILogin) => {
        login({ email, password });
    };

    return <SignIn emailSignIn={emailSignIn} />;
};
