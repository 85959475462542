import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IAdesSales } from '../../adesSales/interfaces/adesSales';
import { ROUTE_PATHS } from '../../../config/routes';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { RouterLinkComponent } from '../../../components/link/router-link.component';
import { currency, quantity } from '../../../helpers';

const useStyles = makeStyles({
    bold: {
        fontWeight: 'bold'
    },
    bgOrange: {
        backgroundColor: 'rgba(250, 211, 145, 0.8)'
    },
    bgLightGreen: {
        backgroundColor: 'rgba(145, 250, 229, 0.8)'
    },
    bgViolet: {
        backgroundColor: 'rgba(187, 179, 227, 0.8)'
    }
});

type Props = {
    data: IAdesSales[];
    yearList: number[];
    yearSelected: number;
    showCustomer?: boolean;
};

export const AdesSalesFullListComponent = ({ data, yearList, yearSelected, showCustomer = true }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'adesSalesFullList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const pastYearsList = yearList.filter((year) => Number(year) < Number(yearSelected)).slice(-3);

    const qtyYearColumns = React.useMemo(
        () =>
            pastYearsList.map((year) => {
                return {
                    field: `qty_${year}`,
                    headerName: `${year - 2000}Qt`, //t(`ADES_SALES.FIELDS.quantity`) + ' ' + year,
                    width: getColumnsWidth(`qty_${year}`, 100, columnWidth),
                    hide: !isColumnsVisibile(`qty_${year}`, true, columnVisibility),
                    disableColumnMenu: true,
                    type: 'number',
                    renderCell: (params: GridCellParams) => {
                        return params.row[`qty_${year}`] ? (
                            <span>{quantity.format(params.row[`qty_${year}`])}</span>
                        ) : (
                            0
                        );
                    },
                    valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
                };
            }),
        [columnVisibility, columnWidth, getColumnsWidth, isColumnsVisibile, pastYearsList]
    );

    const totYearColumns = React.useMemo(
        () =>
            pastYearsList.map((year) => {
                return {
                    field: `tot_${year}`,
                    headerName: `${year - 2000}VL`, //t(`ADES_SALES.FIELDS.tot`) + ' ' + year,
                    width: getColumnsWidth(`tot_${year}`, 100, columnWidth),
                    hide: !isColumnsVisibile(`tot_${year}`, true, columnVisibility),
                    cellClassName: classes.bold,
                    disableColumnMenu: true,
                    type: 'number',
                    renderCell: (params: GridCellParams) => {
                        return params.row[`tot_${year}`] ? (
                            <span>{currency.format(params.row[`tot_${year}`])}</span>
                        ) : (
                            0
                        );
                    },
                    valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
                };
            }),
        [classes.bold, columnVisibility, columnWidth, getColumnsWidth, isColumnsVisibile, pastYearsList]
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                field: 'customer_code',
                headerName: t(`ADES_SALES.FIELDS.customer_code`),
                width: getColumnsWidth('customer_code', 150, columnWidth),
                hide: !isColumnsVisibile('customer_code', showCustomer, columnVisibility)
            },
            {
                field: 'customer_name',
                headerName: t(`ADES_SALES.FIELDS.customer_name`),
                width: getColumnsWidth('customer_name', 300, columnWidth),
                hide: !isColumnsVisibile('customer_name', showCustomer, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    if (params.row.customer_id) {
                        return (
                            <RouterLinkComponent to={`${ROUTE_PATHS.CUSTOMERS}/${params.row.customer_id}/sales`}>
                                {params.row.customer_name}
                            </RouterLinkComponent>
                        );
                    } else {
                        return <span>{params.row.customer_name}</span>;
                    }
                }
            },
            {
                field: 'mandator',
                headerName: t(`ADES_SALES.FIELDS.mandator`),
                width: getColumnsWidth('mandator', 150, columnWidth),
                hide: !isColumnsVisibile('mandator', true, columnVisibility)
            },
            {
                field: 'product_code',
                headerName: t(`ADES_SALES.FIELDS.product_code`),
                width: getColumnsWidth('product_code', 150, columnWidth),
                hide: !isColumnsVisibile('product_code', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    if (params.row.product_id) {
                        return (
                            <RouterLinkComponent to={`${ROUTE_PATHS.PRODUCTS}/sel/${params.row.product_id}`}>
                                {params.row.product_code}
                            </RouterLinkComponent>
                        );
                    } else {
                        return <span>{params.row.product_code}</span>;
                    }
                }
            },
            {
                field: 'customer_product_code',
                headerName: t(`ADES_SALES.FIELDS.customer_product_code`),
                width: getColumnsWidth('customer_product_code', 150, columnWidth),
                hide: !isColumnsVisibile('customer_product_code', true, columnVisibility)
            },
            {
                field: 'manufacturer_product_code',
                headerName: t(`PRODUCTS.FIELDS.manufacturer_product_code`),
                width: getColumnsWidth('manufacturer_product_code', 150, columnWidth),
                hide: !isColumnsVisibile('manufacturer_product_code', true, columnVisibility)
            },
            {
                field: 'product_name',
                headerName: t(`ADES_SALES.FIELDS.product_name`),
                width: getColumnsWidth('product_name', 500, columnWidth),
                hide: !isColumnsVisibile('product_name', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    if (params.row.product_id) {
                        return (
                            <RouterLinkComponent to={`${ROUTE_PATHS.PRODUCTS}/${params.row.product_id}`}>
                                {params.row.product_name}
                            </RouterLinkComponent>
                        );
                    } else {
                        return <span>{params.row.product_name}</span>;
                    }
                }
            },
            ...qtyYearColumns,
            ...totYearColumns,
            {
                field: 'quantity',
                headerName: t(`ADES_SALES.FIELDS.quantity`),
                width: getColumnsWidth('quantity', 120, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('quantity', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{quantity.format(params.row.quantity)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'quantity_to_be_processed',
                headerName: t(`ADES_SALES.FIELDS.quantity_to_be_processed`),
                width: getColumnsWidth('quantity_to_be_processed', 120, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('quantity_to_be_processed', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{quantity.format(params.row.quantity_to_be_processed)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'sell_price',
                headerName: t(`ADES_SALES.FIELDS.sell_price`),
                width: getColumnsWidth('sell_price', 140, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('sell_price', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.sell_price)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'list_price',
                headerName: t(`ADES_SALES.FIELDS.list_price`),
                width: getColumnsWidth('list_price', 140, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('list_price', true, columnVisibility),
                cellClassName: classes.bgOrange,
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.list_price)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'tot_sell',
                headerName: t(`ADES_SALES.FIELDS.tot_sell`),
                width: getColumnsWidth('tot_sell', 140, columnWidth),
                type: 'number',
                cellClassName: classes.bold,
                disableColumnMenu: true,
                hide: !isColumnsVisibile('tot_sell', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.tot_sell)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'price_default',
                headerName: t(`PRODUCTS.FIELDS.price_default`),
                width: getColumnsWidth('price_default', 140, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('price_default', true, columnVisibility),
                cellClassName: classes.bgLightGreen,
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.price_default)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'purchase_price',
                headerName: t(`PRODUCTS.FIELDS.purchase_price`),
                width: getColumnsWidth('purchase_price', 140, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('purchase_price', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.purchase_price)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'special_price',
                headerName: t(`PRODUCTS.FIELDS.special_price`),
                width: getColumnsWidth('special_price', 140, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('special_price', true, columnVisibility),
                cellClassName: classes.bgViolet,
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.special_price)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'last_purchase_price',
                headerName: t(`PRODUCTS.FIELDS.last_purchase_price`),
                width: getColumnsWidth('last_purchase_price', 140, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('last_purchase_price', true, columnVisibility),
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'id',
                headerName: t('ADES_SALES.FIELDS.id'),
                width: getColumnsWidth('id', 60, columnWidth),
                disableColumnMenu: true,
                sortable: false,
                hide: !isColumnsVisibile('id', false, columnVisibility)
            }
            // {
            //     field: 'year_ref',
            //     headerName: t(`ADES_SALES.FIELDS.year_ref`),
            //     width: getColumnsWidth('year_ref', 100, columnWidth),
            //     type: 'number',
            //     disableColumnMenu: true,
            //     hide: !isColumnsVisibile('year_ref', false, columnVisibility)
            // },
            // {
            //     field: 'customer_code',
            //     headerName: t(`ADES_SALES.FIELDS.customer_code`),
            //     width: getColumnsWidth('customer_code', 150, columnWidth),
            //     hide: !isColumnsVisibile('customer_code', false, columnVisibility)
            // },
            // {
            //     field: 'customer_name',
            //     headerName: t(`ADES_SALES.FIELDS.customer_name`),
            //     width: getColumnsWidth('customer_name', 300, columnWidth),
            //     hide: !isColumnsVisibile('customer_name', false, columnVisibility),
            //     renderCell: (params: GridCellParams) => {
            //         if (params.row.customer_id) {
            //             return (
            //                 <RouterLinkComponent to={`${ROUTE_PATHS.CUSTOMERS}/${params.row.customer_id}/sales`}>
            //                     {params.row.customer_name}
            //                 </RouterLinkComponent>
            //             );
            //         } else {
            //             return <span>{params.row.customer_name}</span>;
            //         }
            //     }
            // },
        ],
        [
            t,
            getColumnsWidth,
            columnWidth,
            isColumnsVisibile,
            columnVisibility,
            qtyYearColumns,
            totYearColumns,
            classes.bgOrange,
            classes.bold,
            classes.bgLightGreen,
            classes.bgViolet
        ]
    );

    return (
        <Card variant="outlined">
            <CardContent>
                <DataGridDefault
                    id={listId}
                    columns={columns}
                    data={data}
                    height="60vh"
                    setColumnVisibility={setColumnVisibility}
                    setColumnWidth={setColumnWidth}
                />
            </CardContent>
        </Card>
    );
};
