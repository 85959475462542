import React from 'react';
import { useProductList } from '../api/useProductList';
import { useMutateProduct } from '../api/useMutateProduct';
import Loading from '../../../components/loading/loading.component';
import { ProductListComponent } from './productList.component';
import { IProduct } from '../interfaces/product';

export const ProductListContainer = () => {
    const {
        remove: removeProductMutation
    } = useMutateProduct();
    const { mutateAsync: removeProduct } = removeProductMutation;

    const {
        data: productListData,
        isLoading: isProductListLoading,
        error: productListError,
        isError: isProductListError,
        refetch: refecthProductList
    } = useProductList();

    const handleRemoveProduct = React.useCallback(
        (data: IProduct) => () => {
            removeProduct(data).then(() => {
                refecthProductList();
            });
        },
        [removeProduct, refecthProductList]
    );

    if (isProductListLoading) {
        return <Loading />;
    }

    if (isProductListError) {
        console.error(productListError);
        return <></>;
    }

    return <ProductListComponent data={productListData} onDelete={handleRemoveProduct} />;
};
