import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ICustomerContact } from '../interfaces/customerContact';

export const useCustomerContactListByCustomer = (customerId: string) => {
    return useQuery<ICustomerContact[], AxiosError>([QUERY_KEYS.CUSTOMER_CONTACT_LIST, customerId], () =>
        parseInt(customerId) > 0
            ? axios.get<ICustomerContact[]>(`customer-contact/customer/${customerId}`).then((response) => response.data)
            : []
    );
};

export const useCustomerContactList = () => {
    return useQuery<ICustomerContact[], AxiosError>(QUERY_KEYS.CUSTOMER_CONTACT_LIST, () =>
        axios.get<ICustomerContact[]>(`customer-contact`).then((response) => response.data)
    );
};
