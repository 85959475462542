import React, { MouseEventHandler, SyntheticEvent } from 'react';
import {
    DataGridPro,
    GridColDef,
    GridFilterModel,
    GridRowParams,
    GridSortModel,
    MuiEvent,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { DEFAULT_LIST_LENGTH } from '../../config/constants';
import { useRecoilState } from 'recoil';
import { ColumnIndex, ColumnVisibility, ColumnWidth, ListParams, listParamsState } from '../../recoil/listParams.atom';
import { ListToolbar } from '../list-toolbar/listToolbar';
import { Box } from '@material-ui/core';
import { BackToList } from '../back-to-list/back-to-list';

const useStyles = makeStyles((theme) => ({
    DataGridPro: {
        border: 'none',
        '&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
            backgroundColor: '#FFFFA5'
        }
    },
    iconOpen: {
        color: theme.palette.success.dark
    },
    iconClosed: {
        color: theme.palette.error.main
    },
    row: {
        cursor: 'pointer'
    }
}));

const getColumnsVisibility = (
    columnsVisibility: ColumnVisibility[],
    newColumnVisibility: ColumnVisibility
): ColumnVisibility[] => {
    if (!columnsVisibility.length) {
        return [{ field: newColumnVisibility.field, isVisible: newColumnVisibility.isVisible }];
    }

    const columnsRes = [...columnsVisibility];
    const index = columnsRes.findIndex((item) => item?.field === newColumnVisibility.field);

    if (index > -1) {
        const col = { ...columnsRes[index] };
        col.isVisible = newColumnVisibility.isVisible;
        columnsRes[index] = col;
    } else {
        columnsRes.push({ field: newColumnVisibility.field, isVisible: newColumnVisibility.isVisible });
    }

    return columnsRes;
};

const getColumnsWidth = (columnsWidth: ColumnWidth[], newColumnWidth: ColumnWidth): ColumnWidth[] => {
    if (!columnsWidth?.length) {
        return [{ field: newColumnWidth.field, width: newColumnWidth.width }];
    }

    const columnsRes = [...columnsWidth];
    const index = columnsRes.findIndex((item) => item?.field === newColumnWidth.field);

    if (index > -1) {
        const col = { ...columnsRes[index] };
        col.width = newColumnWidth.width;
        columnsRes[index] = col;
    } else {
        columnsRes.push({ field: newColumnWidth.field, width: newColumnWidth.width });
    }

    return columnsRes;
};

const getColumnsIndex = (columnsIndex: ColumnIndex[], newColumnIndex: ColumnIndex): ColumnIndex[] => {
    if (!columnsIndex?.length) {
        return [
            { field: newColumnIndex.field, oldIndex: newColumnIndex.oldIndex, targetIndex: newColumnIndex.targetIndex }
        ];
    }

    const columnsRes = [...columnsIndex];
    const index = columnsRes.findIndex((item) => item?.field === newColumnIndex.field);

    if (index > -1) {
        const col = { ...columnsRes[index] };
        col.oldIndex = newColumnIndex.oldIndex;
        col.targetIndex = newColumnIndex.targetIndex;
        columnsRes[index] = col;
    } else {
        columnsRes.push({
            field: newColumnIndex.field,
            oldIndex: newColumnIndex.oldIndex,
            targetIndex: newColumnIndex.targetIndex
        });
    }

    return columnsRes;
};

type Props = {
    id: string;
    columns: GridColDef[];
    data: any;
    height?: string;
    showFooter?: boolean;
    handleAddNewClick?: MouseEventHandler;
    handleRowClick?: (params: GridRowParams, event: MuiEvent<SyntheticEvent<Element, Event>>, details) => void;
    handleRowDoubleClick?: (params: GridRowParams, event: MuiEvent<SyntheticEvent<Element, Event>>, details) => void;
    setColumnVisibility?: Function;
    setColumnWidth?: Function;
};

export const DataGridDefault = (
    {
        id,
        columns,
        data,
        height,
        showFooter = true,
        handleAddNewClick,
        handleRowClick,
        handleRowDoubleClick,
        setColumnVisibility,
        setColumnWidth
    }: Props,
    ...props
) => {
    const [pageDest, setPageDest] = React.useState<number>(-1);
    const [params, setParams] = React.useState<ListParams>();
    const [columnsReOrdered, setColumnsReOrdered] = React.useState<GridColDef[]>(columns);
    const [globalParams, setGlobalParams] = useRecoilState<ListParams[]>(listParamsState);

    const classes = useStyles();

    const { id_selected } = useParams<{ id_selected: string }>();

    let listId = id;
    listId += id_selected ? `_${id_selected}` : '';

    const api = useGridApiRef();

    React.useEffect(() => {
        const curParams = globalParams.find((p) => p.id === listId);

        if (curParams) {
            setParams(curParams);
        }
    }, [globalParams, listId]);

    React.useEffect(() => {
        setColumnsReOrdered(columns);
    }, []);

    // React.useEffect(() => {
    //     const handleReorderColumn = (newColumnIndex: ColumnIndex) => {
    //         const tmpCurrentColumn = columnsReOrdered[newColumnIndex.oldIndex];

    //         columnsReOrdered.splice(newColumnIndex.oldIndex, 1);
    //         columnsReOrdered.splice(newColumnIndex.targetIndex, 0, tmpCurrentColumn);

    //         // return curColums;
    //     };

    //     const reorderColumns = (curParams: ListParams) => {
    //         if (curParams?.columnsIndex.length > 0) {
    //             // let curColums = [...columnsReOrdered];

    //             curParams.columnsIndex.forEach((newColumnIndex) => {
    //                 handleReorderColumn(newColumnIndex);
    //             });

    //             // setColumnsReOrdered();
    //         }
    //     };

    //     const curParams = globalParams.find((p) => p.id === listId);

    //     if (curParams) {
    //         reorderColumns(curParams);
    //     }
    // }, [columnsReOrdered, globalParams, listId]);

    React.useEffect(() => {
        const curParams = globalParams.find((p) => p.id === listId);
        if (curParams) {
            if (setColumnVisibility && curParams?.columnsVisibility) {
                setColumnVisibility(curParams.columnsVisibility);
            }
            if (setColumnWidth && curParams?.columnsWidth) {
                setColumnWidth(curParams.columnsWidth);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalParams]);

    React.useEffect(() => {
        if (id_selected) {
            const idIndex = api.current.getRowIndex(id_selected);

            api.current.selectRow(id_selected);

            const pageSize = globalParams[listId]?.pageSize || DEFAULT_LIST_LENGTH;

            const newPage = Math.floor(idIndex / pageSize);

            if (newPage >= 0 && newPage !== pageDest) {
                setPageDest(newPage);
                api.current.setPage(newPage);
            }
        }
    }, [api, globalParams, listId, id_selected, pageDest]);

    const setNewParams = ({
        newPage,
        newPageSize,
        newFilterModel,
        newSortModel,
        newColumnVisibility,
        newColumnWidth,
        newColumnIndex
    }: {
        newPage?: number;
        newPageSize?: number;
        newFilterModel?: GridFilterModel;
        newSortModel?: GridSortModel;
        newColumnVisibility?: ColumnVisibility;
        newColumnWidth?: ColumnWidth;
        newColumnIndex?: ColumnIndex;
    }) => {
        const allParams = [...globalParams];
        const index = allParams.findIndex((item) => item.id === listId);

        let newParams: ListParams;

        let pageToSet = 0;

        if (pageDest >= 0) {
            pageToSet = pageDest;
        } else {
            if (newPage >= 0) {
                pageToSet = newPage;
            } else {
                pageToSet = newParams?.page || 0;
            }
        }

        if (index > -1) {
            let newParams = { ...allParams[index] };
            newParams.page = pageToSet;
            newParams.pageSize = newPageSize ?? newParams.pageSize;
            newParams.filterModel = newFilterModel ?? newParams.filterModel;
            newParams.sortModel = newSortModel ?? newParams.sortModel;
            newParams.columnsVisibility = newColumnVisibility
                ? getColumnsVisibility(newParams.columnsVisibility, newColumnVisibility)
                : newParams.columnsVisibility;
            newParams.columnsWidth = newColumnWidth
                ? getColumnsWidth(newParams.columnsWidth, newColumnWidth)
                : newParams.columnsWidth;
            newParams.columnsIndex = newColumnIndex
                ? getColumnsIndex(newParams.columnsIndex, newColumnIndex)
                : newParams.columnsIndex;

            allParams[index] = newParams;
        } else {
            newParams = {
                id: listId,
                page: newPage,
                pageSize: newPageSize,
                filterModel: newFilterModel,
                sortModel: newSortModel,
                columnsVisibility: newColumnVisibility ? [newColumnVisibility] : [],
                columnsWidth: newColumnWidth ? [newColumnWidth] : [],
                columnsIndex: newColumnIndex ? [newColumnIndex] : []
            };
            allParams.push(newParams);
        }

        setGlobalParams(allParams);

        if (newColumnVisibility?.field) {
            setColumnVisibility(newParams?.columnsVisibility || false);
        }

        if (newColumnWidth?.field && newParams?.columnsWidth) {
            setColumnWidth(newParams.columnsWidth);
        }
    };

    return (
        <Box height={height || '80vh'}>
            <DataGridPro
                apiRef={api}
                columns={columnsReOrdered}
                rows={data}
                page={params?.page || 0}
                pageSize={params?.pageSize || DEFAULT_LIST_LENGTH}
                filterModel={params?.filterModel}
                sortModel={params?.sortModel}
                density="compact"
                rowsPerPageOptions={[
                    Math.round(DEFAULT_LIST_LENGTH / 2),
                    DEFAULT_LIST_LENGTH,
                    DEFAULT_LIST_LENGTH * 2,
                    DEFAULT_LIST_LENGTH * 4
                ]}
                pagination={showFooter}
                hideFooterPagination={id_selected && showFooter}
                // autoHeight
                disableSelectionOnClick={false}
                className={classes.DataGridPro}
                getRowClassName={() => classes.row}
                components={{
                    Toolbar: () =>
                        id_selected ? <BackToList path="../" /> : <ListToolbar onAddNewClick={handleAddNewClick} />
                }}
                onPageChange={(newPage) => {
                    setNewParams({ newPage });
                }}
                onPageSizeChange={(newPageSize) => setNewParams({ newPageSize })}
                onFilterModelChange={(newFilterModel) => setNewParams({ newFilterModel })}
                onSortModelChange={(newSortModel) => setNewParams({ newSortModel })}
                onColumnVisibilityChange={(newColumnVisibility) =>
                    setNewParams({
                        newColumnVisibility: {
                            field: newColumnVisibility.field,
                            isVisible: newColumnVisibility.isVisible
                        }
                    })
                }
                onColumnResize={(newColumnSize) =>
                    setNewParams({
                        newColumnWidth: {
                            field: newColumnSize.colDef.field,
                            width: newColumnSize.width
                        }
                    })
                }
                onColumnOrderChange={(data) => {
                    // handleReorderColumn(data);

                    setNewParams({
                        newColumnIndex: {
                            field: data.field,
                            oldIndex: data.oldIndex,
                            targetIndex: data.targetIndex
                        }
                    });
                }}
                onRowClick={handleRowClick}
                onRowDoubleClick={handleRowDoubleClick}
                {...props}
            />
        </Box>
    );
};
