import { useDrag } from 'react-dnd';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green, orange, red } from '@material-ui/core/colors';
import { IMPORT_COL_WIDTH } from '../../config/constants';

const useStyles = (props: { isMandatory: boolean; isDragging: boolean }) =>
    makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: `${IMPORT_COL_WIDTH}px`,
                padding: theme.spacing(1),
                margin: '0',
                backgroundColor: 'white',
                textAlign: 'center',
                boxSizing: 'border-box',
                borderWidth: '1px',
                borderStyle: 'dotted',
                borderColor: props.isDragging ? red[500] : props.isMandatory ? orange[500] : green[500],
                opacity: props.isDragging ? 0.4 : 1,
                cursor: props.isDragging ? 'grabbing' : 'grab'
            }
        })
    );

type Props = {
    id: number | string;
    name: string;
    label: string;
    type: string;
    isMandatory: boolean;
    onDrop: Function;
};

interface DropResult {
    name: string;
}

export const DragColumn = ({ id, name, label, type, isMandatory, onDrop }: Props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: type,
        item: { id, name },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>();
            if (item && dropResult) {
                onDrop({ dragResult: item, dropResult });
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId()
        })
    }));

    const classes = useStyles({ isMandatory, isDragging })();

    return (
        <div ref={drag} className={classes.root} data-testid={`drag-column-${name}`}>
            {label}
        </div>
    );
};
