import React from 'react';
import { useTotYearByProduct } from '../../adesSales/api/useSalesList';
import Loading from '../../../components/loading/loading.component';
import { AdesSaleListComponent } from './adesSaleList.component';

type Props = {
    productId: string;
};

export const AdesSaleListContainer = ({ productId }: Props) => {
    const {
        data: adesSaleListData,
        isLoading: isAdesSaleListLoading,
        error: adesSaleListError,
        isError: isAdesSaleListError
    } = useTotYearByProduct(productId);

    if (isAdesSaleListLoading) {
        return <Loading />;
    }

    if (isAdesSaleListError) {
        console.error(adesSaleListError);
        return <></>;
    }

    adesSaleListData.forEach((item, i) => (item['id'] = i));

    return <AdesSaleListComponent data={adesSaleListData} />;
};
