import React from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import { Card, CardContent, Dialog, DialogContent, IconButton } from '@material-ui/core';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import { ICustomerEmployee } from '../interfaces/customerEmployee';
import { CustomerEmployeeDetailComponent } from './customerEmployeeDetail.component';
import { ICustomer } from '../../customer/interfaces/customer';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';

const useStyles = makeStyles((theme) => ({
    iconOpen: {
        color: theme.palette.success.dark
    },
    iconClosed: {
        color: theme.palette.error.main
    }
}));

type Props = {
    data: ICustomerEmployee[];
    customerList: ICustomer[];
    dialogOpen: boolean;
    setDialogOpen: Function;
    onSave: Function;
    onDelete: Function;
    onActiveStatus: Function;
};

export const CustomerEmployeeListComponent = ({
    data,
    customerList,
    dialogOpen,
    setDialogOpen,
    onSave,
    onDelete,
    onActiveStatus
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [customerEmployeeData, setCustomerEmployeeData] = React.useState<ICustomerEmployee>();
    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'employeeCustomerEmployeeList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        const { row } = params;
        setCustomerEmployeeData(row as ICustomerEmployee);
        setDialogOpen(true);
    };

    const handleAddNewClick = () => {
        setCustomerEmployeeData({} as ICustomerEmployee);
        setDialogOpen(true);
    };
    const handleCancel = () => {
        setDialogOpen(false);
    };

    const handleDelete = (row: any) => {
        if (window.confirm(t('EMPLOYEES.CONF_DELETE'))) {
            onDelete(row);
        }
    };

    const handleClosedStatus = (row: any) => {
        if (window.confirm(t('EMPLOYEES.CONF_CHANGE_CLOSED_STATUS'))) {
            onActiveStatus(row);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'email',
            headerName: t(`EMPLOYEES.FIELDS.company_email`),
            width: getColumnsWidth('email', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('email', true, columnVisibility)
        },
        {
            field: 'phone',
            headerName: t(`EMPLOYEES.FIELDS.company_phone`),
            width: getColumnsWidth('phone', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('phone', true, columnVisibility)
        },
        {
            field: 'mobile',
            headerName: t(`EMPLOYEES.FIELDS.company_mobile`),
            width: getColumnsWidth('mobile', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('mobile', true, columnVisibility)
        },
        {
            field: 'customer',
            headerName: t(`EMPLOYEES.FIELDS.customer`),
            width: getColumnsWidth('customer', 200, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('customer', true, columnVisibility)
        },
        {
            field: 'job_title',
            headerName: t(`EMPLOYEES.FIELDS.job_title`),
            width: getColumnsWidth('job_title', 150, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('job_title', true, columnVisibility)
        },
        {
            field: 'unit',
            headerName: t(`EMPLOYEES.FIELDS.unit`),
            width: getColumnsWidth('unit', 150, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('unit', true, columnVisibility)
        },
        {
            field: 'annotation',
            headerName: t(`EMPLOYEES.FIELDS.company_annotation`),
            width: getColumnsWidth('annotation', 400, columnWidth),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('annotation', true, columnVisibility)
        },
        {
            field: 'is_active',
            headerName: t(`EMPLOYEES.FIELDS.is_active`),
            disableColumnMenu: true,
            hide: !isColumnsVisibile('is_active', true, columnVisibility),
            width: getColumnsWidth('is_active', 150, columnWidth),
            type: 'singleSelect',
            valueOptions: [
                { value: '0', label: 'No' },
                { value: '1', label: 'Sì' }
            ],
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => {
                if (params.row.is_active || parseInt(params.row.is_active) === 1) {
                    return (
                        <IconButton className={classes.iconClosed} onClick={() => handleClosedStatus(params.row)}>
                            <LockOutlinedIcon />
                        </IconButton>
                    );
                }
                return (
                    <IconButton className={classes.iconOpen} onClick={() => handleClosedStatus(params.row)}>
                        <LockOpenOutlined />
                    </IconButton>
                );
            }
        },
        {
            field: 'id',
            headerName: t('EMPLOYEES.FIELDS.id'),
            type: 'number',
            width: getColumnsWidth('id', 60, columnWidth),
            disableColumnMenu: true,
            sortable: false,
            hide: !isColumnsVisibile('id', false, columnVisibility)
        },
        {
            field: 'delete',
            headerName: t(`COMMON.DELETE`),
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            width: getColumnsWidth('delete', 130, columnWidth),
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridCellParams) => (
                <IconButton
                    color="secondary"
                    onClick={() => {
                        handleDelete(params.row);
                    }}>
                    <DeleteForeverIcon />
                </IconButton>
            )
        }
    ];

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <DataGridDefault
                        id={listId}
                        columns={columns}
                        data={data}
                        handleAddNewClick={handleAddNewClick}
                        handleRowDoubleClick={handleRowDoubleClick}
                        setColumnVisibility={setColumnVisibility}
                        setColumnWidth={setColumnWidth}
                    />
                </CardContent>
            </Card>
            <Dialog fullWidth onClose={handleCancel} open={dialogOpen}>
                <DialogContent dividers>
                    <CustomerEmployeeDetailComponent
                        customerEmployeeData={customerEmployeeData}
                        customerList={customerList}
                        onSave={onSave}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};
