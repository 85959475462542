import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { ICustomer } from '../interfaces/customer';
import { IUser } from '../../user/interfaces/user';
import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';

type Props = {
    data: ICustomer;
    users: IUser[];
    onSave: Function;
};

export const CustomerDetailsComponent = ({ data, users, onSave }: Props) => {
    const { t } = useTranslation();
    const onSubmit = (values: ICustomer) => {
        onSave(values);
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors: { [key: string]: string } = {};

                        if (!values.company) {
                            errors.company = t('COMMON.FIELD_REQUIRED');
                        }

                        return errors;
                    }}
                    initialValues={{
                        id: data.id,
                        code: data.code,
                        firstname: data.firstname,
                        lastname: data.lastname,
                        company: data.company,
                        email_default: data.email_default,
                        phone_default: data.phone_default,
                        lang: data.lang,
                        locale: data.locale,
                        timezone: data.timezone,
                        nationality: data.nationality,
                        tax_code: data.tax_code,
                        vat_code: data.vat_code,
                        reg_num: data.reg_num,
                        sdi: data.sdi,
                        user_owner_id: data.user_owner_id,
                        annotation: data.annotation
                    }}
                    render={({ handleSubmit, hasValidationErrors }) => (
                        <form onSubmit={handleSubmit}>
                            <Box my={1}>
                                <Field name="id">
                                    {({ input, meta }) => (
                                        <TextField {...input} type="text" label="ID" disabled={true} />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="code">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMERS.FIELDS.code')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMERS.FIELDS.code')}
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="firstname">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            fullWidth
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMERS.FIELDS.firstname')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMERS.FIELDS.firstname')}
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="lastname">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            fullWidth
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMERS.FIELDS.lastname')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMERS.FIELDS.lastname')}
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="company">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            fullWidth
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMERS.FIELDS.company')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMERS.FIELDS.company')}
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="email_default">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            fullWidth
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMERS.FIELDS.email_default')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMERS.FIELDS.email_default')}
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="phone_default">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            fullWidth
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMERS.FIELDS.phone_default')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMERS.FIELDS.phone_default')}
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="tax_code">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            fullWidth
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMERS.FIELDS.tax_code')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMERS.FIELDS.tax_code')}
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="vat_code">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            fullWidth
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMERS.FIELDS.vat_code')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMERS.FIELDS.vat_code')}
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="sdi">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            fullWidth
                                            error={meta.error && meta.touched}
                                            label="SDI"
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder="SDI"
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="user_owner_id">
                                    {({ input, meta }) => (
                                        <FormControl fullWidth>
                                            <InputLabel>{t('CUSTOMERS.FIELDS.username')}</InputLabel>
                                            <Select {...input} error={meta.error && meta.touched}>
                                                {users.map((item) => (
                                                    <MenuItem key={item.id} value={Number(item.id)}>
                                                        {item.fullname}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                            </Box>
                            <Box my={1}>
                                <Field name="annotation">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            type="text"
                                            multiline
                                            rows={6}
                                            error={meta.error && meta.touched}
                                            label={t('CUSTOMERS.FIELDS.annotation')}
                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                            placeholder={t('CUSTOMERS.FIELDS.annotation')}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box mt={3} textAlign="right">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={hasValidationErrors}>
                                    {t('COMMON.SAVE')}
                                </Button>
                            </Box>
                        </form>
                    )}
                />
            </CardContent>
        </Card>
    );
};
