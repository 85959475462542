import React from 'react';
import { useCustomerCategoryListByCustomer } from '../api/useCustomerCategoryList';
import { useMutateCustomerCategory } from '../api/useMutateCustomerCategory';
import { useCustomerCategoryTypeList } from '../../customerCategoryType/api/useCustomerCategoryTypeList';
import Loading from '../../../components/loading/loading.component';
import { CustomerCategoryComponent } from './customerCategory.component';
import { ICustomerCategory } from '../interfaces/customerCategory';
import { ICustomerCategoryType } from '../../customerCategoryType/interfaces/customerCategoryType';

type Props = {
    customerId: string;
};

export const CustomerCategoryContainer = ({ customerId }: Props) => {
    const { create: createMutation, remove: removeMutation } = useMutateCustomerCategory(customerId);
    const { mutate: create } = createMutation;
    const { mutate: remove } = removeMutation;

    const {
        data: customerCategoryListData,
        isLoading: isCustomerCategoryListLoading,
        error: customerCategoryListError,
        isError: isCustomerCategoryListError
    } = useCustomerCategoryListByCustomer(customerId);

    const {
        data: categoryTypeListData,
        isLoading: isCategoryTypeListLoading,
        error: categoryTypeListError,
        isError: isCategoryTypeListError
    } = useCustomerCategoryTypeList();

    if (isCustomerCategoryListLoading || isCategoryTypeListLoading) {
        return <Loading />;
    }

    if (isCustomerCategoryListError) {
        console.error(customerCategoryListError);
        return <></>;
    }
    if (isCategoryTypeListError) {
        console.error(categoryTypeListError);
        return <></>;
    }

    const handleAddCategory = (data: ICustomerCategoryType[]) => {
        // TO DO create a bulk insert in the BE
        data.forEach((item) => {
            create({
                id: null,
                customer_id: parseInt(customerId),
                category_id: item.id
            });
        });
    };

    const handleRemoveCategory = (data: ICustomerCategory) => {
        remove(data);
    };

    return (
        <CustomerCategoryComponent
            data={customerCategoryListData}
            categoryTypeList={categoryTypeListData}
            onSave={handleAddCategory}
            onDelete={handleRemoveCategory}
        />
    );
};
