import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-pro';
import { makeStyles } from '@material-ui/core/styles';
import { IMPORT_COL_WIDTH } from '../config/constants';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 'bold'
    }
}));

export const useGridColumnBuilder = ({ arrayData, hasFirstRowHeader }) => {
    const { t } = useTranslation();

    const classes = useStyles();

    const columns: GridColDef[] = React.useMemo(() => {
        return arrayData
            ? arrayData[0].map((cell, index) => {
                  const colName = hasFirstRowHeader ? cell : t('IMPORT_FILES.COLUMN') + (index + 1);

                  return {
                      field: `col${index + 1}`,
                      headerName: colName,
                      headerClassName: classes.bold,
                      renderHeader: () => colName,
                      width: IMPORT_COL_WIDTH,
                      sortable: false,
                      filterable: false,
                      tabindex: index
                  };
              })
            : [];
    }, [hasFirstRowHeader, arrayData, t, classes]);

    return columns;
};
