import axios from '../../../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationState } from '../../../recoil/notification.atom';
import { ICustomerActivity } from '../interfaces/customerActivity';

export const useMutateActivity = (customerId?: string) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const setNotification = useSetRecoilState(notificationState);

    const create = useMutation<AxiosResponse, AxiosError, ICustomerActivity>(
        (data: ICustomerActivity) => axios.post(`customer-activity`, data),
        {
            onSuccess: async (res) => {
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('CUSTOMER_ACTIVITIES.CREATE_SUCCESS')
                });
                return res;
            },
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('CUSTOMER_ACTIVITIES.CREATE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_LIST);
                if (customerId) {
                    await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_ACTIVITY_LIST, customerId]);
                }
            }
        }
    );

    const update = useMutation<AxiosResponse, AxiosError, ICustomerActivity>(
        (data: ICustomerActivity) => axios.put(`customer-activity/${data.id}`, data),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('CUSTOMER_ACTIVITIES.UPDATE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('CUSTOMER_ACTIVITIES.UPDATE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_LIST);
                if (customerId) {
                    await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_ACTIVITY_LIST, customerId]);
                }
            }
        }
    );

    const remove = useMutation<AxiosResponse, AxiosError, ICustomerActivity>(
        (data: ICustomerActivity) => axios.delete(`customer-activity/${data.id}`),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('CUSTOMER_ACTIVITIES.DELETE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('CUSTOMER_ACTIVITIES.DELETE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_LIST);
                if (customerId) {
                    await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_ACTIVITY_LIST, customerId]);
                }
            }
        }
    );

    const setClosedStatus = useMutation<
        AxiosResponse,
        AxiosError,
        {
            id: number;
            is_closed: number;
        }
    >((data: { id: number; is_closed: number }) => axios.put(`customer-activity/closed/${data.id}`, data), {
        onSuccess: async (res) =>
            setNotification({
                open: true,
                severity: 'success',
                message: t('CUSTOMER_ACTIVITIES.UPDATE_SUCCESS')
            }),
        onError: (err) => {
            console.error(err.request.response);
            setNotification({ open: true, severity: 'error', message: t('CUSTOMER_ACTIVITIES.UPDATE_ERROR') });
        },
        onSettled: async () => {
            await queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_LIST);
            if (customerId) {
                await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_ACTIVITY_LIST, customerId]);
            }
        }
    });

    return { update, create, remove, setClosedStatus };
};
