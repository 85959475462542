import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Card, CardContent } from '@material-ui/core';
import { ROUTE_PATHS } from '../../../config/routes';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { RouterLinkComponent } from '../../../components/link/router-link.component';
import { ISalesRow } from '../interfaces/adesSaleTotYearCustomer';
import { quantity } from '../../../helpers';

type Props = {
    data: ISalesRow[];
    yearList: number[];
};

export const AdesSalesListComponent = ({ data, yearList }: Props) => {
    const { t } = useTranslation();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'adesSalesList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const yearColumns = React.useMemo(
        () =>
            yearList.map((year) => {
                return {
                    field: `qty_${year}`,
                    headerName: t(`ADES_SALES.FIELDS.year_ref`) + ' ' + year,
                    width: getColumnsWidth(`qty_${year}`, 130, columnWidth),
                    disableColumnMenu: true,
                    type: 'number',
                    renderCell: (params: GridCellParams) => {
                        return params.row[`qty_${year}`] ? (
                            <span>{quantity.format(params.row[`qty_${year}`])}</span>
                        ) : (
                            0
                        );
                    },
                    valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
                };
            }),
        [columnWidth, getColumnsWidth, t, yearList]
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                field: 'customer_code',
                headerName: t(`ADES_SALES.FIELDS.customer_code`),
                width: getColumnsWidth('customer_code', 150, columnWidth),
                hide: !isColumnsVisibile('customer_code', false, columnVisibility)
            },
            {
                field: 'customer_name',
                headerName: t(`ADES_SALES.FIELDS.customer_name`),
                width: getColumnsWidth('customer_name', 300, columnWidth),
                hide: !isColumnsVisibile('customer_name', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    if (params.row.customer_id) {
                        return (
                            <RouterLinkComponent to={`${ROUTE_PATHS.CUSTOMERS}/${params.row.customer_id}/sales`}>
                                {params.row.customer_name}
                            </RouterLinkComponent>
                        );
                    } else {
                        return <span>{params.row.customer_name}</span>;
                    }
                }
            },
            {
                field: 'mandator',
                headerName: t(`MANDATORS.FIELDS.mandator`),
                width: getColumnsWidth('mandator', 150, columnWidth),
                hide: !isColumnsVisibile('mandator', true, columnVisibility)
            },
            ...yearColumns
        ],
        [t, getColumnsWidth, columnWidth, isColumnsVisibile, columnVisibility, yearColumns]
    );

    return (
        <Card variant="outlined">
            <CardContent>
                <DataGridDefault
                    id={listId}
                    columns={columns}
                    data={data}
                    setColumnVisibility={setColumnVisibility}
                    setColumnWidth={setColumnWidth}
                />
            </CardContent>
        </Card>
    );
};
