import axios from '../../../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { ROUTE_PATHS } from '../../../config/routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationState } from '../../../recoil/notification.atom';
import { IEmployee } from '../interfaces/employee';
import { QUERY_KEYS } from '../../../queries/query.keys';

export const useMutateEmployee = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const history = useHistory();
    const setNotification = useSetRecoilState(notificationState);

    const update = useMutation<AxiosResponse, AxiosError, IEmployee>(
        (data: IEmployee) => axios.put(`employee/${data.id}`, data),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('EMPLOYEES.UPDATE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('EMPLOYEES.UPDATE_ERROR') });
            }
        }
    );

    const create = useMutation<AxiosResponse, AxiosError, IEmployee>(
        (data: IEmployee) => axios.post(`employee`, data),
        {
            onSuccess: async (res) => {
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('EMPLOYEES.CREATE_SUCCESS')
                });
                history.push(`${ROUTE_PATHS.EMPLOYEES}/${res.data}`);
                return res;
            },
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('EMPLOYEES.CREATE_ERROR') });
            }
        }
    );

    const remove = useMutation<AxiosResponse, AxiosError, IEmployee>(
        (data: IEmployee) => axios.delete(`employee/${data.id}`),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('EMPLOYEES.DELETE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('EMPLOYEES.DELETE_ERROR') });
            },
            onSettled: () => {
                queryClient.invalidateQueries(QUERY_KEYS.CUSTOMER_EMPLOYEE_LIST);
            }
        }
    );

    return {
        update,
        create,
        remove
    };
};
