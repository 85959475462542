import React from 'react';
import { currentUserState } from '../../../recoil/currentUser.atom';
import { useRecoilValue } from 'recoil';
import { useUserDetail } from '../api/useUserDetail';
import { useMutateUser } from '../api/useMutateUser';
import Loading from '../../../components/loading/loading.component';
import { IUser } from '../interfaces/user';
import { UserDetailComponent } from './userDetail.component';
import { USER_ROLES } from '../../../config/constants';

type Props = {
    userId: string;
};

export const UserDetailContainer = ({ userId }: Props) => {
    const currentUser = useRecoilValue(currentUserState);

    const isAdmin = React.useMemo(() => currentUser?.roles && currentUser?.roles[USER_ROLES.ADMIN], [currentUser]);

    const { data: userData, isLoading: isUserLoading, error: userError, isError: isUserError } = useUserDetail(userId);

    const { update: updateUserMutation, create: createUserMutation } = useMutateUser();
    const { mutate: updateUser } = updateUserMutation;
    const { mutate: createUser } = createUserMutation;

    if (isUserLoading) {
        return <Loading />;
    }

    if (isUserError) {
        console.error(userError);
        return <></>;
    }

    const handleUpdate = (data: IUser) => {
        updateUser(data);
    };

    const handleCreate = (data: IUser) => {
        createUser(data);
    };

    const handleSave = (data: IUser) => (data.id > 0 ? handleUpdate(data) : handleCreate(data));

    userData.is_active = userData.is_active?.toString() === '1' ? true : false;

    return <UserDetailComponent userData={userData} isAdmin={isAdmin} onSave={handleSave} />;
};
