import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { IMandator } from '../interfaces/mandator';

export const useMandatorDetail = (mandatorId: string) => {
    return useQuery<IMandator, AxiosError>([QUERY_KEYS.MANDATOR_DETAIL, mandatorId], () =>
        axios.get<IMandator>(`mandator/${mandatorId}`).then((response) => response.data)
    );
};
