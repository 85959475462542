import axios from '../../../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationState } from '../../../recoil/notification.atom';
import { ICustomerEmployee } from '../interfaces/customerEmployee';

export const useMutateCustomerEmployee = (employeeId: string) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const setNotification = useSetRecoilState(notificationState);

    const update = useMutation<AxiosResponse, AxiosError, ICustomerEmployee>(
        (data: ICustomerEmployee) => { console.log('Updating employee', data); return axios.put(`customer-employee/${data.id}`, data)},
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('EMPLOYEES.UPDATE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('EMPLOYEES.UPDATE_ERROR') });
            }
        }
    );

    const create = useMutation<AxiosResponse, AxiosError, ICustomerEmployee>(
        (data: ICustomerEmployee) => axios.post(`customer-employee`, data),
        {
            onSuccess: async (res) => {
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('EMPLOYEES.CREATE_SUCCESS')
                });
                return res;
            },
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('EMPLOYEES.CREATE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_EMPLOYEE_LIST_BY_EMPLOYEE, employeeId ?? 0]);
            }
        }
    );

    const remove = useMutation<AxiosResponse, AxiosError, number>(
        (id: number) => axios.delete(`customer-employee/${id}`),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('EMPLOYEES.DELETE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('EMPLOYEES.DELETE_ERROR') });
            }
        }
    );

    const setActiveStatus = useMutation<
        AxiosResponse,
        AxiosError,
        {
            id: number;
            is_active: number;
        }
    >((data: { id: number; is_active: number }) => axios.put(`customer-employee/active/${data.id}`, data), {
        onSuccess: async (res) =>
            setNotification({
                open: true,
                severity: 'success',
                message: t('EMPLOYEES.UPDATE_SUCCESS')
            }),
        onError: (err) => {
            console.error(err.request.response);
            setNotification({ open: true, severity: 'error', message: t('EMPLOYEES.UPDATE_ERROR') });
        }
    });

    return {
        update,
        create,
        remove,
        setActiveStatus
    };
};
