import axios from '../../../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ROUTE_PATHS } from '../../../config/routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationState } from '../../../recoil/notification.atom';
import { ICustomer } from '../interfaces/customer';

export const useMutateCustomer = (id?: string) => {
    const { t } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const setNotification = useSetRecoilState(notificationState);

    const update = useMutation<AxiosResponse, AxiosError, ICustomer>(
        (data: ICustomer) => axios.put(`customer/${id}`, data),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('CUSTOMERS.UPDATE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('CUSTOMERS.UPDATE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_DETAIL, id]);
            }
        }
    );

    const create = useMutation<AxiosResponse, AxiosError, ICustomer>(
        (data: ICustomer) => axios.post(`customer`, data),
        {
            onSuccess: async (res) => {
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('CUSTOMERS.CREATE_SUCCESS')
                });
                history.push(`${ROUTE_PATHS.CUSTOMERS}/${res.data}`);
                return res;
            },
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('CUSTOMERS.CREATE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.CUSTOMER_LIST);
            }
        }
    );

    const remove = useMutation<AxiosResponse, AxiosError, ICustomer>(
        (data: ICustomer) => axios.delete(`customer/${data.id}`),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('CUSTOMERS.DELETE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('CUSTOMERS.DELETE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.CUSTOMER_LIST);
            }
        }
    );

    return {
        update,
        create,
        remove
    };
};
