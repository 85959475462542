import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Button, TextField } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { RouterLinkComponent as Link } from '../link/router-link.component';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: grey[50]
    }
}));

interface Props {
    signUp: Function;
}

export const SignUpComponent = ({ signUp }: Props) => {
    const classes = useStyles();

    const [userCredentials, setUserCredentials] = useState({
        displayName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const { displayName, email, password, confirmPassword } = userCredentials;

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert("passwords don't match");
            return;
        }

        signUp({ displayName, email, password });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setUserCredentials({ ...userCredentials, [name]: value });
    };

    return (
        <Paper variant="outlined" className={classes.paper}>
            <Box p={3}>
                <form onSubmit={handleSubmit}>
                    <Box my={2}>
                        <TextField
                            name="displayName"
                            label="Your name"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Box>
                    <Box my={2}>
                        <TextField name="email" label="Email" variant="outlined" fullWidth onChange={handleChange} />
                    </Box>
                    <Box my={2}>
                        <TextField
                            type="password"
                            name="password"
                            label="Password"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Box>
                    <Box my={2}>
                        <TextField
                            type="password"
                            name="confirmPassword"
                            label="Confirm Password"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Box>
                    <Box my={2}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Register
                        </Button>
                    </Box>
                </form>
                <hr />
                <Box my={2} textAlign="center">
                    <Link to="/login">Already registered? Login</Link>
                </Box>
            </Box>
        </Paper>
    );
};
