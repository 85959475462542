import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Box } from '@material-ui/core';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

export class ErrorBoundaryComponent extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // process the error
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Box padding={3} textAlign="center">
                    <h1>Sorry this page is broken</h1>
                    <img src="https://i.imgur.com/yW2W9SC.png" alt="" />
                </Box>
            );
        }

        return this.props.children;
    }
}
