import { useTranslation } from 'react-i18next';
import { useSalesColumn } from '../adesSales/api/useSalesColumn';
import Loading from '../../components/loading/loading.component';
import { ImportFileCSV } from '../../components/file-importer/importFileCSV.component';

export const ImportFileSales = () => {
    const { t } = useTranslation();
    const { data: fieldsData, isLoading: fieldsAreLoading, error: fieldsError } = useSalesColumn();

    if (fieldsAreLoading) {
        return <Loading />;
    }

    if (fieldsError) {
        return <div></div>;
    }

    const fieldsDataTrans = fieldsData.map((item) => t(`ADES_SALES.FIELD_ALIAS.${item}`));

    return <ImportFileCSV fieldsData={fieldsData} fieldsDataTrans={fieldsDataTrans} importPath="ades-sales/import" showDelButton />;
};
