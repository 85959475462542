import axios from '../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { ILogger } from '../interfaces/logger';

export { LOG_LEVELS } from '../config/constants';

export const useLogger = () => {
    const createLogMutation = useMutation<AxiosResponse, AxiosError, ILogger>(
        (data: ILogger) => axios.post(`logger`, data),
        {
            onError: (err) => {
                console.error(err.request?.response);
            }
        }
    );
    const { mutateAsync } = createLogMutation;
    return mutateAsync;
};
