import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export type ColumnVisibility = {
    field: string;
    isVisible: boolean;
};

export type ColumnWidth = {
    field: string;
    width: number;
};

export type ColumnIndex = {
    field: string;
    oldIndex: number;
    targetIndex: number;
};

export type ListParams = {
    id: string;
    page?: number;
    pageSize?: number;
    filterModel?: GridFilterModel;
    sortModel?: GridSortModel;
    columnsVisibility?: ColumnVisibility[];
    columnsWidth?: ColumnWidth[];
    columnsIndex?: ColumnIndex[];
};

const initValue: ListParams[] = [];

export const listParamsState = atom<ListParams[]>({
    key: 'listParams',
    default: initValue,
    effects_UNSTABLE: [persistAtom]
});
