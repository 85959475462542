import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { grey, green } from '@material-ui/core/colors';
import { IMPORT_COL_WIDTH } from '../../config/constants';
import { Box } from '@material-ui/core';

const useStyles = (props: { canDrop: boolean; isOver: boolean }) =>
    makeStyles((theme: Theme) =>
        createStyles({
            dropZone: {
                width: `${IMPORT_COL_WIDTH}px`,
                padding: theme.spacing(1),
                margin: '0 2px',
                backgroundColor: 'white',
                textAlign: 'center',
                boxSizing: 'border-box',
                borderWidth: '1px',
                borderStyle: props.canDrop ? 'dashed' : 'dotted',
                borderColor: props.canDrop && props.isOver ? green[500] : grey[500]
            }
        })
    );

type Props = {
    id: number | string;
    name: string;
    dbFieldName: string;
    accept: string | symbol;
};

export const Dustbin = ({ id, name, dbFieldName, accept }: Props) => {
    const { t } = useTranslation();

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: accept,
        drop: () => ({ id, name }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }));

    const classes = useStyles({ canDrop, isOver })();

    const isActive = canDrop && isOver;

    const label = dbFieldName ? `${dbFieldName} > ${name}` : t('IMPORT_FILES.DUSTBIN.DRAG_HERE');

    return (
        <Box width={`${IMPORT_COL_WIDTH}px`} minWidth={`${IMPORT_COL_WIDTH}px`} pr="4px">
            <div ref={drop} className={classes.dropZone}>
                {isActive ? t('IMPORT_FILES.DUSTBIN.DROP_TO') + name : label}
            </div>
        </Box>
    );
};
