import { Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CustomerCategoryTypeDetailContainer } from './components/customerCategoryTypeDetail.container';

type UrlParams = {
    id: string;
};

export const CustomerCategoryTypeDetailPage = () => {
    const { id } = useParams<UrlParams>();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <CustomerCategoryTypeDetailContainer customerCategoryTypeId={id} />
            </Grid>
        </Grid>
    );
};
