import React from 'react';
import { useRecoilValue } from 'recoil';
import { useRelogin } from '../pages/signIn/api/useLogin';
import { currentUserState } from '../recoil/currentUser.atom';
import { useIdleTimer } from 'react-idle-timer';

export const useExpiration = () => {
    const currentUser = useRecoilValue(currentUserState);
    const isExpired = React.useRef(false);
    const { mutate: relogin } = useRelogin();

    const exp = parseInt(localStorage.getItem('exp') || '');

    function handleOnAction(): void {
        if (exp < Date.now()) {
            isExpired.current = true;
        } else {
            relogin(currentUser.email);
        }
    }

    useIdleTimer({
        timeout: 300000,
        events: ['keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'scroll'],
        onAction: handleOnAction,
        debounce: 60000
    });

    return isExpired.current;
};
