import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ICustomerEmployee } from '../interfaces/customerEmployee';

export const useCustomerEmployeeList = () => {
    return useQuery<ICustomerEmployee[], AxiosError>(QUERY_KEYS.CUSTOMER_EMPLOYEE_LIST, () =>
        axios.get<ICustomerEmployee[]>(`customer-employee`).then((response) => response.data)
    );
};

export const useCustomerEmployeeListByEmployee = (employeeId: string) => {
    return useQuery<ICustomerEmployee[], AxiosError>(
        [QUERY_KEYS.CUSTOMER_EMPLOYEE_LIST_BY_EMPLOYEE, employeeId ?? 0],
        () =>
            parseInt(employeeId) > 0
                ? axios
                      .get<ICustomerEmployee[]>(`customer-employee/employee/${employeeId}`)
                      .then((response) => response.data)
                : []
    );
};
