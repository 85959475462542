import { Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CustomerEmployeeListContainer } from './components/customerEmployeeList.container';
import { EmployeeDetailContainer } from './components/employeeDetail.container';

type UrlParams = {
    id: string;
};

export const EmployeeDetailPage = () => {
    const { id } = useParams<UrlParams>();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <EmployeeDetailContainer employeeId={id} />
            </Grid>
            <Grid item xs={12} md={9}>
                {id && id !== 'new' && <CustomerEmployeeListContainer employeeId={id} />}
            </Grid>
        </Grid>
    );
};
