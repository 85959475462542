import React from 'react';
import { GridRowsProp } from '@mui/x-data-grid-pro';

export const useGridRowBuilder = ({ arrayData, hasFirstRowHeader }) => {
    let rows: GridRowsProp = React.useMemo(() => {
        return arrayData
            ? arrayData.map((row, rowIndex: number) => {
                  const cellObj = {};
                  cellObj['id'] = rowIndex + 1;

                  row.forEach((cell, cellIndex: number) => {
                      cellObj[`col${cellIndex + 1}`] = cell;
                  });

                  return cellObj as GridRowsProp;
              })
            : [];
    }, [arrayData]);

    if (hasFirstRowHeader) {
        return rows.slice(1);
    }

    return rows;
};
