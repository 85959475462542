import React from 'react';
import { Box } from '@material-ui/core';
import { ActivityListContainer } from './components/activityList.container';

export const ActivityListPage = () => {
    return (
        <Box flexGrow="1" id="container">
            <ActivityListContainer />
        </Box>
    );
};
