import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, CardHeader, Checkbox, Chip, TextField, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ICustomerCategory } from '../interfaces/customerCategory';
import { ICustomerCategoryType } from '../../customerCategoryType/interfaces/customerCategoryType';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
    data: ICustomerCategory[];
    categoryTypeList: ICustomerCategoryType[];
    onSave: Function;
    onDelete: Function;
};

export const CustomerCategoryComponent = ({ data, categoryTypeList, onSave, onDelete }: Props) => {
    const { t } = useTranslation();

    const [categoryTypeListFiltered, setCategoryTypeListFiltered] = React.useState<ICustomerCategoryType[]>([]);
    const [categories, setCategories] = React.useState<ICustomerCategoryType[]>([]);

    const filterList = React.useCallback(() => {
        if (categoryTypeList.length) {
            const catFiltered = categoryTypeList.filter(
                (catType) => !data.some((cat) => Number(cat.category_id) === Number(catType.id))
            );
            setCategoryTypeListFiltered(catFiltered);
        }
    }, [categoryTypeList, data]);

    React.useEffect(() => filterList(), [filterList]);

    const handleDelete = (row: ICustomerCategory) => {
        if (window.confirm(t('CUSTOMER_CATEGORIES.CONF_DELETE'))) {
            onDelete(row);
        }
    };

    const handleSave = (data: ICustomerCategoryType[]) => {
        onSave(data);
        setCategories([]);
        filterList();
    };

    return (
        <Card variant="outlined">
            <CardHeader title={t('CUSTOMER_CATEGORIES.TITLE')} />
            <CardContent>
                <Box py={2} mb={2} borderBottom="1px solid #ccc" display="flex">
                    <Autocomplete
                        multiple
                        options={categoryTypeListFiltered}
                        disableCloseOnSelect
                        getOptionLabel={(option: ICustomerCategoryType) => option.category}
                        value={categories as ICustomerCategoryType[]}
                        renderOption={(option, { selected }) => (
                            <>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.category}
                            </>
                        )}
                        style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} label="Checkboxes" placeholder={t('CUSTOMER_CATEGORIES.TITLE')} />
                        )}
                        onChange={(e, values) => {
                            setCategories(values as ICustomerCategoryType[]);
                        }}
                    />
                    <Button variant="contained" color="primary" onClick={() => handleSave(categories)}>
                        {t('CUSTOMER_CATEGORIES.ASSIGN')}
                    </Button>
                </Box>
                <Box display="flex" flexWrap="wrap">
                    {data.map((item) => (
                        <Box key={item.id} pr={1} pb={1}>
                            <Chip
                                label={item.category}
                                color="secondary"
                                variant="outlined"
                                onDelete={() => handleDelete(item)}
                            />
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};
