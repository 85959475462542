import axios from '../../../helpers/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { ROUTE_PATHS } from '../../../config/routes';

interface Credentials {
    code: string;
    new_password: string;
    new_password_bis: string;
}

export const useResetPwd = () => {
    return useMutation<AxiosResponse<void>, AxiosError, Credentials, unknown>(
        ({ code, new_password, new_password_bis }: Credentials) =>
            axios.post('/reset-password', {
                code,
                new_password,
                new_password_bis
            }),
        {
            onError: (e) => {
                console.error('useResetPwd', e);
            },
            onSuccess: () => {
                window.location.href = ROUTE_PATHS.LOGIN;
            }
        }
    );
};
