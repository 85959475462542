import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUserState } from '../../../recoil/currentUser.atom';
import { notificationState } from '../../../recoil/notification.atom';
import { useActivityList } from '../api/useActivityList';
import { useMutateActivity } from '../api/useMutateActivity';
import { useMandatorList } from '../../mandator/api/useMandatorList';
import { useCustomerList } from '../../customer/api/useCustomerList';
import { useActivityTypeList } from '../../activityType/api/useActivityTypeList';
import { ActivityListComponent } from './activityList.component';
import { ICustomerActivity } from '../interfaces/customerActivity';
import Loading from '../../../components/loading/loading.component';

type Props = {
    customerId?: string;
    listHeight?: string;
};

export const ActivityListContainer = ({ customerId, listHeight }: Props) => {
    const { t } = useTranslation();
    const currentUser = useRecoilValue(currentUserState);
    const setNotification = useSetRecoilState(notificationState);

    const {
        update: updateActivityMutation,
        create: createActivityMutation,
        remove: removeActivityMutation,
        setClosedStatus: changeClosedStatusMutation
    } = useMutateActivity(customerId);

    const { mutateAsync: updateActivity } = updateActivityMutation;
    const { mutateAsync: createActivity } = createActivityMutation;
    const { mutateAsync: removeActivity } = removeActivityMutation;
    const { mutateAsync: changeClosedStatus } = changeClosedStatusMutation;

    const {
        data: activityListData,
        isLoading: isActivityListLoading,
        error: activityListError,
        isError: isActivityListError,
        refetch: refecthActivityList
    } = useActivityList(customerId);

    const {
        data: mandatorListData,
        isLoading: isMandatorListLoading,
        error: mandatorListError,
        isError: isMandatorListError
    } = useMandatorList();

    const {
        data: customerListData,
        isLoading: isCustomerListLoading,
        error: customerListError,
        isError: isCustomerListError
    } = useCustomerList();

    const {
        data: activityTypeListData,
        isLoading: isActivityTypeListLoading,
        error: activityTypeListError,
        isError: isActivityTypeListError
    } = useActivityTypeList();

    if (isActivityListLoading || isMandatorListLoading || isCustomerListLoading || isActivityTypeListLoading) {
        return <Loading />;
    }

    if (isActivityListError) {
        console.error(activityListError);
        return <></>;
    }
    if (isMandatorListError) {
        console.error(mandatorListError);
        return <></>;
    }
    if (isCustomerListError) {
        console.error(customerListError);
        return <></>;
    }
    if (isActivityTypeListError) {
        console.error(activityTypeListError);
        return <></>;
    }

    const handleUpdateActivity = (data: ICustomerActivity) => {
        if (Number(data.user_id) !== Number(currentUser.id)) {
            setNotification({
                open: true,
                severity: 'error',
                message: t('CUSTOMER_ACTIVITIES.UPDATE_OTHER_USER_ERROR')
            });
            return;
        }

        if (!data.customer_id && customerId) {
            data.customer_id = parseInt(customerId);
        }

        updateActivity(data).then(() => {
            refecthActivityList();
        });
    };

    const handleClosedStatus = (data: { id: number; is_closed: number; user_id: number }) => {
        if (Number(data.user_id) !== Number(currentUser.id)) {
            setNotification({
                open: true,
                severity: 'error',
                message: t('CUSTOMER_ACTIVITIES.UPDATE_OTHER_USER_ERROR')
            });
            return;
        }

        data.is_closed = +data.is_closed === 1 ? 0 : 1;

        changeClosedStatus(data).then(() => {
            refecthActivityList();
        });
    };

    const handleCreateActivity = (data: ICustomerActivity) => {
        if (!data.customer_id && customerId) {
            data.customer_id = parseInt(customerId);
        }
        data.user_id = currentUser.id;

        createActivity(data).then(() => {
            refecthActivityList();
        });
    };
    const handleRemoveActivity = (data: ICustomerActivity) => {
        if (Number(data.user_id) !== Number(currentUser.id)) {
            setNotification({
                open: true,
                severity: 'error',
                message: t('CUSTOMER_ACTIVITIES.DELETE_OTHER_USER_ERROR')
            });
            return;
        }
        removeActivity(data).then(() => {
            refecthActivityList();
        });
    };

    const handleSaveActivity = (data: ICustomerActivity) =>
        data.id > 0 ? handleUpdateActivity(data) : handleCreateActivity(data);

    activityListData.forEach((item) => {
        // item['created_at_text'] = new Date(item.created_at).toLocaleString(i18n.language);
        item['created_at_date'] = new Date(item.created_at);
    });

    return (
        <ActivityListComponent
            data={activityListData}
            customerId={customerId}
            mandatorList={mandatorListData}
            customerList={customerListData}
            activityTypeList={activityTypeListData}
            listHeight={listHeight}
            onSave={handleSaveActivity}
            onClosedStatus={handleClosedStatus}
            onDelete={handleRemoveActivity}
        />
    );
};
