import { useTranslation } from 'react-i18next';
import { useCustomerColumn } from '../customer/api/useCustomerColumn';
import { LoadingComponent as Loader } from '../../components/loading/fullscreen_loading.component';
import { ImportFileCSV } from '../../components/file-importer/importFileCSV.component';

export const ImportFileCustomer = () => {
    const { t } = useTranslation();
    const { data: fieldsData, isLoading: fieldsAreLoading, error: fieldsError } = useCustomerColumn();

    if (fieldsAreLoading) {
        return <Loader show={true} />;
    }

    if (fieldsError) {
        console.error({ fieldsError });
    }

    const fieldsDataTrans = fieldsData.map((item) => t(`CUSTOMERS.FIELD_ALIAS.${item}`));

    return <ImportFileCSV fieldsData={fieldsData} fieldsDataTrans={fieldsDataTrans} importPath="customer/import" />;
};
