import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from '../../config/routes';
import { LOG_LEVELS, useLogger } from '../../hooks/useLogger';

interface Props {
    component: any;
    layout: any;
    isLoggedIn: boolean;
    restricted?: boolean;
    exact?: boolean;
    path: string;
}

export const PrivateRouteComponent = ({
    component: Component,
    layout: Layout,
    isLoggedIn,
    restricted,
    ...rest
}: Props) => {
    const  location  = useLocation();
    const logger = useLogger();

    React.useEffect(() => {
        logger({
            level: LOG_LEVELS.INFO,
            message: `User navigate to ${location.pathname}`,
            user_date: new Date().toLocaleString()
        });
    }, [location.pathname, logger]);

    if (!isLoggedIn) {
        return <Route {...rest} render={() => <Redirect to={ROUTE_PATHS.LOGIN} />} />;
    }

    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout {...props}>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};
