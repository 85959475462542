import { useHistory } from 'react-router-dom';
import { useMandatorDetail } from '../api/useMandatorDetail';
import { useMutateMandator } from '../api/useMutateMandator';
import Loading from '../../../components/loading/loading.component';
import { IMandator } from '../interfaces/mandator';
import { MandatorDetailComponent } from './mandatorDetail.component';
import { ROUTE_PATHS } from '../../../config/routes';


type Props = {
    mandatorId: string;
};

export const MandatorDetailContainer = ({ mandatorId }: Props) => {
    const history = useHistory();
    
    const {
        data: mandatorData,
        isLoading: isMandatorLoading,
        error: mandatorError,
        isError: isMandatorError
    } = useMandatorDetail(mandatorId);

    const { update: updateMandatorMutation, create: createMandatorMutation } = useMutateMandator();
    const { mutateAsync: updateMandator } = updateMandatorMutation;
    const { mutateAsync: createMandator } = createMandatorMutation;

    if (isMandatorLoading) {
        return <Loading />;
    }

    if (isMandatorError) {
        console.error(mandatorError);
        return <></>;
    }

    const handleUpdate = (data: IMandator) => {
        updateMandator(data);
    };

    const handleCreate = async (data: IMandator) => {
        const res = await createMandator(data);

        history.push(`${ROUTE_PATHS.MANDATORS}/${res?.data}`);
    };

    const handleSave = (data: IMandator) => (data.id > 0 ? handleUpdate(data) : handleCreate(data));

    return <MandatorDetailComponent mandatorData={mandatorData} onSave={handleSave} />;
};
