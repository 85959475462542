import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import { Card, CardContent, IconButton, makeStyles } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { IProduct } from '../interfaces/product';
import { ROUTE_PATHS } from '../../../config/routes';
import { DataGridDefault } from '../../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../../recoil/listParams.atom';
import { useColumnVisibility } from '../../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../../hooks/useColumnWidth';
import { currency, quantity } from '../../../helpers';
import { RouterLinkComponent } from '../../../components/link/router-link.component';

const useStyles = makeStyles({
    bgLightGreen: {
        backgroundColor: 'rgba(145, 250, 229, 0.8)'
    },
    bgViolet: {
        backgroundColor: 'rgba(187, 179, 227, 0.8)'
    }
});

type Props = {
    data: IProduct[];
    onDelete: Function;
};

export const ProductListComponent = ({ data, onDelete }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const classes = useStyles();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'productList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        history.push(`${ROUTE_PATHS.PRODUCTS}/${params.row.id}`);
    };

    const handleAddNewClick = () => {
        history.push(`${ROUTE_PATHS.PRODUCTS}/new`);
    };
    const handleDelete = React.useCallback(
        (row: any) => () => {
            if (window.confirm(t('PRODUCTS.CONF_DELETE'))) {
                onDelete(row);
            }
        },
        [t, onDelete]
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                field: 'mandator',
                headerName: t(`PRODUCTS.FIELDS.mandator`),
                width: getColumnsWidth('mandator', 150, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('mandator', true, columnVisibility)
            },
            {
                field: 'mandator_product_code',
                headerName: t(`PRODUCTS.FIELDS.mandator_product_code`),
                width: getColumnsWidth('mandator_product_code', 200, columnWidth),
                hide: !isColumnsVisibile('mandator_product_code', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return (
                        <RouterLinkComponent to={`${ROUTE_PATHS.PRODUCTS}/${params.row.id}`}>
                            {params.row.mandator_product_code}
                        </RouterLinkComponent>
                    );
                }
            },
            {
                field: 'product_name',
                headerName: t(`PRODUCTS.FIELDS.product_name`),
                width: getColumnsWidth('product_name', 490, columnWidth),
                hide: !isColumnsVisibile('product_name', true, columnVisibility)
            },
            {
                field: 'manufacturer_product_code',
                headerName: t(`PRODUCTS.FIELDS.manufacturer_product_code`),
                width: getColumnsWidth('manufacturer_product_code', 200, columnWidth),
                hide: !isColumnsVisibile('manufacturer_product_code', true, columnVisibility)
            },
            {
                field: 'purchase_price',
                headerName: t(`PRODUCTS.FIELDS.purchase_price`),
                width: getColumnsWidth('purchase_price', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('purchase_price', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.purchase_price)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'special_price',
                headerName: t(`PRODUCTS.FIELDS.special_price`),
                width: getColumnsWidth('special_price', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('special_price', true, columnVisibility),
                cellClassName: classes.bgViolet,
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.special_price)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'last_purchase_price',
                headerName: t(`PRODUCTS.FIELDS.last_purchase_price`),
                width: getColumnsWidth('last_purchase_price', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('last_purchase_price', true, columnVisibility),
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'price_default',
                headerName: t(`PRODUCTS.FIELDS.price_default`),
                width: getColumnsWidth('price_default', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('price_default', true, columnVisibility),
                cellClassName: classes.bgLightGreen,
                renderCell: (params: GridCellParams) => {
                    return <span>{currency.format(params.row.price_default)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'stock',
                headerName: t(`PRODUCTS.FIELDS.stock`),
                width: getColumnsWidth('stock', 150, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('stock', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{quantity.format(params.row.stock)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'available',
                headerName: t(`PRODUCTS.FIELDS.available`),
                width: getColumnsWidth('available', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('available', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{quantity.format(params.row.available)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'ordered',
                headerName: t(`PRODUCTS.FIELDS.ordered`),
                width: getColumnsWidth('ordered', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('ordered', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{quantity.format(params.row.ordered)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'committed',
                headerName: t(`PRODUCTS.FIELDS.committed`),
                width: getColumnsWidth('committed', 130, columnWidth),
                type: 'number',
                disableColumnMenu: true,
                hide: !isColumnsVisibile('committed', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return <span>{quantity.format(params.row.committed)}</span>;
                },
                valueFormatter: ({ value }) => (value ? value.toString().replace('.', ',') : 0)
            },
            {
                field: 'delete',
                headerName: t(`COMMON.DELETE`),
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                width: getColumnsWidth('delete', 130, columnWidth),
                hide: !isColumnsVisibile('delete', false, columnVisibility),
                align: 'center',
                headerAlign: 'center',
                renderCell: (params: GridCellParams) => (
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            handleDelete(params.row);
                        }}>
                        <DeleteForeverIcon />
                    </IconButton>
                )
            },
            {
                field: 'id',
                headerName: t('PRODUCTS.FIELDS.id'),
                width: getColumnsWidth('id', 60, columnWidth),
                disableColumnMenu: true,
                filterable: false,
                sortable: false,
                hide: !isColumnsVisibile('id', false, columnVisibility)
            }
        ],
        [
            t,
            getColumnsWidth,
            columnWidth,
            isColumnsVisibile,
            columnVisibility,
            classes.bgViolet,
            classes.bgLightGreen,
            handleDelete
        ]
    );

    return (
        <Card variant="outlined">
            <CardContent>
                <DataGridDefault
                    id={listId}
                    columns={columns}
                    data={data}
                    handleAddNewClick={handleAddNewClick}
                    handleRowDoubleClick={handleRowDoubleClick}
                    setColumnVisibility={setColumnVisibility}
                    setColumnWidth={setColumnWidth}
                />
            </CardContent>
        </Card>
    );
};
