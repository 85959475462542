import { useResetRecoilState } from 'recoil';
import { currentUserState } from '../recoil/currentUser.atom';
import { listParamsState } from '../recoil/listParams.atom';
import { notificationState } from '../recoil/notification.atom';
import { useLogger, LOG_LEVELS } from './useLogger';

export const useLogout = () => {
    const resetUser = useResetRecoilState(currentUserState);
    const resetLists = useResetRecoilState(listParamsState);
    const resetNotifications = useResetRecoilState(notificationState);

    const logger = useLogger();

    return (logMe = false) => {
        if (logMe) {
            logger({ level: LOG_LEVELS.INFO, message: `User logged out`, user_date: new Date().toLocaleString() });
        }
        resetUser();
        resetLists();
        resetNotifications();
        localStorage.clear();
    };
};
