import React from 'react';
import { useTranslation } from 'react-i18next';
import { useImportAdes } from './api/useImportAdes';
import {
    Container,
    Box,
    TextField,
    FormControlLabel,
    Button,
    Card,
    CardContent,
    CardActions,
    Divider
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingComponent as Loader } from '../../components/loading/fullscreen_loading.component';

const useStyles = makeStyles({
    root: {
        width: '60%',
        minWidth: 800
    }
});

interface Props {
    props?: any;
}

type ImportFilesType = {
    artascii: File;
    cfasci: File;
    dispon_g: File;
    lisdet: File;
    lispec: File;
    stoascii: File;
    p_quote: File;
};

const filesList = ['artascii', 'cfasci', 'dispon_g', 'lisdet', 'lispec', 'stoascii', 'p_quote'];

export const ImportFileAdes = ({ ...props }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { mutateAsync, isLoading: isImportLoading, data: importData } = useImportAdes();

    const [yearRef, setYearRef] = React.useState<number>(new Date().getFullYear());
    const [uploadFiles, setUploadFiles] = React.useState<ImportFilesType>();

    const onYearRefChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            setYearRef(parseInt(e.target.value));
        }
    };

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data = { ...uploadFiles };

        if (e?.target?.files[0]) {
            data[e?.target?.name] = e?.target?.files[0];
            setUploadFiles(data);
        }
    };

    const handleImport = () => {
        const formData = new FormData();

        formData.append('yearRef', yearRef.toString());

        filesList.forEach((fileName) => {
            if (uploadFiles[fileName]) {
                formData.append(fileName, uploadFiles[fileName], fileName + '.csv');
            }
        });

        // Request made to the backend api
        // Send formData object
        mutateAsync(formData).then(() => setUploadFiles(null));
    };

    if (importData && importData.data) {
        for (const property in importData.data) {
            if (importData.data[property].err_rows.length > 0) {
                console.error(importData.data[property]);
            }
        }
    }

    return (
        <Container maxWidth={false}>
            <Card className={classes.root}>
                <CardContent>
                    <Box m={2}>
                        <TextField
                            type="number"
                            id="yearRef"
                            label={t('IMPORT_FILES.YEAR_REF')}
                            value={yearRef}
                            onChange={onYearRefChange}
                        />
                    </Box>
                    <Divider />
                    {filesList.map((fileName) => (
                        <Box key={fileName}>
                            <Box m={2} display="flex">
                                <Box width={350} display="flex" alignItems="center">
                                    <FormControlLabel
                                        control={
                                            <input
                                                key={fileName}
                                                type="file"
                                                id={fileName}
                                                name={fileName}
                                                onChange={onFileChange}
                                            />
                                        }
                                        label={fileName}
                                    />
                                </Box>
                                <Box flexGrow="1">
                                    {importData?.data[fileName] && (
                                        <>
                                            <Alert severity="success">
                                                {importData?.data[fileName]?.inserted > 0 && (
                                                    <Box component="span" paddingRight={2}>
                                                        {t('IMPORT_FILES.INSERTED_ROWS', {
                                                            num: importData?.data[fileName].inserted
                                                        })}
                                                    </Box>
                                                )}
                                                {importData?.data[fileName]?.updated > 0 && (
                                                    <Box component="span">
                                                        {t('IMPORT_FILES.UPDATED_ROWS', {
                                                            num: importData?.data[fileName].updated
                                                        })}
                                                    </Box>
                                                )}
                                            </Alert>
                                            {importData.data[fileName].err_rows?.length > 0 && (
                                                <Alert severity="error">
                                                    <Box component="span">
                                                        {t('IMPORT_FILES.ERR_ROWS', {
                                                            num: importData?.data[fileName].err_rows?.length
                                                        })}
                                                    </Box>
                                                </Alert>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </Box>
                            <Divider key={`divider-${fileName}`} />
                        </Box>
                    ))}
                </CardContent>
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Box marginRight={2} marginBottom={2}>
                        <Button color="primary" variant="contained" size="large" onClick={handleImport}>
                            Import
                        </Button>
                    </Box>
                </CardActions>
            </Card>
            {isImportLoading && <Loader show={true} />}
        </Container>
    );
};
