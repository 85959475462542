import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const sideMenuOpenedState = atom<string>({
    key: 'sideMenuOpened',
    default: '',
    effects_UNSTABLE: [persistAtom]
});
