import axios from '../../../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationState } from '../../../recoil/notification.atom';

export const useBulkDelete = (yearRef: number, mandatorId: number) => {
    const { t } = useTranslation();
    const setNotification = useSetRecoilState(notificationState);

    return useMutation<AxiosResponse, AxiosError>(
        () => axios.delete(`ades-sales/bulk-delete/${yearRef}/${mandatorId}`),
        {
            onSuccess: async () =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('IMPORT_FILES.FILE_UPLOADER.DELETE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: err.message });
            }
        }
    );
};
