import axios from '../../../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationState } from '../../../recoil/notification.atom';
import { ICustomerMandator } from '../interfaces/customerMandator';

export const useMutateCustomerMandator = (customerId: string) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const setNotification = useSetRecoilState(notificationState);

    const create = useMutation<AxiosResponse, AxiosError, ICustomerMandator>(
        (data: ICustomerMandator) => axios.post(`customer-mandator`, data),
        {
            onSuccess: async (res) => {
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('CUSTOMER_MANDATORS.CREATE_SUCCESS')
                });
                return res;
            },
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('CUSTOMER_MANDATORS.CREATE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_MANDATOR_LIST, customerId]);
            }
        }
    );

    const update = useMutation<AxiosResponse, AxiosError, ICustomerMandator>(
        (data: ICustomerMandator) => axios.put(`customer-mandator/${data.id}`, data),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('CUSTOMER_MANDATORS.UPDATE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('CUSTOMER_MANDATORS.UPDATE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_MANDATOR_LIST, customerId]);
            }
        }
    );

    const remove = useMutation<AxiosResponse, AxiosError, ICustomerMandator>(
        (data: ICustomerMandator) => axios.delete(`customer-mandator/${data.id}`),
        {
            onSuccess: async (res) =>
                setNotification({
                    open: true,
                    severity: 'success',
                    message: t('CUSTOMER_MANDATORS.DELETE_SUCCESS')
                }),
            onError: (err) => {
                console.error(err.request.response);
                setNotification({ open: true, severity: 'error', message: t('CUSTOMER_MANDATORS.DELETE_ERROR') });
            },
            onSettled: async () => {
                await queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_MANDATOR_LIST, customerId]);
            }
        }
    );

    return { update, create, remove };
};
