import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

import NotificationIcon from '@material-ui/icons/Notifications';

interface Props {
    BadgeContent: ReactNode;
    props?: any
}

export const NotificationIconComponent = ({ BadgeContent, ...props }: Props) => {
    const { t } = useTranslation();
    return (
        <IconButton color="inherit" {...props} title={t('ACCOUNT_MENU.NOTIFICATIONS')}>
            <Badge badgeContent={BadgeContent} color="secondary">
                <NotificationIcon />
            </Badge>
        </IconButton>
    );
};
