import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { sideMenuOpenedState } from '../../recoil/sideMenu.atom';
import { useTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import DefaultIcon from '@material-ui/icons/LensOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { RouteConfig } from './sideMenu.component';
import { RouterLinkComponent } from '../link/router-link.component';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            paddingLeft: theme.spacing(4)
        }
    })
);

interface Props {
    item: RouteConfig;
}

export const SideMenuItem = ({ item }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();

    const [opened, setOpened] = useRecoilState(sideMenuOpenedState);

    const toggleSubMenu = (url: string) => (url === opened ? setOpened('') : setOpened(url));

    const Icon = item.Icon ?? DefaultIcon;

    return (
        <>
            {item.subItems && (
                <ListItem
                    button
                    key={item.title}
                    onClick={() => toggleSubMenu(item.url)}
                    selected={item.url?.length > 0 && location.pathname.startsWith(item.url)}>
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                    <ListItemText primary={t(item.title)} />
                    {item.subItems && (opened === item.url ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
            )}
            {!item.subItems && (
                <RouterLinkComponent key={item.title} to={item.url}>
                    <ListItem button selected={item.url?.length > 0 && location.pathname.startsWith(item.url)}>
                        <ListItemIcon>
                            <Icon />
                        </ListItemIcon>
                        <ListItemText primary={t(item.title)} />
                        {item.subItems && (opened === item.url ? <ExpandLess /> : <ExpandMore />)}
                    </ListItem>
                </RouterLinkComponent>
            )}

            {item.subItems && (
                <Collapse in={opened === item.url} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {item.subItems.map((subItem, subIndex) => {
                            const SubIcon = subItem.Icon;
                            return (
                                <RouterLinkComponent key={subIndex + subItem.title} to={item.url + subItem.url}>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        // onClick={() => navigate(item.url + subItem.url)}
                                        selected={location.pathname.endsWith(item.url + subItem.url)}>
                                        <ListItemIcon>
                                            <SubIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t(subItem.title)} />
                                    </ListItem>
                                </RouterLinkComponent>
                            );
                        })}
                    </List>
                </Collapse>
            )}
        </>
    );
};
