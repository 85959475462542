import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ICustomer } from '../interfaces/customer';

export const useCustomerDetail = (id: string) => {
    return useQuery<ICustomer, AxiosError>([QUERY_KEYS.CUSTOMER_DETAIL, id], () =>
        axios.get<ICustomer>(`customer/${id}`).then((response) => response.data)
    );
};
