import axios from '../../../helpers/axios';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../queries/query.keys';
import { ICustomerActivityList } from '../interfaces/customerActivityList';

export const useActivityList = (customerId?: string) => {
    return useQuery<ICustomerActivityList[], AxiosError>([QUERY_KEYS.CUSTOMER_ACTIVITY_LIST, customerId ?? 0], () =>
        customerId && parseInt(customerId) > 0
            ? axios
                  .get<ICustomerActivityList[]>(`customer-activity/customer/${customerId}`)
                  .then((response) => response.data)
            : axios.get<ICustomerActivityList[]>(`customer-activity`).then((response) => response.data)
    );
};
