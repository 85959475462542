import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCustomerDetail } from './api/useCustomerDetail';
import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import { CustomerDetailsContainer } from './components/customerDetails.container';
import { CustomerMandatorListContainer } from './components/customerMandatorList.container';
import { CustomerCategoryContainer } from './components/customerCategory.container';
import { CustomerAddressListContainer } from './components/customerAddressList.container';
import { CustomerContactListContainer } from './components/customerContactList.container';
import { CustomerEmployeeListContainer } from './components/customerEmployeeList.container';
import { ActivityListContainer } from '../activity/components/activityList.container';
import { CustomerAdesSalesListContainer } from './components/customerAdesSalesList.container';
import { BackToList } from '../../components/back-to-list/back-to-list';
import { ROUTE_PATHS } from '../../config/routes';
import Loading from '../../components/loading/loading.component';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`customer-tabpanel-${index}`}
            aria-labelledby={`customer-tab-${index}`}
            {...other}>
            {value === index && <Box mt={2}>{children}</Box>}
        </div>
    );
};

type UrlParams = {
    id: string;
    tab?: string;
};

export const CustomerDetailPage = () => {
    const { t } = useTranslation();
    const { id, tab } = useParams<UrlParams>();
    const [value, setValue] = React.useState(0);

    const { data: customerData, isLoading, error, isError } = useCustomerDetail(id);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'employees':
                    setValue(1);
                    break;
                case 'activities':
                    setValue(2);
                    break;
                case 'sales':
                    setValue(3);
                    break;
                default:
                    setValue(0);
            }
        }
    }, [tab]);

    if (isError) {
        console.error(error);
        return <></>;
    }

    return isLoading ? (
        <Loading />
    ) : (
        <Box flexGrow="1" id="container">
            <BackToList path={ROUTE_PATHS.CUSTOMERS} />
            <Typography variant="h4">{customerData?.company}</Typography>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label={t('CUSTOMERS.TABS.DETAILS')} />
                    <Tab label={t('CUSTOMERS.TABS.EMPLOYEES')} />
                    <Tab label={t('CUSTOMERS.TABS.ACTIVITIES')} />
                    <Tab label={t('CUSTOMERS.TABS.SALES')} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Box display="flex">
                    <Box width="50%" p={2}>
                        <CustomerDetailsContainer customerId={id} />
                    </Box>
                    <Box width="50%" p={2}>
                        {parseInt(id) > 0 && (
                            <>
                                <CustomerMandatorListContainer customerId={id} />
                                <CustomerCategoryContainer customerId={id} />
                            </>
                        )}
                    </Box>
                </Box>
                <Box p={2} mb={4}>
                    <CustomerAddressListContainer customerId={id} />
                    <CustomerContactListContainer customerId={id} />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                {parseInt(id) > 0 && <CustomerEmployeeListContainer customerId={id} />}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {parseInt(id) > 0 && <ActivityListContainer customerId={id} listHeight="60vh" />}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {parseInt(id) > 0 && <CustomerAdesSalesListContainer customerId={id} />}
            </TabPanel>
        </Box>
    );
};
