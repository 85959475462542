import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCustomerList } from './api/useCustomerList';
import { useMutateCustomer } from './api/useMutateCustomer';
import { useCustomerMandatorList } from './api/useCustomerMandatorList';
import { useCustomerAddressList } from './api/useCustomerAddressList';
import { useCustomerCategoryList } from './api/useCustomerCategoryList';
import { useColumnVisibility } from '../../hooks/useColumnVisbility';
import { useColumnWidth } from '../../hooks/useColumnWidth';
import { GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import { Box, Card, CardContent, IconButton } from '@material-ui/core';
import { ADDRESS_TYPE, CONTACT_TYPE } from '../../config/constants';
import { ROUTE_PATHS } from '../../config/routes';
import { useCustomerContactList } from './api/useCustomerContactList';
import { DataGridDefault } from '../../components/dataGrid/dataGridDefault';
import { ColumnVisibility, ColumnWidth } from '../../recoil/listParams.atom';
import { RouterLinkComponent } from '../../components/link/router-link.component';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ICustomer } from './interfaces/customer';
import Loading from '../../components/loading/loading.component';

export const CustomerListPage = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [columnVisibility, setColumnVisibility] = React.useState<ColumnVisibility[]>();
    const [columnWidth, setColumnWidth] = React.useState<ColumnWidth[]>();

    const listId = 'customerList';

    const isColumnsVisibile = useColumnVisibility();
    const getColumnsWidth = useColumnWidth();

    const { data, isLoading, error, isError, refetch: refetchCustomerList } = useCustomerList();
    const { remove: removeCustomerMutation } = useMutateCustomer();
    const { mutateAsync: removeCustomer } = removeCustomerMutation;

    const {
        data: customerMandatorListData,
        isLoading: isCustomerMandatorListLoading,
        error: customerMandatorListError,
        isError: isCustomerMandatorListError
    } = useCustomerMandatorList();

    const {
        data: customerAddressListData,
        isLoading: isCustomerAddressListLoading,
        error: customerAddressListError,
        isError: isCustomerAddressListError
    } = useCustomerAddressList();

    const {
        data: customerContactListData,
        isLoading: isCustomerContactListLoading,
        error: customerContactListError,
        isError: isCustomerContactListError
    } = useCustomerContactList();

    const {
        data: customerCategoryListData,
        isLoading: isCustomerCategoryListLoading,
        error: customerCategoryListError,
        isError: isCustomerCategoryListError
    } = useCustomerCategoryList();

    const isLoadingAll =
        isLoading ||
        isCustomerMandatorListLoading ||
        isCustomerAddressListLoading ||
        isCustomerContactListLoading ||
        isCustomerCategoryListLoading;

    const userArray = useMemo<string[]>(() => [], []);

    if (!isLoadingAll) {
        const mandatorArray = [];
        
        data.forEach((customer) => {
            /** mandator */
            const mandators = customerMandatorListData.filter(
                (mandItem) => mandItem.customer_id === customer.id && mandItem.mandator_code !== ''
            );

            customer['mandator'] = '';

            mandators.forEach((mandItem) => {
                customer['mandator'] += `${mandItem.mandator}, `;
            });

            customer['mandator'] = customer['mandator'].slice(0, -2);

            /** address */
            const address = customerAddressListData.filter(
                (address) =>
                    address.customer_id === customer.id && address.address_type === ADDRESS_TYPE.REGISTERED_OFFICE
            )[0];

            customer['address'] = address?.address;
            customer['zip'] = address?.zip;
            customer['province'] = address?.province;
            customer['city'] = address?.city;
            customer['country_iso2'] = address?.country_iso2;

            /** contact */
            const contacts = customerContactListData?.find(
                (contact) => contact.customer_id === customer.id && contact.contact_type === CONTACT_TYPE.TEL
            );

            customer['tel'] = contacts?.contact_value;

            /** categories */
            customer['categories'] = customerCategoryListData
                .filter((category) => category.customer_id === customer.id)
                .map((customer) => customer.category)
                .join(', ');

            /** user list */
            if (!customer.username_short) {
                customer.username_short = '--';
            }

            if (customer.username_short && !userArray.includes(customer.username_short)) {
                userArray.push(customer.username_short);
            }
        });

        mandatorArray.sort();
        userArray.sort();
    }

    const handleRowDoubleClick = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
        history.push(`${ROUTE_PATHS.CUSTOMERS}/${params.row.id}/sales`);
    };

    const handleDelete = React.useCallback(
        (row: ICustomer) => {
            if (window.confirm(t('CUSTOMERS.CONF_DELETE'))) {
                removeCustomer(row).then(() => refetchCustomerList());
            }
        },
        [refetchCustomerList, removeCustomer, t]
    );

    const columns: GridColDef[] = React.useMemo(
        () => [
            {
                field: 'company',
                headerName: t('CUSTOMERS.FIELDS.company'),
                width: getColumnsWidth('company', 350, columnWidth),
                hide: !isColumnsVisibile('company', true, columnVisibility),
                renderCell: (params: GridCellParams) => {
                    return (
                        <RouterLinkComponent to={`${ROUTE_PATHS.CUSTOMERS}/${params.row.id}`}>
                            {params.row.company}
                        </RouterLinkComponent>
                    );
                }
            },

            {
                field: 'categories',
                headerName: t('CUSTOMERS.FIELDS.categories'),
                width: getColumnsWidth('categories', 350, columnWidth),
                hide: !isColumnsVisibile('categories', true, columnVisibility)
            },
            {
                field: 'address',
                headerName: t('CUSTOMER_ADDRESSES.FIELDS.address'),
                width: getColumnsWidth('address', 200, columnWidth),
                hide: !isColumnsVisibile('address', false, columnVisibility)
            },
            {
                field: 'zip',
                headerName: t('CUSTOMER_ADDRESSES.FIELDS.zip'),
                width: getColumnsWidth('address', 80, columnWidth),
                hide: !isColumnsVisibile('address', false, columnVisibility)
            },
            {
                field: 'city',
                headerName: t('CUSTOMER_ADDRESSES.FIELDS.city'),
                width: getColumnsWidth('city', 200, columnWidth),
                hide: !isColumnsVisibile('city', true, columnVisibility)
            },
            {
                field: 'province',
                headerName: t('CUSTOMER_ADDRESSES.FIELDS.province'),
                width: getColumnsWidth('province', 100, columnWidth),
                hide: !isColumnsVisibile('province', true, columnVisibility)
            },
            {
                field: 'country_iso2',
                headerName: t('CUSTOMERS.FIELDS.nationality'),
                width: getColumnsWidth('country_iso2', 100, columnWidth),
                hide: !isColumnsVisibile('country_iso2', true, columnVisibility)
            },
            {
                field: 'vat_code',
                headerName: 'P.IVA',
                width: getColumnsWidth('vat_code', 130, columnWidth),
                hide: !isColumnsVisibile('vat_code', true, columnVisibility)
            },
            {
                field: 'sdi',
                headerName: 'SDI',
                width: getColumnsWidth('sdi', 100, columnWidth),
                hide: !isColumnsVisibile('sdi', true, columnVisibility)
            },
            {
                field: 'tel',
                headerName: t('CONTACT_TYPES.TEL'),
                width: getColumnsWidth('tel', 100, columnWidth),
                hide: !isColumnsVisibile('tel', true, columnVisibility)
            },
            {
                field: 'mandator',
                headerName: t('MANDATORS.FIELDS.mandator'),
                width: getColumnsWidth('mandator', 150, columnWidth),
                hide: !isColumnsVisibile('mandator', true, columnVisibility)
            },
            {
                field: 'username_short',
                headerName: t('CUSTOMERS.FIELDS.username'),
                width: getColumnsWidth('username_short', 100, columnWidth),
                hide: !isColumnsVisibile('username_short', true, columnVisibility),
                type: 'singleSelect',
                valueOptions: userArray
            },
            {
                field: 'annotation',
                headerName: t('CUSTOMERS.FIELDS.annotation'),
                width: getColumnsWidth('annotation', 150, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('annotation', true, columnVisibility)
            },

            {
                field: 'delete',
                headerName: t(`COMMON.DELETE`),
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                width: getColumnsWidth('delete', 130, columnWidth),
                align: 'center',
                headerAlign: 'center',
                renderCell: (params: GridCellParams) => (
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            handleDelete(params.row as ICustomer);
                        }}>
                        <DeleteForeverIcon />
                    </IconButton>
                )
            },
            {
                field: 'id',
                headerName: 'ID',
                type: 'number',
                width: getColumnsWidth('id', 80, columnWidth),
                disableColumnMenu: true,
                hide: !isColumnsVisibile('id', false, columnVisibility)
            }
        ],
        [t, getColumnsWidth, columnWidth, isColumnsVisibile, columnVisibility, userArray, handleDelete]
    );

    if (isLoadingAll) {
        return <Loading />;
    }

    if (isError) {
        console.error(error);
        return <></>;
    }
    if (isCustomerAddressListError) {
        console.error(customerAddressListError);
    }
    if (isCustomerContactListError) {
        console.error(customerContactListError);
    }
    if (isCustomerCategoryListError) {
        console.error(customerCategoryListError);
    }
    if (isCustomerMandatorListError) {
        console.error(customerMandatorListError);
    }

    const handleAddNewClick = () => {
        history.push(`${ROUTE_PATHS.CUSTOMERS}/new`);
    };

    data.forEach(item => item.annotation = item?.annotation?.replace(/[\r\n]/gm, '') ?? '')

    return (
        <Box flexGrow="1" id="container">
            <Card variant="outlined">
                <CardContent>
                    <DataGridDefault
                        id={listId}
                        columns={columns}
                        data={data}
                        handleAddNewClick={handleAddNewClick}
                        handleRowDoubleClick={handleRowDoubleClick}
                        setColumnVisibility={setColumnVisibility}
                        setColumnWidth={setColumnWidth}
                    />
                </CardContent>
            </Card>
        </Box>
    );
};
